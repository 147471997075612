/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800;900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700;800&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://code.jquery.com/jquery-3.2.1.slim.min.js');

body {
    font-family: 'Poppins' !important;
    background-color: #fff;
    /*background-color: #fcfcfc;*/
    /*background-color: #f9f9f9;*/
    font-weight: 500;
    color: #212121;
    overflow-x: hidden;
    width: 100vw;
    max-width: 100vw;
}
input{
    font-family: 'Poppins' !important;
}
body.fixed-position{
    position: absolute;
    overflow: hidden;
}
body.no-scroll{
    position: absolute;
    overflow: hidden;
}
body.fixed-position #wrapper{
    position: relative;
    z-index: 99999;
}
body.fixed-position #wrapper{
    position: relative;
    z-index: 99999;
}
body.fixed-position.fixed-search #header{
    z-index: 999999;
}
body.popover-hidden {
    margin: 0; height: 100%; overflow: hidden
}

html {
    scroll-behavior: smooth;
}
.bg-white {
    box-shadow: 0 0 55px rgb(0 0 0 / 1%);
}
ul {
    padding: 0;
    list-style: none;
}
/*1. Font Styling Starts*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}
p {
    margin-bottom: 6px;
    color: #555;
}
h1{
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 1.6rem;
}
h2 {
    font-weight: 600;
    font-size: 1rem;
}
h3 {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 6px;
}
h4 {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 12px;
}
.font-8{
    font-size: 10px !important;
}
.font-10{
    font-size: 12px !important;
}
.font-13{
    font-size: 13px !important;
}
.font-14{
    font-size: 14px !important;
}
.font-18{
    font-size: 18px !important;
}
.title {
    margin-bottom: 15px;
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.h-fit{
    height: fit-content;
}
.title h2{
    float: left;
    font-size: 24px;
}
.title h2.text-sm{
    font-size: 20px;
    margin-top: 15px;
}
.error-text{
    font-size: 14px;
    transition: 0.3s ease-in-out;
}
/*Font Styling Ends*/

/*2. Fields Styling Ends*/
.form-control {
    border-radius: 6px !important;
    height: 44px;
    border: 1px solid #c9c9c9 !important;
    box-shadow: unset !important;
    font-weight: 500;
    font-size: 14px;
}

.form-control::placeholder{
    font-weight: 400;
    color: rgba(33, 33, 33, 0.60) !important;
}
.form-control:focus {
    border: 1px solid #5ab7d4;
    box-shadow: none;
}
.form-control.is-invalid, .valid-control.invalid{
    border-color: #dc3545 !important;
}

.form-control:focus {
    border: 1px solid #5ab7d4;
    box-shadow: none;
}

textarea.form-control{
    height: 120px;
    resize: none;
}
.search-bar i{
    color: #5ab7d4;
}
.sq-input{
    border-radius: 6px;
    font-size: 14px !important;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
}
/*Fields Styling Ends*/

/*3. Button Styling Ends*/
.btn{
    width: 100%;
    padding: 0;
    font-weight: 500;
    color: white;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: unset !important;
    outline: none !important;
    text-align: center;
    border: 0 !important;
    transition: all 0.3s ease-in-out;
}
.btn:hover{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    color: white;
}
.btn-l{
    height: 40px;
}
.btn-m{
    height: 38px;
}
.btn-s{
    height: 36px;
}
button.btn-order {
    background: #5ab7d4;
    color: white;
    margin-top: 10px;
    transition: 0.5s all;
    padding: 0 25px;
    width: max-content;
}
a.btn-order {
    background: #5ab7d4;
    color: white;
    margin-top: 10px;
    transition: 0.5s all;
    padding: 0 25px;
    width: max-content;
}

button.btn-order:hover {
    background: #258cac;
}

.cart-button {
    position: relative;
    overflow: hidden;
    background: #d30000;
}

button.cart-button .added{
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1 !important;
}
.cart-button span.minus {
    left: 0;
    top: 0;
    height: 38px;
    width: 38px;
    transform: unset;
    padding: 9px 8px;
    transition: 0.3s;
    background: #d30000 !important;
}
.cart-button span.minus:hover {
    background: #b20108 !important;
}

button.cart-button.clicked span {
    position: relative;
    left: 0;
}
.cart-button span {
    position: absolute;
    left: 50%;
    top: 49%;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 0.875rem;
    font-weight: 500;
}

.cart-button span.plus {
    left: unset;
    right: 0;
    top: 0;
    height: 38px;
    width: calc(100% - 38px);
    transform: unset;
    padding: 9px 8px;
    text-align: right;
}
.cart-button span.plus i{
    right: 3px;
    position: relative;
}
.cart-button span.added {
    opacity: 0;
    left: 0;
    top: 0;
}

/*.cart-button.clicked .fa-plus {
    animation: addicon 2s ease-in forwards
}*/

.cart-button.clicked {
    animation: color 2s ease-in forwards;
}

.cart-button.clicked span.add-to-cart {
    animation: addcart 0s ease-in forwards;
}

/*.cart-button .skin-5 .num-in span.minus {
    position: absolute;
    top: -7px;
}*/
.cart-button .skin-5 .num-in {
    border: none;
}
.cart-button .skin-5 .num-in input {
    height: auto;
    position: absolute;
    top: -13px;
    right: 7px;
    color: white;
    font-weight: 700;
}
.cart-button .skin-5 .num-in span.plus {
    position: absolute;
    text-align: right;
    left: 100%;
    margin-left: 31px;
}
.skin-5{
    height: 100%;
    width: 100%;
    display: inline-block;
}
.skin-5 .num-in {
    width: 100%;
    padding: 0;
    float: left;
    top: 0;
    display: flex;
    align-items: center;
    height: 32px;
    vertical-align: middle;
    position: relative;
    border: 1px solid #d1d1d1;
    border-radius: 100px;
}
.product-quantity-cart {
    margin: 0 20px;
}
input.in-num:focus {
    outline: 0 !important;
    background: none;
}

.skin-5 .num-in span {
    position: relative;
    font-size: 13px;
    margin-right: 0px;
    cursor: pointer;
    padding: 8px 11px;
}

.skin-5 .num-in span.minus {
    float: left;
}

.skin-5 .num-in input {
    height: 34px;
    width: 33px;
    float: left;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background: transparent;
    border: none;
}
.cart-table .table td .skin-5 .num-in input {
    height: 30px;
    font-weight: 600;
}
table a{
    text-decoration: unset !important;
}
.cart-table .table td .skin-5 .num-in span{
    padding: 0 10px;
    top: 1px;
}
.skin-5 .num-in span.plus {
    float: right;
}
select.btn option{
    background: white;
    color: black;
}
select.btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><svg width="20" height="20" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z" fill="white"/></svg>') !important;
    background-repeat: no-repeat;
    background-size: 22px;
    background-position-x: 95%;
    background-position-y: center;
    position: relative;
}
select.btn:hover {
    background-color: #c10505;
}
select:after{
    content: '';
    position: absolute;
    right: 0;
    background: black;
    top: 0;
    display: block;
    border-left: 5px solid red;
    border-top: 5px solid red;
    width: 25px;
    height: 25px;
    float: right;
    z-index: 5;
}

.buttons{
    position: relative;
}
.buttons .added{
    position: absolute;
    height: 38px;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
    width: 38px;
    top: 0;
    transition: 0.3s all ease-in-out;
    z-index: 2;
}
.buttons .added .minus{
    height: 38px;
    width: 38px;
    padding: 8px 13px;
    display: block;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: white;
    background: #d30000;
}
.buttons .added .minus .fa{
    position: relative;
    top: -1px;
}
.buttons .added .minus:hover{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}
.buttons .added .disabled{
    display: none !important;
}

@keyframes addicon {
    0% {
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}

@keyframes addcart {
    0%,
    30% {
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}
.btn-blank{
    background: transparent;
    text-align: center;
    height: unset;
    font-size: 13px;
    color: #000;
    font-weight: 500;
}
.btn-blank:hover{
    background: transparent;
    color: #56aac5;
}
.single-pro-right .btn-blank{
    /*color: #56aac5;*/
    background: transparent;
    border: 1px solid #e2e2e2 !important;
    color: #212121;
    padding: 8px 10px
}
.single-pro-right .btn-blank:hover{
    color: #008CBA;
}
.btn-list {
    background: transparent;
    border: 1px solid #ddd !important;
    color: #212121;
}
.btn-list:hover {
    background: #ddd !important;
    color: #0066c0;
}
.btn-proceed{
    color: #212121;
}
.btn-proceed:hover{
    color: #212121;
}
.blank-qty{

}
/*Button Styling Ends*/


/*4. header*/
.header-nav {
    background: #d30000;
}
header.header1 {
    top: 0;
    width: 100%;
    /*position: sticky;*/
    padding: 10px;
    height: 78px;
    z-index: 9;
}
.logo-col {
    width: 190px;
    margin: auto 0;
}
.mid-head-details {
    display: flex;
}
.flex-col {
    flex: 1 1 0%;
}
.head-content {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0.4rem 10px;
}
.search-bar-col {
    margin: auto 24px;
    flex: 1 1 0%;
}

.search-bar-col .btn.btn-danger{
    display: none;
}
.search-bar .form-control {
    padding-right: 85px;
}
.search-bar-col h4{
    position: fixed;
    z-index: -1;
}
.search-bar {
    position: relative;
    max-width: 360px;
}
.search-bar .input-search{
    position: relative;
}
.search-bar .clear-icon{
    position: absolute;
    top: 0;
    height: fit-content;
    bottom: 0;
    margin: auto;
    right: 65px;
    text-decoration: none !important;
}
.search-bar .clear-icon span{
    font-size: 16px;
    font-weight: 600;
    color: #424046;
}
.search-bar svg {
    position: absolute;
    top: 1px;
    right: 1px;
    transition: 0.2s all ease-in-out;
    width: 44px;
    border-radius: 0 5px 5px 0;
    padding: 12px;
    height: 42.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 44px;
    color: #333;
}
.search-bar .type-in svg{
    /*background: #5ab7d4;*/
    /*color: white;*/
    background: #fec601;
    color: #281713;
}
.search-wish{
    position: relative;
}
.search-wish .input-search{
    width: 70%;
    position: relative;
}
.search-wish .input-search .clear-icon{
    position: absolute;
    top: 0;
    height: fit-content;
    bottom: 0;
    margin: auto;
    right: 40px;
    text-decoration: none !important;
}
.search-wish .input-search .clear-icon span{
    font-size: 16px;
    font-weight: 600;
    color: #424046;
}
.search-list {
    margin-top: 0;
    position: absolute;
    z-index: 9999;
    border-radius: 6px;
    width: 100%;
    top: 44px;
    background: white;
    max-width: 360px;
    max-height: 300px;
    overflow: auto;
    box-shadow: 0 0 55px rgb(0 0 0 / 6%);
}
.search-list li {
    font-size: 14px;
    padding: 8px 10px;
    width: 100%;
    transition: 0.3s;
    color: #696969;
}
.search-list li:hover {
    background-color: #5ab7d4;
    color: white;
}
.drop-col {
    /*flex: 1 1 0%;*/
    flex: 0 0 auto;
}
.menu-col.top-header {
    margin: auto 0;
}
.top-header ul li {
    list-style: none;
    margin-left: 24px;
}
.top-header ul li.menu-toggle {
    padding-top: 0;
}

.menu-col.top-header ul li a {
    font-size: 14px;
    text-decoration: none;
    color: black;
}
.menu-col.top-header ul li svg {
    width: 16px;
    position: relative;
}
.menu-col.top-header ul li a b {
    margin-left: 6px;
    font-weight: 600;
    color: #414141;
    top: 2px;
    position: relative;
}
li.menu-toggle {
    position: relative;
}
.menu-details li img {
    width: 16px;
    position: relative;
    top: -2px;
}
.menu-details li a b {
    margin-left: 6px;
    /*font-weight: 600;*/
}
.menu-details li.menu-toggle {
    position: relative;
}
.badge-light {
    position: absolute;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    top: -12px;
    right: -10px;
    color: #fff;
    background-color: #dc2a27;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 16.5px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    height: 20px;
    min-width: 20px;
    border: 2px solid #dc2a27;
    padding: 0px 4px;
    border-radius: 10px;
}
/*.badge-light {*/
/*    color: #ffffff;*/
/*    background-color: #dc2a27;*/
/*    position: absolute;*/
/*    top: -12px;*/
/*    right: -10px;*/
/*    border-radius: 6px;*/
/*    height: 20px;*/
/*    width: auto;*/
/*    min-width: 20px;*/
/*    font-size: 10px;*/
/*    font-weight: 500;*/
/*    text-align: center;*/
/*    display: block;*/
/*    line-height: 16px;*/
/*    padding: .25em .5em*/
/*}*/
ul.navbar-nav.menu-details.right-menu li a.color-red i {
    color: #dc2a27;
}
ul.navbar-nav.menu-details.right-menu li a.color-blue i {
    color: #5ab7d4;
}
.navbar.navbar-expand-lg{
    padding: 0.5rem 1.5rem 0.5rem  1rem;
}


.pickup-selection.form-group {
    margin: auto 0;
}
.pickup-selection.form-group span {
    font-size: 16px;
    font-weight: 600 !important;
    top: 2px;
    position: relative;
    color: #008CBA;
}
.pickup-selection.form-group .btn:hover span{
    color: #006f94 !important;
}
.pickup-selection.form-group .btn:hover svg path {
    fill: #006f94 !important;
    stroke: #006f94 !important;
}
.pickup-selection.form-group .btn:hover:after {
    color: #006f94 !important;
}

.pickup-selection.form-group span.pickup {
    /*font-weight: 600;*/
    margin: 0 5px;
    top: 0;
    font-size: 14px;
}
.pickup-selection .dropdown .btn {
    cursor: pointer;
    background: transparent;
    margin: 0;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
    padding: 0.375rem 0 0.375rem 0;
    color: #5ab7d4;
    font-size: 14px;
    font-weight: 500;
    height: 44px;
    width: max-content;
    max-width: 460px;
    transition: 0.1s all ease-in-out;
    display: flex;
    align-items: center;
}
.pickup-selection .dropdown .btn span{
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 380px;
}
.pickup-selection .btn{
    color: #5ab7d4;
}
.pickup-selection .dropdown .btn:hover {
    color: #2a87d0;
}
.pickup-selection .dropdown-toggle::after {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 18px;
    color: #008CBA;
    font-weight: 700;
    margin-top: 8.5px;
    right: -3px;
    position: relative;
}

.pickup-selection .dropdown img {
    margin-right: 5px;
    width: 18px;
    position: relative;
    top: 0.065rem;
}
.header-nav .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
}
.header-nav ul.navbar-nav.menu-details {
    padding: 0;
}
.header-nav .navbar-collapse .btn-close{
    display: none;
}
.dropdown-toggle:after{
    border-top: .4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    vertical-align: 0.13em;
    margin-left: 0.5rem;
}
.dropdown .btn-tranparent{
    background-image: unset !important;
}
.header-nav ul.navbar-nav.menu-details li {
    margin-right: 30px;
}
.menu-col.top-header ul li a i {
    margin-right: 4px;
}
.menu-col.top-header ul li a:hover{
    color: #006f94;
}

.menu-col.top-header ul li a:hover b{
    color: #006f94;
}


.header-nav ul.navbar-nav.menu-details li a {
    font-size: 16px;
    color: white;
    font-weight: 700;
    text-decoration: unset !important;
}
.header-nav ul.navbar-nav.menu-details span{
    font-weight: 300 !important;
    font-size: 80%;
}
.header-nav ul.navbar-nav.menu-details li .dropdown-menu{
    width: 180px;
    transform: translate(-70px, 36px) !important;
    border-radius: 10px;
}
.header-nav ul.navbar-nav.menu-details li .dropdown-menu a{
    color: #212121;
    font-size: 14px;
}
.header-nav ul.navbar-nav.menu-details li .dropdown-menu .dropdown-item{
    padding: 8px 15px;
}
.header-nav ul.navbar-nav.menu-details li .dropdown-menu .dropdown-item.active{
    background: white;
}
.header-nav ul.navbar-nav.menu-details li .dropdown-menu a:focus{
    background: #f8f9fa;
}
.header-nav ul.ml-auto li a {
    font-weight: 500 !important;
}
.header-nav ul.ml-auto li a b{
    font-weight: 500 !important;
    font-size: 14px;
}
.header-nav ul.navbar-nav.menu-details.ml-auto li {
    margin-right: 0;
    margin-left: 24px;
    color: white;
}
.header-nav ul.navbar-nav.menu-details.right-menu.ml-auto li {
    margin-right: 0;
    margin-left: 18px;
}
.emp-form .main-section{
    padding: 30px 20px 15px;
    margin-bottom: 0;
}
.emp-form .sidebar{
    padding-bottom: 0;
}
.emp-form .most-product{
    padding-right: 0;
}
.emp-form .bg-section .complete-order .food-column:last-child{
    padding-right: 0;
}
.emp-form .bg-section .complete-order .cus-slider{
    padding-left: 15px;
}
.emp-form .bg-section .complete-order .cus-slider ul{
    padding: 0 0 15px;
}
.emp-form .main-section ul{
    list-style: initial;
    padding-left: 10px;
    margin-top: 10px;
}
.emp-form .main-section ul li{
    font-size: 14px;
    font-weight: 400;
    color: #6c757d!important;
}
.emp-form .main-section h6{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.emp-form .main-section .label{
    color: #696969;
}
.emp-form .main-section .fields{
    margin-bottom: 10px !important;
}
.emp-form .main-section .check{
    color: #281713;
    font-size: 14px;
    font-weight: 500;
    padding-left: 22px;
}
.emp-form .title{
    display: inline-block;
    text-align: left;
}
.emp-form .title h2{
    font-size: 20px;
    margin-bottom: 0;
    float: none;
    width: 100%;
}
.blog-desc h6{
    color: black;
    font-weight: 600;
    /*margin-bottom: 20px !important;*/
}
.blog-desc h4{
    color: black;
    font-weight: 600;
}
.blog-desc h2{
    font-size: 22px !important;
    color: black;
    font-weight: 600;
    margin-bottom: 15px !important;
}

ul.navbar-nav.menu-details.right-menu li a i {
    width: 24px;
    height: 24px;
    background: #f6f6f6;
    text-align: center;
    line-height: 24px;
    border-radius: 24px;
    margin-right: 8px;
    font-size: 10px;
}


.middle-navigation nav.navbar.navbar-expand-lg {
    padding: 0;
}

.control {
    display: block;
    position: relative;
    padding-left: 0;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control__indicator {
    position: absolute;
    top: 2px;
    right: 0;
    height: 16px;
    width: 16px;
    background: #e2e2e2;
    border-radius: 3px;
}
.control--radio .control__indicator {
    border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
    background: #ccc;
}
.control input:checked ~ .control__indicator {
    background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
    background: #5ab7d4;
}
.control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
    position: relative;
}
.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control__indicator:after {
    display: block;
}
.control--checkbox .control__indicator:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}


/*=====================home page css====================*/
.main-section {
    padding: 0.85rem;
    margin-bottom: 1.2rem;
    border-radius: 12px;
    border: 1px solid #f1f1f1;
}
.content-padding {
    padding-top: 1rem;
}
.column1 {
    max-width: 270px;
    flex: 0 0 270px;
    /*padding-top: 80px;*/
}
.div-breadcrumb{
    padding-left: 240px;
}
.column {
    padding-left: 0;
    max-width: calc(100% - 270px);
    flex: 0 0 80%;
}
.column.catering-main {
    padding-left: 0;
    max-width: 75%;
    flex: 0 0 75%;
}

.sidebar {
    border-radius: 10px;
    /*box-shadow: 0 0 35px rgb(0 0 0 / 3%);*/
    /*border: 1px solid #eeeeee;*/
    border: 0;
    padding: 1.2rem 1rem 1rem 0;
}
.category .sidebar{
    position: relative;
    top: 0;
}
.sidebar .tags-article{
    margin-top: 5px;
    padding-left: 0 !important;
}

.sidebar .tags-article li a{
    padding: 3px 7px;
    cursor: pointer;
    font-weight: 400 !important;
    border: 1px solid #e2e2e2;
    color: #969696;
    transition: 0.2s all ease-in-out;
}
.sidebar .tags-article li a:hover{
    border: 1px solid #2a87d0;
    color: #2a87d0;
}
.sidebar .tags-article li.active a{
    border: 1px solid #2a87d0;
    background-color: #2a87d0;
    color: #ffffff;
}
.cus-slc-tag{
    position: relative;
}
.cus-slc-tag svg{
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 17px;
    height: 17px;
    z-index: 0;
}
.cus-slc-tag select{
    position: relative;
    -webkit-appearance: none;
    z-index: 1;
    background: transparent !important;
    padding-right: 34px !important;
}
.sidebar .tags-article li{
    margin: 0 5px 5px 0 !important;
}
.react-tel-input .form-control{
    height: 44px !important;
    font-size: 13px !important;
    width: 100% !important;
    color: #212121;
    padding: 0.375rem 0.75rem !important;
}
.react-tel-input .flag-dropdown{
    display: none !important;
}
.sidebar h5 {
    color: #212121;
    font-weight: 700;
    font-size: 1rem;
}
.sidebar ul {
    padding-left: 5px;
}
.sidebar ul li {
    margin: 5px 0;
    display: block;
    transition: 0.3s all ease-in-out;
}


.sidebar ul li a {
    font-size: 16px;
    color: #535353;
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 5px 10px;
    text-transform: capitalize;
}
.sidebar ul li a a{
    padding: 0 !important;
}
.sidebar ul li a:hover{
    color: #56aac5;
}

.sidebar ul li a.active{
    font-weight: 500;
    /*color: black;*/
    background: #2aa1c0;
    color: white;
    border-radius: 5px;
}
.sidebar ul li a.active a{
    color: white !important;
}
.menu-static li.active a{
    font-weight: 700;
    color: black;
}
.sidebar .filter{
    padding: 20px 0;
    border-top: 1px solid #eeeeee;
}
.sidebar .filter:last-child{
    padding-bottom: 10px;
}
.sidebar .filter h5{
    margin-bottom: 10px;
}
.sidebar .filter p{
    font-weight: 600;
}
.sidebar .filter h6 input{
    float: right;
    position: absolute;
    right: 0;
    top: 3px;
    margin-left: 5px;
}
.sidebar .filter h6 img{
    width: 18px;
    margin-right: 5px;
}
.sidebar .filter h6 span {
    float: right;
    right: 25px;
    top: 2px;
    font-size: 14px;
    position: absolute;
    text-align: revert;
}
.sidebar .filter h6 b{
    font-weight: 500;
    top: 2px;
    position: relative;
}
.sidebar .filter .range-slider {
    display: inline-block;
    width: 100%;
}
.sidebar .filter .range-slider p:last-child{
    float: right;
}
.sidebar .filter .range-slider p{
    float: left;
    font-size: 14px;
    margin: 10px 0 0 !important;
    color: black;
}
.sidebar .filter h6{
    font-size: 16px;
    color: #535353;
    position: relative;
    text-decoration: none;
    margin: 15px 0;
}
.sidebar .filter h6:last-child{
    margin-bottom: 0;
}

.filter-mob{
    margin-top: 15px;
}

.filter-mob .filter{
    padding: 20px 0;
    border-top: 1px solid #dee2e6!important;
}
.filter-mob .filter:last-child{
    padding-bottom: 10px;
}
.filter-mob .filter h5{
    margin-bottom: 10px;
}
.filter-mob .filter p{
    font-weight: 600;
}
.filter-mob .filter h6 input{
    float: right;
    position: absolute;
    right: 0;
    top: 3px;
    margin-left: 5px;
}
.filter-mob .filter h6 img{
    width: 18px;
    margin-right: 5px;
}
.filter-mob .filter h6 span {
    float: right;
    right: 25px;
    top: 2px;
    font-size: 14px;
    position: absolute;
    text-align: revert;
}
.filter-mob .filter h6 b{
    top: 2px;
    position: relative;
    color: #46474a;
    font-weight: 500 !important;
    width: 100%;
    font-size: 15px !important;
}
.filter-mob .filter ul li{
    margin: 10px 0;
}
.filter-mob .filter ul li a{
    top: 2px;
    position: relative;
    color: #46474a;
    font-weight: 500 !important;
    width: 100%;
    font-size: 15px !important;
}
.filter-mob .filter .range-slider {
    display: inline-block;
    width: 100%;
}
.filter-mob .filter .range-slider p:last-child{
    float: right;
}
.filter-mob .filter .range-slider p{
    float: left;
    font-size: 14px;
    margin: 10px 0 0 !important;
    color: black;
}
.filter-mob .filter h6{
    font-size: 16px;
    color: #535353;
    position: relative;
    text-decoration: none;
    margin: 10px 0;
}
.filter-mob .filter h6:last-child{
    margin-bottom: 0;
}


.rc-slider-handle{
    border: solid 2px #2aa1c0;
    background-color: #2aa1c0;
}
.rc-slider-handle-dragging{
    border: solid 2px #2aa1c0 !important;
    background-color: #2aa1c0 !important;
}
.rc-slider-handle:hover{
    border: solid 2px #2aa1c0;
    background-color: #2aa1c0;
}
.rc-slider-track{
    background-color: #2aa1c0;
}
.rc-slider-handle:active {
    border-color: #2aa1c0;
    box-shadow: 0 0 5px #2aa1c0 !important;
}
.banner-details {
    background-color: #56aac5;
    border-radius: 8px;
    padding: 2rem 1.5rem;
    background-image: url(../images/new-img1.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    margin: 5px 0 0;
    background-size: contain;
}
.spacer-home{
    margin-top: 1.5rem;
    display: inline-block;
    width: 100%;
}
.banner-details h1 {
    color: white;
    margin-bottom: 5px;
    font-weight: 700;
}
.banner-details p {
    color: white;
    font-size: 16px;
    font-weight: 400;
}
.food-details {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
}
.display-details .food-column {
    width: 20%;
    flex: 0 0 20%;
    padding-left: 10px;
    margin-bottom: 10px;
}
.food-column {
    margin-bottom: 10px;
}
.display-details {
    padding-right: 15px;
    padding-left: 5px;
}

.product-details {
    background-color: white;
    position: relative;
    border-radius: 10px;
    /*box-shadow: 0 0 35px rgb(0 0 0 / 3%);*/
    border: 1px solid #eeeeee;
    overflow: visible;
    padding: 0 0 0.5rem;
}
.product-details .onsale-details {
    position: absolute;
    left: 0;
    top: -2px;
    display: block;
    z-index: 3;
}
.product-details .onsale-details span {
    background: #fec601;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 14px 4px;
    display: block;
    border-radius: 10px 0 10px 0;
}
.product-details .top-img {
    position: absolute;
    top: 6px;
    right: 1px;
    width: 25px;
    z-index: 2;
}

.product-details .sub-order{
    display: none;
}
.product-details .top-img img {
    width: 18px;
    margin-bottom: 7px;
}
.product-details .top-img img.b {
    width: 20px;
}
.product-details .food-img {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-top: 100%;
    margin-bottom: 0;
}
.product-details .food-img .lazyload-wrapper {
    transition: 0.2s all ease-in-out;
}
.product-details .food-img img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    margin: auto;
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    left: 0;
    border-radius: 10px;
    right: 0;
}
.deliverable{
    background: #f6f6f6 !important;
    text-align: center;
    cursor: default;
    border-radius: 4px;
    pointer-events: none;
    border: 1px solid #e3e3e3;
    height: 38px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px 5px;
    margin: 0;
}
.rotate-icon{
    transform: rotate(180deg);
}
#card-number-wrapper #cardNumber{
    font-size: 13px;
}
.product-details .food-content{
    padding: 0;
    display: flex;
    flex-direction: column;
}
.bg-primary-10{
    background-color: rgba(255, 0, 0, 0.1) !important;
}
.bg-primary-10{
    color: red !important;
    font-weight: 500;
}
.bg-light{
    background: #f5f5f5;
}
.deliverable p{
    text-align: center !important;
    font-size: 12px !important;
}
.catering-main .deliverable{
    margin: 0;
    height: 38px;
    padding: 9px 0;
}
.catering-main .product-details .buttons{
    margin-bottom: 5px;
}
.deliverable p{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    color: #969696;
}
.product-details .food-content p{
    padding: 0 1rem;
}

.product-details .food-content p.p-normal{
    padding: unset;
}

.product-details .food-content.mb-10{
    margin-bottom: 10px;
}
.product-details .buttons{
    padding: 0 1rem;
}
.product-details .food-content a{
    display: inline-block;
    width: 100%;
}
.product-details .ad-fc{
    display: inline-block;
    width: 100%;
}
.product-details .food-content h6 {
    font-size: 14px;
    font-weight: 400;
    padding: 0 1rem 5px;
    height: 52px;
    margin: 10px 0;
    /*text-transform: capitalize;*/
    color: #414142;
    line-height: 1.45;
    text-align: left;
}

.product-details .food-content h6.h-normal {
    all: unset;
}

.product-details .food-content .weight{
    font-size: 12px;
    text-align: left;
}
.product-details .food-content .weight .tags{
    width: auto;
}
.product-details .food-content .weight .tags .btn{
    font-weight: 500;
    font-size: 12px;
    border: 1px solid #ced4da !important;
    padding: 3px 6px;
    width: auto;
    background: white;
    color: #212121;
}
.catering-card{
    padding: 20px 0;
}
.catering-card h6{
    font-size: 24px;
    color: #d30000;
    font-weight: 600;
    line-height: 0.9;
    margin-bottom: 20px;
}
.catering-card h6 span{
    font-size: 14px;
}
.catering-card p{
    font-size: 15px;
}
.catering-card small{
    font-size: 14px;
    font-weight: 600;
    color: black;
    display: block;
    margin: 5px 0 8px;
}
p.price {
    font-size: 13px;
    font-weight: 400;
    color: #696969;
    text-align: left;
    margin-bottom: 0;
}
p.price span{
    font-size: 13px;
    font-weight: 600;
    color: #414142;
}
p.price small{
    font-size: 11px;
    color: #414142 !important;
    font-weight: 500;
}
p.price sup{
    font-size: 14px;
    font-weight: 600;
    color: #414142;
}
p.price .text-success{
    font-weight: 500;
}
.price sup {
    font-size: 14px;
    font-weight: 600;
    top: 0;
}
.product-details .buttons{
    margin: 0;
    float: left;
    width: 100%;
}
.product-details .buttons select{
    color: transparent;
    opacity: 0;
    z-index: 2;
}
.product-details .buttons select option{
    color: black;
}
.back-icon{
    font-size: 16px;
    margin-right: 10px;
    color: #969696;
    position: relative;
    top: 1px;
}
.back-icon:hover{
    color: #56aac5;
}
/*.product-details .food-content p.weight span {*/
/*    float: right;*/
/*}*/

.MuiPopover-paper{
    transition: 0.15s all ease-in-out !important;
}


.MuiPopover-paper ul{
    margin-bottom: 2px;
}
.MuiPopover-paper{
    box-shadow: 0 0 35px rgb(0 0 0 / 10%) !important;
    max-width: calc(100% - 10px) !important;
    border: 1px solid #f0f0f0;
    border-radius: 6px !important;
}
/*.MuiPopover-paper:before {*/
/*    content: "";*/
/*    display: block;*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-top: 10px solid transparent;*/
/*    border-bottom: 10px solid transparent;*/
/*    border-left: 10px solid #009688;*/
/*    position: absolute;*/
/*    top: 16px;*/
/*    right: 58px;*/
/*    z-index: 12;*/
/*    transform: rotate(90deg);*/
/*}*/
.MuiPopover-paper ul li{
    padding: 12px 35px;
    font-size: 14px;
    color: #696969;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
}
.MuiPopover-paper ul li:hover{
    color: black;
    cursor: pointer;
    background: #f7f7f7;
}
.MuiPopover-paper ul li:last-child{
    border-bottom: 0;
}
.product-details a:hover{
    text-decoration: none;
}
/*.owl-item:first-child .price{*/
/*    color: #d30000;*/
/*    font-weight: 900;*/
/*}*/
/*.owl-item:first-child .price sup{*/
/*    color: #d30000;*/
/*    font-weight: 900;*/
/*}*/
p.price del {
    font-weight: 500;
    margin-right: 5px;
    font-size: 12px;
    color: #9a9a9a;
}
p.price .on-sale{
    color: #028f02;
}
.single-pro-right {
    padding: 1.5rem 1rem;
}
.single-pro-right-main{
    position: sticky;
    top: 20px;
    height: max-content;
}
.single-pro-right .price {
    text-align: center;
    margin: 0 0px 5px;
}
.single-pro-right .sub-text{
    font-size: 13px;
    color: #696969;
    margin: 0px 15px 20px;
    text-align: center;
}
.single-pro-right select{
    color: transparent !important;
    opacity: 0;
    z-index: 2;
}
.single-pro-right .value-slc{
    width: 100%;
}
.single-pro-right select option{
    color: black !important;
}
.single-pro-right .btn-div{
    width: 95%;
    margin: auto;
}
.single-pro-right .shared-details h6{
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.single-pro-right .price sup {
    margin-left: 0;
    font-size: 38px;
    font-weight: 700;
}
.single-pro-right p.price .text-success {
    font-size: 16px;
}
.single-pro-right .price span {
    font-size: 30px;
    margin-top: 0px;
    display: block;
    color: #414142;
}
.single-pro-right .price.sub{
    font-size: 12px;
}
.single-pro-right .price del{
    font-size: 16px;
}
.single-pro .price.text-left{
    font-size: 15px;
}
.single-pro .price.text-left span{
    font-size: 15px;
}
.single-pro .price.text-left sup{
    font-size: 15px;
}
.single-pro .price.text-left .text-success{
    font-size: 13px;
    padding-top: 5px;
}
.product-details a.btn.btn-list {
    font-size: 0.875rem;
    width: 100%;
    padding-bottom: 0;
    padding-top: 0px;
    font-weight: 500;
    margin-top: 5px;
}
.list-menu{
    position: absolute;
    width: 80%;
    height: 100%;
    background-color: black;
    bottom: 40px;
    display: none;
}
.product-details a.btn.btn-list:cli .list-menu{
    display: block;
}

div#owl-slider {
    margin-bottom: 1.2rem;
}

.food-details a {
    display: inline-block;
    color: #0066c0;
    font-weight: 700;
    font-size: 13px;
    text-decoration: none;
}
.react-multi-carousel-item{
    padding: 0 10px 0 0 ;
}
.slider-container{
    padding: 0 0 0 10px;
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
}
.react-multiple-carousel__arrow{
    background: white;
    top: 42%;
    padding: 0;
    box-shadow: 0 0px 6px 0 #cdcdcd;
    outline: none !important;
    transition: 0.3s all ease-in-out;
    z-index: 2;
}
.react-multiple-carousel__arrow:hover{
    background: white;
    color: black;
}
.react-multiple-carousel__arrow:before{
    font-size: 15px;
    font-weight: 600;
    color: black;
    transition: 0.3s all ease-in-out;
}
.react-multiple-carousel__arrow--left{
    left: 0px;
}
.react-multiple-carousel__arrow:hover{
    font-weight: 700;
}
.react-multiple-carousel__arrow--right{
    right: 0px;
}
.carousel img{
    border-radius: 8px;
}
.owl-nav,
.owl-prev {
    font-size: 0;
}
.owl-theme .owl-nav {
    margin-top: 0px !important;
    height: 0px;
}
.owl-theme .owl-nav button{
    transition: 0.3s;
}
.owl-theme .owl-nav button.disabled{
    opacity: 0;
}
.owl-theme .owl-nav button.disabled:before{
    color: #969696;
}
.owl-dots{
    display: none;
}
.owl-prev:before {
    content: '\f104';
    font-family: fontawesome;
    position: absolute;
    font-size: 22px;
    padding: 0px 20px;
    line-height: 40px;
    width: 42px;
    height: 42px;
    border-radius: 50px;
    /*border:1px solid #e4e4e4;*/
    text-align: center;
    color: black;
    background: white;
    box-shadow: 0 0px 6px 0 #cdcdcd;
    top: 40%;
    left: -14px;
}
.owl-next:before {
    content: '\f105';
    font-family: fontawesome;
    position: absolute;
    font-size: 25px;
    padding: 0px 20px;
    line-height: 46px;
    width: 48px;
    height: 48px;
    border-radius:50px;
    border: 1px solid #d2d6da !important;
    text-align: center;
    color: black;
    background: white;
    box-shadow: 0 0px 6px 0 #cdcdcd;
    right: -14px;
    top: 40%;
}
.owl-carousel .owl-item .top-img img {
    width: 20px;
}
.owl-carousel .owl-item .top-img img.b {
    width: 22px;
}
.fresh-food-details {
    /*margin: 3.5rem 0 0rem;*/
}
.fresh-food-details img {
    width: 100%;
    border-radius: 15px;
}
.fresh-food-details h5 {
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: rgb(65, 65, 66);
    font-weight: 700;
    margin-top: 2px;
}

.fresh-food-details p {
    color: #535353;
    font-size: 14px;
}


.shop-section {
    padding: 2rem 1rem 1rem;
    margin: 2rem 0;
    border-radius: 8px;
}
.shop-section .shop-border.food-details {
    border: 1px solid #dddddd;
    border-radius: 12px;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    height: 93px;
    display: flex;
    justify-content: space-between;
}
.shop-details h5 {
    font-weight: 700;
    font-size: 18px;
}
.shop-img {
    width: 82px;
    align-items: center;
    display: flex;
}
.shop-img img {
    width: 100%;
}

.shop-inner-details button.cart-button.cart1 {
    /*width: 30px;*/
    height: 32px;
    background-color: #dc2a27;
    border-color: #dc2a27;
    transition: 0.5s;
}

.shop-inner-details .cart-col {
    text-align: right;
    top: -9px;
}
.shop-inner-details button.cart-button.cart1.clicked {
    width: 100%;
    background-color: #dc2a27;
    padding: 0;
}
.shop-inner-details button.cart-button.clicked span {
    position: relative;
    left: 0;
    top: unset;
}
.shop-inner-details .cart-button .skin-5 .num-in span.minus {
    top: 0;
    font-size: 12px;
    width: 32px;
    margin: 0;
    height: 100%;
}
.shop-inner-details .cart-button .skin-5 .num-in input {
    top: 0;
    right: 0;
    font-size: 15px;
    left: 0;
    width: 30px;
    margin: 4px auto;
    user-select: none;
    pointer-events: none;
}
.shop-inner-details .cart-button .skin-5 .num-in span.plus {
    top: 0;
    font-size: 12px;
    width: 32px;
    margin: 0;
    right: 0;
    height: 100%;
    text-align: center;
    padding: 0;
    left: unset;
    line-height: 32px;
}
.shop-inner-details .cart-button.cart1 span.add-to-cart {
    color: white;
    width: 100%;
}
.shop-inner-details .cart-button .num-block.skin-5{
    top: 0 !important;
    transform: unset !important;
}
.shop-inner-details .cart-button.cart-button .skin-5 span{
    top: 0 !important;
    transform: unset !important;
}
.shop-inner-details button.cart-button.cart1:hover span.add-to-cart {
    color: white;
}
.shop-inner-details button.cart-button.cart1:hover {
    background-color: #dc2a27;
    border-color: #5ab7d4;
}
button.cart-button{
    outline: none !important;
}
button.cart-button.active {
    background-color: #5ab7d4 !important;
    border-color: #5ab7d4 !important;
    color: white !important;
    cursor: default;
}
button.cart-button .slc{
    display: none;
}
button.cart-button.active .slc{
    display: inline-block;
}
.shop-section .shop-border.food-details .shop-inner-details {
    margin-left: 8px;
    width: calc(100% - 82px);
}
.shop-section .shop-border.food-details .shop-inner-details a{
    width: 63%;
}
.shop-section .shop-border.food-details .shop-inner-details a h6 {
    font-size: 14px;
    color: #281713;
    font-weight: 600;
    height: 36px;
    margin: 0 0 6px;
    text-align: left;
}
.shop-section .shop-border.food-details .shop-inner-details .food-price p {
    color: black;
    font-size: 14px;
    margin-top: 0px;
}
.shop-section .shop-border.food-details .shop-inner-details .price{
    text-align: left;
}
a.more-info {
    display: inline-block;
    color: #56aac5;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    float: right;
}
a.more-info:hover{
    color: #258cac;
    text-decoration: underline !important;
}
a.more-info:hover .text-black{
    color: #258cac !important;
}

.cursor{
    cursor: pointer;
}
/*h2 a.more-info{*/
/*    font-size: 24px;*/
/*    color: #281713;*/
/*    text-decoration: none;*/
/*}*/
a.delete-info{
    display: inline-block;
    color: dimgrey;
    font-weight: 500;
    font-size: 13px;
    text-decoration: none;
    float: right;
}
a.delete-info:hover{
    text-decoration: underline;
    color: orangered;
}
.subscribe-display {
    display: flex !important;
}
.subscribe-display .form-group {
    margin-bottom: 0;
}
.subscribe-info {
    /*background: #5ab7d4;*/
    border-radius: 0;
    padding: 2rem 0 0;
    margin-top: 0rem;
    top: 0;
    position: relative;
}
.subscribe-details {
    padding: 0;
}
.subscribe-details h4 {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 1.4rem;
}
.subscribe-details p {
    color: white;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
}
.subscribe-details .form-group {
    width: calc(100% - 160px);
    padding: 0 15px 0 0;
    float: left;
    margin-bottom: 0;
}
.subscribe-details .form-group .valid-control{
    border-width: 2px !important;
    box-shadow: initial !important;
}
.subscribe-details .btn {
    width: 160px;
    margin-top: 0 !important;
}
.subscribe-details form .food-details .form-group .form-control {
    width: 292px;
    height: 42px;
    border-radius: 8px;
    font-weight: 500;
}
.subscribe-details button.btn.btn-order{
    background-color: #258cac;
    border-radius: 6px;
    height: 44px;
    transition: 0.3s all ease-in-out;
    padding: 0 13px;
}
.subscribe-details button.btn.btn-order:hover{
    background-color: #1981a1;
}
.subscribe-details .action{
    display: flex;
}
.success-title p{
    color: black;
    font-size: 15px;
}
.success-title p b{
    font-weight: 600;
    color: #414141;
}
.foot-cart{
    position: fixed;
    z-index: 999;
    bottom: 0;
    padding: 1rem 0;
    background: white;
    border-top: 1px solid #e2e2e2;
    box-shadow: 0 0 55px rgb(0 0 0 / 1%);
}
.foot-cart h2{
    font-weight: 400;
    font-size: 1.1rem;
}
.foot-cart p b{
    font-weight: 600;
    font-size: 16px;
    color: black;
}
.footer {
    background: #1b1a1a;
    padding: 0 0 2rem;
    margin-top:0;
    border-bottom: 1px solid #100e0e;
}
.footer hr{
    opacity: 0.2;
}
.footer h3{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
.footer .item-col {
    width: max-content;
    max-width: max-content;
}
.footer-content img {
    width: 140px;
}
.footer-content h5 {
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
    color: white;
}
.footer-content h2{
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
}
.footer-content p {
    font-size: 13px;
    color: white;
    margin-top: 10px;
    font-weight: 300;
}

.footer-content ul{
    margin-bottom: 1.8rem;
}
.footer-content ul li {
    margin-bottom: 0px;
}
.footer-content ul li a {
    font-size: 13px;
    color: white;
    font-weight: 300;
    transition: 0.3s;
}
.footer-content .contact-details i {
    font-size: 16px;
    color: #2e94b2;
    vertical-align: text-top;
    top: 0;
    position: relative;
}
.footer-content .contact-details h6{
    color: white;
    font-size: 13px;
    font-weight: 300;
}
.footer-content .hours-details {
    margin-bottom: 1rem;
    padding-left: 10px;
}
.footer-content .social{
    display: inline-flex;
    margin-top: 10px;
}
.footer-content .social li{
    margin-right: 10px;
}
.footer-content .social li a{
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 28px;
    opacity: 0.5;
    color: white;
    border-radius: 100px;
    text-align: center;
    border: 1px solid white;
    transition: 0.3s all ease-in-out;
}
.footer-content .social li a svg{
    width: 14px;
    height: 14px;
}
.footer-content .social li a:hover{
    opacity: 1;
}
.custom-checkbox {
    margin: 0.5rem 0;
    height: 30px;
    position: relative;
}

input[type='radio'][id^='myradio'] {
    display: none;
}
input[type='checkbox'][id^='mycheckbox'] {
    display: none;
}

.payment-method label {
    border: 1px solid #bbbbbb;
    padding: 0rem 0.6rem;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
}
.date-details .pickup-date.payment-method {
    margin-right: 0.6rem;
}
.pickup-date.payment-method {
    display: inline-block;
    margin-right: 5px;
}
.date-custom-select {
    text-align: center;
}
.pickup-date.payment-method :checked + label {
    background-color: #56aac5;
    border-color: #56aac5;
    color: white;
}

.dropdown.more-size {
    background: #e5e5e5;
    width: 28px;
    height: 20px;
    position: absolute;
    right: -3px;
    top: 3px;
    border-radius: 2px;
}
.dropdown.more-size button {
    padding: 0 7px;
    font-size: 12px;
    color: #5ab7d4;
}
.dropdown.more-size .dropdown-menu {
    min-width: 0;
    padding: 0;
}
.dropdown.more-size .dropdown-item {
    padding: 0 5px;
}

.dropdown.more-size button:after {
    display: none;
}
.bottom-footer {
    padding: 0.8rem 0;
    background: #252525;
    font-weight: 300;
}
.bottom-footer h6{
    color: white;
    font-size: 13px;
    font-weight: 200;
    line-height: 1.6;
    margin-bottom: 15px;
}
.copyright-details {
    margin: auto 0;
}
p.copyright-content {
    color: white;
    margin: 10px 0px 5px;
    font-size: 14px;
    text-align: center;
}
.footer-right {
    float: right;
}
.footer-right ul li {
    list-style: none;
    margin-left: 28px;
}
.footer-right ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
}
.row.slider-title.food-details.mb-sm.feature-details {
    display: inline-flex;
}
/*===================dropdown header modal design======================*/
.card-heading{
    font-weight: 600;
    font-size: 1.3rem;
}
.card-text{
    font-weight: 400;
    line-height: 1.4;
}
.modal-dialog {
    width: 440px;
}
.modal-dialog-ebt {
    width: 700px !important;
}

/* Add media query for responsiveness */
@media (max-width: 768px) {
    .card-shadow{
        width: 100%;
        max-width: 100%;
    }
    .modal-dialog-ebt {
        width: 100% !important; /* Adjust the width as needed for smaller screens */
    }
    .pickup-selection.form-group span {
        font-size: 16px;
        font-weight: 500;
        top: 2px;
        position: relative;
        color: #fff;
    }
    .pickup-selection.form-group span:hover {
        color: #fff;
    }
}

.card-heading{
    width: 100%;
}
.modal-close{
    margin-top: 5px;
    float: right;
    color: #d30000;
    font-size: 16px;
}
.storeModal .modal-dialog{
    width: 540px;
    max-width: 540px;
}
.storeModal .modal-dialog p{
    font-weight: 600;
    font-size: 14px;
}
.storeModal .modal-dialog p.note {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
}
.storeModal .modal-dialog p b{
    color: black;
    font-weight: 700;
}
.modal-dialog .btn{
    font-weight: 600;
    width: auto;
    margin-right: 10px;
    padding: 0 15px;
}
.modal-dialog .modal-close.btn {
    font-weight: 600;
    padding: 0;
    width: 30px;
    height: 30px;
    margin: 0;
    top: 5px;
    position: absolute;
    border: 0px solid #969696 !important;
    border-radius: 100px;
    background: #c9c9c9 !important;
    line-height: 29px;
    right: 5px;
}
.modal-dialog .modal-close.btn svg {
    width: 20px;
    height: 20px;
    color: white;
}
.modal-dialog .modal-close.btn:hover{
    border-color: #d30000 !important;
    background: white;
    color: #d30000;
}
.modal-dialog .modal-footer{
    justify-content: start;
}
.modal-dialog .btn.proceed-button{
    background: #fec601;
    color: #000;
    transition: 0.3s all ease-in-out;
}
.modal-dialog .btn.proceed-button br{
    display: none;
}
.modal-dialog .btn.proceed-button:hover{
    background: #f0bb02;
}
.modal-dialog .btn.proceed-button[disabled]{
    background: #ebebeb !important;
    cursor: default !important;
}
.form-modal .modal-dialog{
    width: 540px;
    max-width: 540px;
}
.modal-info .modal-dialog{
    width: 600px;
    max-width: 600px;
}
.modal-info .modal-dialog li:first-child{
    margin-top: 20px;
}
.modal-info .modal-dialog li{
    min-height: 40px;
    margin-bottom: 20px;
}
.modal-info .modal-dialog li:last-child{
    margin-bottom: 0;
}
.modal-info .modal-dialog img{
    width: 40px;
    float: left;
}
.modal-info .modal-dialog h6{
    font-weight: 700;
    padding-left: 50px;
}
.modal-info .modal-dialog span{
    color: #696969;
    text-transform: initial;
    width: 100%;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    margin-top: 3px;
}
.modal-dialog .modal-content {
    border-radius: 15px;
}
.drop-modal {
    padding: 10px 5px 8px;
    position: relative;
}
.drop-modal .modal-content {
    border-radius: 12px;
    border: 0;
}

/*.drop-modal .order-prefrence h4 {*/
/*    color: #212121;*/
/*    font-weight: 700;*/
/*    margin-bottom: 0.8rem;*/
/*    font-size: 1rem;*/
/*}*/
.drop-modal .order-prefrence p {
    font-size: 13px;
    font-weight: 400;
    color: #696969;
    margin-bottom: 1.4rem;
}
.drop-modal .store-select {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}
.drop-modal .tabs-order .nav-item{
    width: 48%;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
    opacity: 1;
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
    opacity: 1;
}
.drop-modal ul{
    margin-bottom: 15px;
    padding: 0;
}
.drop-modal .store-select .options {
    display: inline-block;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
}
/*.drop-modal .store-select .options .distance:last-child{*/
/*    font-size: 12px;*/
/*}*/
.drop-modal .store-select .options:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.drop-modal .store-select .card-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 4px;
    width: 100%;
    float: left;
    color: #000;
}
.drop-modal .store-select .card-title b{
    font-weight: 600;
}
.drop-modal .store-select .distance {
    font-size: 0.85rem;
    margin-bottom: 0px;
    width: 100%;
    float: left;
    font-weight: 400;
}
.drop-modal .store-select .distance span{
    font-weight: 400;
}
.drop-modal .store-select .distance .text-black{
    color: #212121;
    font-weight: 500;
}
.drop-modal .store-select p.cursor{
    margin-bottom: 0;
}
.drop-modal .store-select .distance .text-black i{
    width: 10px !important;
}
.drop-modal .store-select .distance .text-success{
    font-weight: 500;
}
.drop-modal .store-select button {
    float: right;
    width: 80px;
    height: 28px;
    border: 2px solid #5ab7d4;
    background: transparent;
    color: #000;
    /*top: -14px;*/
    border-radius: 5px;
    padding: 0px;
    font-weight: 500;
    font-size: 12px;
    transition: 0.3s;
}
.drop-modal .store-select button:hover {
    background: #5ab7d4;
    color: white;
}
.drop-modal .store-select button:disabled{
    color: #a6a6a6 !important;
    border-color: #d6d4d4 !important;
    background: white !important;
    cursor: default;
}
.delivery-tabs .nav-tabs .nav-link {
    border: 1px solid #c8c8c8;
    border-radius: 20px;
    font-weight: 500;
    color: black;
    font-size: 14px;
    width: 100%;
    height: 40px;
    text-align: center;
}
.location-icon {
    cursor: pointer;
    right: 0px;
    position: absolute;
    top: 0px;
    color: black;
    font-size: 12px;
    height: 100%;
    border-radius: 0 50px 50px 0;
    background: #f0bb02;
    text-align: center;
    line-height: 41px;
    padding: 0px 10px;
    font-weight: 600;
}
.location-suggestions {
    position: relative;
    margin-top: 12px;
    border: 1px solid #e8e8e8;
}
.location-suggestions-autocomplete {
    position: absolute;
    margin-top: 12px;
    border: 1px solid #e8e8e8;
    z-index: 9;
    width: 90%;
    top: 60px;
}
.suggestion-items {
    border-bottom: 1px solid #e8e8e8;
    padding: 7px 10px;
    cursor: pointer;
    font-size: 13px;
}
.suggestion-items:hover {
    color: white;
}
.tabs .nav-tabs{
    border-bottom: 0;
    margin-bottom: 20px;
}

.nav-tabs .nav-item {
    float: left;
    cursor: pointer;
}
.nav-tabs .nav-item .nav-link{
    background: #eee;
    transition: 0.2s all ease-in-out;
}

.nav-tabs .nav-item:last-child {
    float: right;
}

.nav-tabs .nav-item:last-child .nav-link {
    float: right;
    display: flex;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: 0;
    background: #5ab7d4;
    color: white;
}
.account-right .nav-tabs .nav-item:last-child .nav-link{
    float: right;
    border-radius: 0 5px 5px 0 !important;
}
.account-right .nav-tabs .nav-link{
    border: 0;
    padding: .4rem 1rem !important;
    border-radius: 5px 0 0 5px !important;
}
.account-right .nav-tabs .nav-item:last-child .nav-link {
    padding: .4rem 1rem !important;
}
.delivery-tabs {
}

.sec-article{
    padding: 3rem 0 1rem;
    background: #fff;
    /*border-top: 1px solid #e2e2e2;*/
    background-image: url(../images/article-bg.jpg);
    background-attachment: fixed;
    background-size: 100%;
}
.product-articles .cus-slider.recent .food-column {
    width: 280px;
    min-width: 280px;
}
.product-articles .cus-slider.recent .food-column h6 {
    height: 40px;
    line-height: 1.4;
}
.sec-article .title{
    font-size: 28px;
    align-items: start;
    justify-content: start;
}
.art-head{
    font-size: 1.8rem;
}
.cus-slider .food-column.article-card{
    padding: 0;
    margin-right: 20px;
    min-width: 262px;
    width: 262px;
}
.article-card img{
    border-radius: 8px;
}
.article-card a{
    text-decoration: none !important;
}
.article-card h6{
    font-size: 15px;
    color: #281713 !important;
    font-weight: 600;
    height: 44px;
    margin: 2px 0;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: start;
    text-transform: capitalize;
}
.article-card p{
    height: 36px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin-bottom: 2px;
    -webkit-box-orient: vertical;
    font-size: 13px;
    font-weight: 400;
}
.article-card .product-details{
    margin-bottom: 0;
    padding: 15px;
}
.drop-modal .map {
    position: relative;
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    margin-top: 15px;
    left: 0px;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
}
.delivery-tabs h6 {
    color: #575757;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.8em;
}
.delivery-tabs label {
    color: #575757;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.8rem;
}
.drop-modal ul li{
    width: 50%;
}
.drop-modal ul li p{
    width: calc(100% - 8px) !important;
    margin-right: 8px;
}
.drop-modal ul li:last-child p{
    width: calc(100% - 8px);
    margin-left: 8px;
}
.delivery-tabs form.input-form {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.delivery-tabs form.input-form .form-group{
    margin-bottom: 1rem;
}
/*.delivery-tabs form.input-form label {*/
/*    font-size: 13px;*/
/*    margin-bottom: 5px;*/
/*    color: #281713;*/
/*    font-weight: 400;*/
/*}*/
/*.delivery-tabs form.input-form .form-control {*/
/*    border-radius: 20px;*/
/*    height: 40px;*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    padding-right: 14px;*/
/*}*/
.delivery-tabs .tab-content {
    margin-top: 1.5rem;
}
.delivery-tabs .tab-content .form-group .form-control {
    border-radius: 20px;
    font-weight: 500;
    color: black;
    font-size: 14px;
    width: 100%;
    height: 40px;
    padding: 0px 40px 0px 20px;
}
.modal-content .modal-body .map p {
    color: #00506a;
    font-weight: 600;
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
}
.modal-content .modal-body .map p:hover {
    color: #2e3a59;
}
.modal-content .modal-body .start-btn .btn {
    color: white;
    background: #dc2a27;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    height: 40px;
    margin: 1.5rem 0 1rem;
}
.modal-content .modal-body .exit-details {
    width: max-content;
    margin: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 8px;
}
.modal-content .modal-body .exit-details p {
    transition: 0.2s;
}
.modal-content .modal-body .exit-details p:hover {
    color: #c10000;
    font-weight: 700;
}
.modal-content .modal-body .exit-details p i{
    font-weight: 100;
    font-size: 20px;
}
.modal-content .modal-body .exit-details a {
    text-decoration: none;
    color: #6c6c6c;
    font-size: 12px;
}
.main-head{
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 20px !important;
    position: relative;
}
.main-head:after{
    position: absolute;
    content: '';
    width: 260px;
    height: 1.5px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 2px;
    background: #008CBA;
}
.page_404{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 155px);
}
.page_404 .content{
    height: fit-content;
}
.page_404 h1{
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 20px !important;
}
.page_404 h3{
    font-size: 20px;
}
.bg-success-25{
    border: 1px solid #70ff70;
    background-color: rgba(0, 128, 0, 0.05);
}
.bg-primary-25{
    border: 1px solid #2cc0c0;
    background-color: rgb(0 82 128 / 8%);
}
.bg-danger-25{
    border: 1px solid #ffa7a7;
    background-color: rgba(255, 0, 0, 0.05);
}
.info-box{
    font-size: 13px;
    color: #696969;
    font-weight: 500;
    line-height: 1.2;
}
.info-box span{
    padding: 0.375rem 0.75rem;
    line-height: 1.4;
}
/*===================/dropdown header modal design======================*/
@media (min-width: 1400px) {
    .container-fluid {
        max-width: 1400px;
    }
    .container-fluid.container-width {
        max-width: 100%;
    }cd
    .drop-col {
        flex: 0 0 auto;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .display-details .food-column{
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%;
    }
}
/*======================for minisidebar and arrow click sidebar=======================*/
.container1 {
    background-color: white;
    position: fixed;
    /*height: 100%;*/
    /* transition: width .3s; */
    z-index: 1000;
    border: 1px solid #f1f1f1;
    bottom: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
    box-shadow: -3px -2px 20px 1px rgb(0 0 0 / 12%);
    /*transition: 0.3s all ease-in-out;*/
    border-top: 0;
    right: 64px;
    width: 0;
    margin-right: -66px;
}
.border-theme{
    border-color: #f1f1f1 !important;
}
#sidebar-wrapper .container1{
    height: 100vh;
}
.container1 .sidebar-front{
    height: calc(100% - 50px);
    overflow-y: overlay;
}
#sidebar-wrapper .main-cart .side-cart-list {
    padding: 1rem 1rem;
    height: calc(100vh - 144px);
    overflow-y: auto;
    overflow-x: hidden;
}
#sidebar-wrapper .main-cart .side-cart-list.coupon-list{
    border: 0;
    height: calc(100vh - 50px);
    padding: 15px 0;
}
.offer-card .payment-card{
    border: 1.5px dashed #e2e2e2 !important;
}

.offer-card .payment-card.selected{
    border-color: #ffc601 !important;
}
.br-0{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
/*#sidebar-wrapper .main-cart .side-cart-list{*/
/*    overflow: hidden;*/
/*}*/
.cus-slider ul{
    display: flex;
    align-items: center;
    list-style: none;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 25px;
    scroll-behavior: smooth;
}
.cus-slider ul::-webkit-scrollbar-track {
    background-color: rgb(238, 236, 236);
    border-right: 40px;
    border-radius: 40px;
}
.cus-slider .nav-btn{
    position: absolute;
    font-size: 25px;
    text-align: center;
    padding: 0;
    line-height: 42px;
    width: 44px;
    height: 44px;
    border-radius:50px;
    border: 0 !important;
    color: white;
    outline: 0 !important;
    background: rgba(0,0,0,0.5);
    box-shadow: 0 0px 6px 0 #cdcdcd;
    top: 42%;
    transition: 0.25s all ease-in-out;
    z-index: 5;
}
.cus-slider .nav-btn.next{
    right: -20px;
    padding: 0 18px;
}
.cus-slider .nav-btn.prev{
    left: -20px;
    padding: 0 15px;
}
.cus-slider.recent .nav-btn{
    top: 27%;
}
.cus-slider .nav-btn:hover{
    background: rgba(0,0,0,0.6);
}
.cus-slider ul::-webkit-scrollbar {
    /*width: 3px;*/
    position: absolute;
    cursor: pointer;
    height: 10px;
    border-radius: 40px;
    background-color: rgb(238, 236, 236);
    border-right: 40px;
}
/*.cus-slider:hover ul::-webkit-scrollbar{*/
/*  height: 6px;*/
/*}*/
.cus-slider ul::-webkit-scrollbar-thumb {
    background-color: #56aac5;
    border-radius: 10px;
    border-right: 40px;
}
.myScroll{
    height: 48px;
    width: 100%;
}
.myScroll{
    height: 48px;
    width: 100%;
}
.scrollMain{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.scrollTrack{
    background-color: rgb(238, 236, 236);
    border-radius: 40px;
    position: relative;
    width: 100%;
    height: 4px;
}
.scrollThumb{
    background-color: rgb(65, 65, 66);
    border-radius: 4px;
    height: 100%;
    position: absolute;
    width: 50%;
}
.cus-slider .food-column{
    margin-bottom: 0;
}
.cus-slider .food-column{
    margin-bottom: 0;
    width: 230px;
    min-width: 230px;
    margin-right: 15px;
}
.cus-slider .food-column:last-child{
    margin-right: 0;
}
.cus-slider.recent .food-column{
    width: 250px;
    min-width: 250px;
}

#sidebar-wrapper .main-cart .side-cart-list .food-details{
    position: relative;
    transition: 0.2s all ease-in-out;
    left: 0;
}

#sidebar-wrapper .main-cart .side-cart-list .food-details a{
    font-size: 14px;
    font-weight: 500;
    color: #414142;
    text-align: left;
}
#sidebar-wrapper .main-cart .side-cart-list .food-details h6 span{
    font-size: 13px;
}
#sidebar-wrapper .main-cart .side-cart-list .food-details h6 span select{
    font-size: 13px;
    font-weight: 500;
    color: #000;
}
.slide-items .area{
    opacity: 0;
    position: absolute;
    left: -100%;
    transition: 0.2s all ease-in-out;
    width: max-content;
}
.slide-items.confirm{
    position: relative;
}
.slide-items.confirm .area{
    position: absolute;
}
.slide-items.confirm .area{
    left: 0;
    opacity: 1;
}
.slide-items.confirm .area p{
    font-size: 14px;
}
.slide-items.confirm .area .btn-outline-dark{
    border: 1px solid #696969 !important;
    color: #696969 !important;
}
.slide-items.confirm .area .btn-outline-dark:hover{
    color: white !important;
}
.slide-items.confirm .area .btn-danger{
    border: 1px solid #c82281713 !important;
}
.slide-items.confirm .food-details{
    left: 260px !important;
    opacity: 0.6;
}
#sidebar-wrapper .main-cart .side-cart-list select{
    height: 30px;
    /*width: 70px;*/
    width: max-content;
    padding: 0 5px;
    color: #212121;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
    outline: none !important;
    border-radius: 15px;
    border: 1px solid #ccc;
    z-index: 1;
}
/*.container1:before{*/
/*    border: solid #E5E5E5;*/
/*    border-width: 0 2px 2px 0;*/
/*    display: inline-block;*/
/*    padding: 3px;*/
/*    transform: rotate(135deg);*/
/*    -webkit-transform: rotate(135deg);*/
/*    content: "";*/
/*    position: fixed;*/
/*    display: block;*/
/*    margin-top: 43px;*/
/*    margin-left: -5px;*/
/*    background: white;*/
/*}*/
.container1.open:before {
    display: none;
}
.container1.open {
    width: 266px;
}
.offer-container1 {
    width: 400px !important;
    max-width: 100% !important;
    right: -100%;
    margin-right: 0;
    transition: 0.3s all ease-in-out;
    border: 0;
}
.offer-container1.open {
    right: 0;
}

.offer-container1.open {
    animation: slideInRight 0.2s forwards;
}
.offer-container1.close {
    animation: slideOutRight 0.2s forwards;
}
.background {
    background: #5ab7d4;
    height: 50px;
}
.background-yellow{
    background: #ffc601;
}
.closeburger {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 50px;
    text-align: left;
    padding-left: 30px;
    line-height: 30px;
    color: white;
    display: flex;
    align-items: center;
}
.closeburger:before {
    content: '\f104';
    top: 10px;
    left: 15px;
    transition: top 500ms ease 500ms, transform 500ms ease;
    font-family: fontawesome;
    position:absolute;
}
.offer-container1 .closeburger{
    padding-left: 15px;
    padding-right: 15px;
}
.offer-container1 .closeburger:before {
    display: none;
}
.offer-container1 .coupon-field h6.apply{
    right: 6.5px;
}
.open .closeburger:before {
    content: '\f104';
    top: 10px;
    left: 15px;
    transition: top 500ms ease 500ms, transform 500ms ease;
    font-family: fontawesome;
    position:absolute;
    transform: rotate(180deg);
}
.container1 .main-cart {
    display: none;
}
.container1.sidebar-front {
    display: block;
}
.container1.open .main-cart {
    display: block;
}
.container1.open .sidebar-front {
    display: none;
}
.container1 .sidebar-front{
    padding: 10px 0;
}
.container1 .sidebar-front .food-images {
    width: 100%;
    margin: 5px auto;
    display: flex;
    align-items: center;
}
.container1 .sidebar-front .food-images img {
    width: 100%;
    margin: auto;
}
.main-cart {
    z-index: 99;
}
.main-cart .cart-padding {
    padding: 1.3rem 1rem .6rem;
    border: 1px solid rgba(0,0,0,.1);
}
.main-cart .side-cart-list{
    padding: 1rem 1rem;
}
.main-cart .cart-title{
    position: absolute;
    top: 16px;
}
.main-cart .cart-title h6{
    font-size: 14px;
    font-weight: 600;
    color: white;
}
.main-cart .estimate-order {
    width: 100%;
    float: left;
}
.main-cart .estimate-order h6 {
    color: #212121;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
    padding: 15px 0px;
}
.prop-in{
    margin: 0 0 0 5px;
    font-weight: 400 !important;
}
.main-cart .estimate-order h6 span {
    float: right;
    font-weight: 700;
}
.main-cart .estimate-order p {
    font-size: 13px;
    color: #212121;
    margin-bottom: 0px;
    font-weight: 400;
    padding: 8px 0px;
    border-bottom: 1px solid #e8e8e8;
}
.main-cart .estimate-order p span{
    float: right;
}

button.btn.btn-proceed {
    background: #fec601;
    /*border: 1px solid #ffc601;*/
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    transition: 0.5s all;
}
#sidebar-wrapper .main-cart .estimate-order{
    width: 210px;
}
#sidebar-wrapper .main-cart .estimate-order h6{
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: 700;
    font-size: 1.5rem;
}
#sidebar-wrapper .main-cart .estimate-order p{
    border: 0;
    padding-top: 3px;
}
#sidebar-wrapper .main-cart .estimate-order h6 span{
    float: unset;
}
#sidebar-wrapper .main-cart button.btn.btn-proceed {
    float: right;
    width: calc(100% - 210px);
    margin-top: 2px;
}
.price-info{
    margin-left: 3px;
    width: 14px;
    height: 14px;
    transform: rotate(180deg);
    outline: none !important;
    stroke: #999999;
}
.price-info-text{
    line-height: 1.4;
    font-weight: 500;
    display: block;
}
.price-info path{
    stroke: #999999;
}
.main-cart button.btn.btn-proceed:hover {
    background: #f0bb02;
    border-color: #f0bb02;
}
.cart-inner-details {
    margin-left: 10px;
}
.cart-food-details {
    background: #eeeeee;
    padding: 0.5rem 0.8rem;
    margin-bottom: 0.5rem;
}
.cart-food-details h6 {
    font-size: 14px;
    color: #281713;
}
.cart-food-details h6 span {
    float: right;
    font-size: 12px;
    margin-top: 1px;
}
.cart-inner-details h6 {
    font-size: 15px;
    color: #212121;
}
#sidebar-wrapper .food-details .imgCart {
    width: 65px;
    height: 65px;
    float: left;
    position: relative;
}
#sidebar-wrapper .food-details .imgCart img{
    position: absolute;
    top: 0;
    height: 80%;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}
#sidebar-wrapper hr{
    margin-top: .5rem;
    margin-bottom: .8rem;
}
#sidebar-wrapper .food-details .close-btn {
    float: left;
    margin: 0px;
    width: 30px;
    max-width: 30px;
    padding: 0px;
}
#sidebar-wrapper .food-details .close-btn button{
    border: 0 !important;
    border-radius: 100px;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    transition: 0.3s all ease-in-out;
    width: 28px;
    color: black;
    line-height: 12px;
}
#sidebar-wrapper .food-details .close-btn svg{
    font-size: 14px;
    color: black;
}
#sidebar-wrapper .food-details .cart-inner-details {
    width: calc(100% - 65px);
    padding-left: 10px;
    float: left;
    margin-left: 0px;
    display: flex;
    align-items: center;
}
#sidebar-wrapper .food-details .cart-inner-details .item-info{
    width: calc(100% - 80px);
}
#sidebar-wrapper .food-details .cart-inner-details .price{
    width: 80px;
}
#sidebar-wrapper .food-details .cart-inner-details h6{
    margin-bottom: 0;
    font-weight: 500;
}
#sidebar-wrapper .food-details .cart-inner-details .food-details {
    margin-bottom: 0px;
    padding: 0px;
}
#sidebar-wrapper .food-details .cart-inner-details .cart-table {
    float: left;
}
#sidebar-wrapper .food-details .cart-inner-details .food-details .cart-price {
    margin-left: 0px;
    box-shadow: unset;
    border: 0;
}
#sidebar-wrapper
.food-details
.cart-inner-details
.product-details
.food-content
p.price {
    margin-top: 5px;
    text-align: left;
}
#sidebar-wrapper .product-quantity-cart .skin-5 .num-in{
    height: 30px;
    width: 80px;
    padding: 0;
    margin-top: 5px;
}
#sidebar-wrapper .product-quantity-cart .skin-5 select.num-in{
    padding: 0 0 0 10px;
    outline: none !important;
    font-size: 14px;
    font-weight: 700;
    width: 75px;
    margin-left: 10px;
}
#sidebar-wrapper  .product-quantity-cart .skin-5 .num-in input{
    font-size: 14px;
    height: 27px;
    right: -1px;
    user-select: none;
    pointer-events: none;
}
#sidebar-wrapper .product-quantity-cart .skin-5 .num-in span {
    width: 28px;
    top: 0px;
    margin: 0;
    padding: 7px 10px;
    height: 28px;
}
#sidebar-wrapper  .product-quantity-cart .skin-5 .num-in span.minus.decrement{
    left: 0;
}
#sidebar-wrapper .weight{
    font-size: 13px;
    margin-top: 2px;
    width: 100%;
}
#sidebar-wrapper .weight span{
    float: right;
}
#sidebar-wrapper .price{
    width: max-content;
    float: right;
    margin: 0;
    text-align: right;
}
.close-btn h6 {
    width: 24px;
    height: 24px;
    background: #e0e0e0;
    border-radius: 50px;
    text-align: center;
    color: black;
    line-height: 1.4;
    margin-top: 0.4rem;
    cursor: pointer;
}
.close-btn h6 i {
    font-weight: 100;
    font-size: 14px;
}
.close-btn.size-sm h6{
    height: 20px;
    width: 20px;
    line-height: 0.85;
    margin-top: 0;
}
.close-btn.size-sm h6 i {
    font-size: 12px;
}
.skin-2 .num-in span {
    width: 35%;
    display: block;
    height: 21px;
    float: left;
    position: relative;
    cursor: pointer;
}
.cart-price {
    margin-left: 10px;
}

.cart-table.qty-button .product-quantity-cart .skin-5 .num-in {
    height: 26px;
    width: 70px;
    border: 1px solid #ccc;
    z-index: 1;
    /* background: transparent; */
}
.cart-table.qty-button .product-quantity-cart .skin-5 .num-in span {
    position: absolute;
}
.cart-table.qty-button .product-quantity-cart .skin-5 .num-in input {
    right: 3px;
    top: 0px;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    margin: auto;
    left: 0px;
    text-align: center;
    width: 100%;
    float: none;
}
.cart-table.qty-button .product-quantity-cart .skin-5 .num-in span.plus {
    right: 0px;
    top: 5px;
    z-index: 2;
    width: 15px;
    font-size: 10px;
}
.cart-table.qty-button .product-quantity-cart span.minus.decrement {
    top: 5px;
    float: none;
    left: 9px;
    z-index: 2;
    width: 9px;
    font-size: 10px;
}
.table-items{
    margin-bottom: 100px;
}
/*======================/for minisidebar and arrow click sidebar=======================*/

/*======================/for toggle and expand minisidebar and content=======================*/

#wrapper {
    padding-right: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-right: 93px;
}
#wrapper.toggled .btn-order.btn-float{
    right: 100px
}
#wrapper.toggled .container1 {
    width: 90px;
    height: calc(100vh - 125px);
}
#wrapper.toggled .container1.open {
    width: 420px;
    transition-property: none;
    overflow: hidden;
}
.opacity-0{
    opacity: 0;
    z-index: -2;
}
@media (min-width: 768px) {

    .div-breadcrumb{
        padding: 0;
    }
    #wrapper {
        padding-right: 0;
    }

    #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        position: relative;
    }
    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-left: 0;
    }

}
/*======================/for toggle and expand minisidebar and content=======================*/

/*====================single product details page==========================*/
#myCarousel {
    padding: 1rem;
}
.img-overlay{
    position: relative;
    z-index: 4;
}
.image-gallery-slide-wrapper{
    opacity: 0;
    position: absolute !important;
}
.slider:before {
    display: none;
}
#myCarousel .list-inline {
    white-space: nowrap;
    overflow-x: auto;
}

#myCarousel .carousel-indicators {
    position: static;
    left: initial;
    width: initial;
    margin-left: initial;
}

#myCarousel .carousel-indicators > li {
    width: 104px;
    height: 95px;
    text-indent: initial;
    opacity: 1;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    line-height: 95px;
}
#myCarousel ul {
    padding: 0;
    margin-bottom: 0;
}
.carousel-indicators li {
    margin-left: 0;
}
#myCarousel .carousel-indicators > li.active {
    border-color: #dc2a27;
}

#myCarousel .list-inline-item:not(:last-child) {
    margin-right: 9px;
}
ul.carousel-indicators.product-slider li a img {
    width: 100%;
}
ul.carousel-indicators.product-slider {
    padding-left: 8px;
}
.react-slider img{
    border: 0 !important;
    border-radius: 5px;
}
.image-gallery-thumbnail{
    width: 70px !important;
    overflow: hidden;
    transition: 0.2s all ease-in-out;
    border-radius: 10px;
    padding: 2px;
    border: 2px solid #ffffff !important;
}
.image-gallery-thumbnail img{
    transition: 0.2s all ease-in-out;
}
.image-gallery-thumbnail:hover{
    border-radius: 10px;
    border: 2px solid #e2e2e2 !important;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
    border-radius: 10px;
    border: 2px solid #56aac5 !important;
}
.overlay {
    position: relative;
}

.overlay:after {
    content: '\f04b';
    font-family: 'FontAwesome';
    position: absolute;
    left: 45%;
    width: 20px;
    height: 20px;
    background: #ff9933;
    border-radius: 50px;
    text-align: center;
    line-height: 20px;
    font-size: 10px;
    top: 40%;
    color: white;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0px;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.carousel .carousel-status {
    display: none;
}
.control-dots {
    display: none;
}
.carousel .thumb {
    padding: 5px !important;
    cursor: pointer;
    border-radius: 10px;
    width: calc(25% - 5px) !important;
}
.carousel .thumb:hover {
    border: 1px solid #ddd !important;
}
.carousel .thumb.selected {
    border: 2px solid #5ab7d4 !important;
}
.carousel .thumbs-wrapper {
    margin: 10px 0 !important;
    overflow: hidden;
}
.slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    background-color: #dc2a27;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.single-pro.product-details {
    padding-left: 0px;
    padding-top: 20px;
    margin-bottom: 1.5rem;
    box-shadow: unset;
    border: unset;
}
.single-pro.product-details .food-maintitle h1{
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: capitalize;
}
.recent-view .scrollbar {
    height: auto;
    display: inline-flex;
    padding-bottom: 5px;
    width: 100%;
    border-bottom: 0;
}
.recent-view .scrollbar .most-product{
    display: inline-flex;
}
.recent-view .most-product .product-details{
    width: 230px;
    padding-top: 0;
    margin-right: 15px;
    border: 1px solid #eaeaea;
}
.recent-view .most-product .product-details .buttons{
    padding: 0 15px 15px;
}
/*.recent-view .most-product .product-details .buttons{*/
/*    display: none;*/
/*}*/
.recent-view .most-product .product-details .btn-blank{
    display: none;
}
.recent-view .force-overflow{
    min-height: auto;
}
.recent-view .complete-order .food-content{
    margin-bottom: 0px !important;
    padding-left: 0;
}
.recent-view #style-4::-webkit-scrollbar {
    height: 4px;
    border-radius: 40px
}
#style-4::-webkit-scrollbar-thumb {
    background: #56aac5;
    height: 4px;
    border-radius: 40px
}
.sale-banner.product-details {
    box-shadow: unset;
    overflow: visible;
    border: unset;
    /*border-radius: 0 !important;*/
}
.info-pro p{
    font-size: 14px;
    text-align: justify;
}
.info-pro ul li{
    font-size: 14px;
    color: #555;
}
div.desc ul li{
    font-size: 14px;
    color: #555;
}

.info-pro{
    margin-bottom: 10px;
}
.info-pro ul{
   list-style: inherit !important;
    padding-left: 20px;
}
.single-pro.product-details .weight{
    font-size: 14px;
}
.single-pro.product-details .label-img {
    margin: 0;
    display: inline-block;
}
.single-pro.product-details .label-img img {
    width: 24px;
    margin-right: 10px;
    float: left;
}
.icon-details-click {
    font-size: 14px;
    font-weight: 600;
    color: #2e94b2 !important;
    margin-top: 5px;
    display: block;
    float: left;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}
.icon-details-click:hover{
    color: #1981a1 !important;
}
.single-product .block {
    padding: 0 20px;
    margin-bottom: 5px;
}
.single-product .block div.desc{
    font-size: 14px;
    font-weight: 400;
}
.single-product .info-pro ul{
    list-style: inherit;
    padding-left: 20px;
}
.image-gallery-slide{
    width: 100% !important;
    max-width: 100% !important;
}

.single-product .product-details .onsale-details{
    position: relative;
}
.single-product ul li{
    font-size: 14px;
    font-weight: 400;
}
.single-product ul li{
    font-weight: 500 !important;
}
.single-product ul li strong{
    font-weight: bolder !important;
}
.single-product .product-details .onsale-details{
    top: 0;
    width: max-content;
    width: -moz-max-content;
}
.single-product .product-details .onsale-details span {
    background: #fec601;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 18px;
}
.text-less{
    font-weight: 400;
}
.single-product .block b{
    font-weight: 500;
    color: #969696;
}
.single-product .block:last-child {
    margin-bottom: 20px;
    color: #696969;
}
.single-product .image{
    width: 45%;
    max-width: 45%;
    flex: unset;
    margin-bottom: 30px;
}
.single-product .right{
    width: 55%;
    max-width: 55%;
    flex: unset;
}

.single-product .tags {
    margin-right: 6px;
    padding-right: 6px;
    border-right: 1px solid #e2e2e2;
    font-size: 13px;
    color: #696969;
}
/*.single-product span {*/
/*    color: #696969;*/
/*    margin-right: 6px;*/
/*    padding-right: 6px;*/
/*    border-right: 1px solid #e2e2e2;*/
/*    font-size: 13px;*/
/*}*/
.single-product .tags:last-child{
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}
.single-pro-options{
    margin-bottom: 36px;
}
.single-pro.product-details .food-content h6{
    height: unset;
    font-size: 16px;
    font-weight: 600 !important;
    margin-bottom: 15px;
}
.single-pro.product-details .food-content h6:last-child{
    font-size: 14px;
}
.single-pro.product-details .food-content h6 del{
    font-size: 80%;
    margin-left: 8px;
    font-weight: 500;
    color: #8c8c8c;
}
.single-pro-options li {
    padding: 5px 0 10px;
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 10px;
}
.single-pro-options li:last-child{
    border-bottom: 0;
    margin-bottom: 5px;
    padding-bottom: 0;
}
.single-pro-options li svg{
    width: 18px;
    margin-right: 8px;
    color: #5ab7d4;
    float: left;
    transition: 0.3s;
}
.single-pro-options li span{
    color: #212121;
    position: relative;
    top: -2px;
    transition: 0.3s;
}
.single-pro-options li:hover{
    font-weight: 600;
    cursor: pointer;
}
.single-pro-options li:hover svg{
    color: #258cac;
}

/*==============modal for navbar===============*/
.sidebar-modal.modal.left .modal-dialog {
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}
.sidebar-modal.modal.left .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    max-width: 100%;
    height: 100%;
}

.sidebar-modal.modal.left .modal-content {
    height: 100%;
    overflow-y: auto;
}

.sidebar-modal.modal.left .modal-body {
    padding: 0;
}

.sidebar-modal.modal.left.fade .modal-dialog {
    left: -500px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.sidebar-modal.modal.left.fade.show .modal-dialog {
    left: 0;
}

.sidebar-modal.modal.left .modal-content {
    border-radius: 0;
    border: none;
}

.sidebar-modal.modal.left .modal-dialog.modal-sm,
.modal.right .modal-dialog.modal-sm {
    width: 250px;
}
.sidebar-modal .modal-header {
    padding: 0.5rem 1rem;
}
.dropdown.bootstrap-select.form-control:hover {
    background-color: #e8e8e8;
}
.info-bar {
    background: white;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    position: relative;
    /*box-shadow: 0 0 35px rgb(0 0 0 / 3%);*/
    border: 1px solid #eeeeee;
    padding: 1rem;
}
.info-bar .left {
    float: right;
    width: calc(100% - 270px);
    max-width: calc(100% - 270px);
    flex: unset;
}
.info-bar .left small {
    font-size: 14px;
    margin: 5px;
    color: dimgrey;
    font-weight: 500;
    display: block;
    line-height: 1.3;
}
.info-bar .right {
    float: right;
    width: 270px;
    padding: 0px 15px;
    max-width: 320px;
    flex: unset;
    color: dimgrey;
}
.info-bar .right span {
    font-size: 14px;
    margin: 5px;
    color: dimgrey;
}
.info-bar .right .dropdown .dropdown-item {
    font-size: 14px;
}
.info-bar .right select {
    background: #f1f1f1;
    border-radius: 100px;
    font-size: 14px;
    width: 170px;
    text-align: left;
    color: #212121;
    font-weight: 500;
    padding: 5px 13px;
    border: 0 !important;
    margin-left: 10px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 18px;
}
.info-bar .right select:focus-visible{
    border: 0 !important;
    outline: none !important;
}
.info-bar .right select:focus-within{
    border: 0 !important;
    outline: none !important;
}
.info-bar .right .dropdown .btn::after {
    float: right;
    margin: 8px 0px;
}
.text-theme{
    color: #d30000 !important;
}
.bg-theme{
    background: #d30000 !important;
}
/*======================cart css=====================*/
.main-section.main-cart {
    margin-top: 1rem;
}
.cart-pickup{
    display: inline-block;
}
.cart-pickup h3{
    font-weight: 700;
    color: #696969;
}
.cart-pickup svg{
    width: 20px;
    height: 20px;
    position: relative;
}
.cart-pickup span {
    font-weight: 400;
    font-size: 14px;
    color: #212121;
}
.cart-pickup span span{
    padding: 0;
}
.cart-pickup b {
    font-weight: 700;
    font-size: 16px;
    /*margin-left: 5px;*/
    color: #212121;
}
.cart-pickup span span{
    font-weight: 500;
    margin-left: 0;
    top: 0;
    font-size: 14px;
    color: #414141;
}
.pickup-main{
    padding: .8rem 1rem;
    border: 1px solid #d4d4d4;
}
.edit-order {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #f1f1f1;
    text-align: center;
    line-height: 30px;
    margin-left: 10px;
    color: #d30000;
}
.cart-pickup span b {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
}

.cart-selection .selector-pick{
    display: inline-block;
    justify-content: space-between;
    width: 100%;
}
.cart-selection .pickup-date.payment-method {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    width: calc(100% / 7 - 9px);
}

.cart-selection .pickup-date.tip-method {
    width: calc(100% / 3 - 9px) !important;
}

.cart-selection .pickup-date.payment-method:nth-child(7){
    margin-right: 0;
}
.selector-pick .pickup-date:last-child{
    margin-right: 0;
}
.select-address{
    padding-bottom: 10px;
}

.cart-selection .pickup-time {
    width: calc(100% / 5 - 9px);
    margin-right: 10px;
    position: relative;
    height: 48px;
    float: left;
}

.cart-selection .pickup-time:nth-child(5){
    margin-right: 0;
}
.cart-selection .pickup-time:nth-child(10){
    margin-right: 0;
}
.cart-selection .pickup-time:nth-child(15){
    margin-right: 0;
}
.custom-date {
    position: absolute;
    float: right;
    width: max-content;
    color: black;
    margin-bottom: 0;
    top: -2px;
    right: 0px;
    border: 1px solid #008CBA;
    padding: 0 8px;
    border-radius: 4px;
}

.custom-date img {
    position: relative;
    width: 20px;
    margin-right: 15px;
}
.custom-date span {
    font-weight: 500;
    top: 0px;
    color: #008CBA;
    font-size: 13px;
    position: relative;
}
.custom-date span:hover{
    color: #0066c0;
}
.custom-date .react-datepicker-wrapper {
    position: absolute;
    z-index: 2;
    height: 100% !important;
    width: 100%;
    left: 0;
    opacity: 0;
    top: 0;
}
.custom-date .react-datepicker__input-container {
    height: 100% !important;
    width: 100%;
}

.custom-date
.react-datepicker-wrapper
.react-datepicker__input-container
input {
    height: 100% !important;
    width: 100%;
    cursor: pointer;
    user-select: none;
}
.cart-selection .payment-method.disabled label{
    opacity: 0.6;
    border: 1px solid #ced4da;
}
.cart-selection .payment-method.disabled input{
    cursor: no-drop;
}
.cart-selection .payment-method label {
    border: 1px solid #56aac5;
    padding: 0.6rem 0.3rem;
    border-radius: 6px;
    margin-bottom: 0;
    font-size: 13px;
    cursor: pointer;
    /*background: #f7f7f7;*/
    width: 100%;
    line-height: 1.4;
    transition: all ease-in-out 0.3s;
    text-align: center;
}
.cart-selection .payment-method span {
    font-weight: 500;
    width: 100%;
    display: block;
    transition: all ease-in-out 0.3s;
    color: #696969;
}
.cart-selection .payment-method b {
    font-weight: 600;
    font-size: 15px;
}
.cart-selection .payment-method input {
    position: absolute;
    width: 100%;
    z-index: 2;
    opacity: 0;
    height: 100%;
    cursor: pointer;
}

.cart-selection .payment-method :checked + label {
    border: 1px solid #56aac5;
    background-color: #56aac5;
    color: white;
}
.cart-selection .payment-method :checked + label span{
    font-weight: 700;
    color: white;
}

.cart-selection .payment-method :checked + label h5{
    font-weight: 700;
    color: white;
}
.cart-selection .payment-method :checked + label b{
    font-weight: 700;
}

.cart-selection .payment-method.tip-method label{
    border: 1px solid #c9c9c9;
    position: relative;
    padding: 0.3rem 0.3rem 0.4rem !important;
}
.cart-selection .payment-method.tip-method :checked + label:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid #2a87d0;
    border-radius: 5px;
}
.cart-selection .payment-method.tip-method label *{
    color: #665;
}
.cart-selection .payment-method.tip-method :checked + label{
    border-color: #2a87d0;
    background: white;
    color: #281713;
}
.cart-selection .payment-method.tip-method :checked + label *{
    color: #281713;
}

.cart-selection .payment-method:last-child{
    margin-right: 0;
}
.cart-selection .pickup-date label{
    height: 80px;
    max-height: 80px;
}
.complete-order .product-details{
    padding: 15px;
}
.complete-order .product-details .value-slc{
    width: 100%;
}
.bg-section{
    background: #f7f7f7 !important;
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 5px 15px 0;
}

.bg-section .complete-order{
    background: transparent !important;
}
.bg-section .complete-order .most-product .product-details{
    padding: 0 0 10px !important;
}
.bg-section .complete-order .most-product .product-details a{
    padding: 11px 0 11px 15px;
}
.bg-section .complete-order .most-product .product-details a.ad-fc{
    padding: 0;
}
.bg-section .complete-order .most-product .product-details a a{
    padding: 0;
    display: block;
}
.bg-section .complete-order .most-product .product-details a .food-content{
    margin-bottom: 0;
}
.bg-section .complete-order .most-product .product-details a .food-img{
    margin-bottom: 0;
}
.bg-section .complete-order .most-product .product-details .buttons{
    margin-bottom: 5px;
}
.bg-section .complete-order .cus-slider ul{
    padding-bottom: 15px;
    margin-bottom: 5px;
}
.bg-section .complete-order .food-column .d-button {
    position: absolute;
    width: calc(100% - 20px);
    left: 10px;
    bottom: 0;
    padding: 0px;
}
.bg-section .complete-order .most-product .product-details .buttons.d-button{
    margin-bottom: 0;
}
.bg-section .complete-order .food-column .d-button .deliverable{
    padding: 15px 5px;
}
.complete-order .recent .food-column .d-button{
    position: absolute;
    width: calc(100% - 20px);
    left: 10px;
    bottom: 5px;
    padding: 0;
}
.complete-order .recent .food-column{
    height: 130px;
}
.complete-order .recent .food-column .product-details{
    height: 100%;
}
.bg-section .complete-order .food-column{
    padding-right: 15px;
    height: 155px;
}
.bg-section .complete-order .food-column .product-details{
    height: 100%;
}
.bg-section .complete-order .food-column{
    border: 0;
}
.react-multiple-carousel__arrow--left{
    left: 0px !important;
    min-width: 36px;
    min-height: 36px;
}
.react-multiple-carousel__arrow--right{
    right: 0px !important;
    min-height: 36px;
    min-width: 36px;
}
.complete-order .react-multiple-carousel__arrow--right{
    right: -5px !important;
    min-height: 36px;
    min-width: 36px;
    z-index: 5;
}
.complete-order .react-multiple-carousel__arrow--left{
    left: -5px !important;
    min-height: 36px;
    min-width: 36px;
    z-index: 5;
}
.complete-order .product-details .food-img{
    width: 55px;
    height: 55px;
    float: left;
    padding: 0;
}
.react-multiple-carousel__arrow::before {
    font-size: 14px;
}
.complete-order .top-img{
    display: none;
}
.complete-order .onsale-details{
    display: none;
}
.complete-order .deliverable{
    margin: 0 0 0;
    height: 38px;
    padding: 8px 0;
    width: 100% !important;
}
.complete-order .food-content{
    float: left;
    width: calc(100% - 70px);
    padding: 0;
}
.complete-order .product-details .sub-order{
    display: none;
}
.complete-order .food-content h6{
    text-align: left;
    margin-bottom: 2px;
    height: 40px;
    overflow: hidden;
}
.special-label{
    display: none !important;
}
.complete-order .food-content .price{
    text-align: left;
}
.complete-order .food-content .price del{
    /*display: none;*/
    font-size: 11px;
}
.complete-order .food-content .price{
    font-size: 11px !important;
}
.complete-order .food-content .price span{
    font-size: 12px;
}
/*.complete-order .product-details .buttons{*/
/*    padding: 0;*/
/*}*/

.complete-order .food-column{
    margin-bottom: 0;
    margin-right: 0;
}
.complete-order .owl-prev:before{
    padding: 0;
    line-height: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    top: 36%;
}
.complete-order .owl-next:before{
    padding: 0;
    line-height: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    top: 36%;
}
.complete-order .product-details .price{
    margin-bottom: 2px;
}
.complete-order .product-details .sub-order{
    font-size: 14px;
    font-weight: 700;
    display: none;
    text-align: center;
    color: #d30000;
}
.item-cart .main-section {
    padding: 1.5rem 1rem 0.2rem;
}
.cart-table .close-btn span {
    width: 36px;
    height: 36px;
    background: #f6f6f6;
    border-radius: 50px;
    text-align: center;
    line-height: 36px;
    margin-top: 4px;
}
.cart-table .cart-img {
    padding-right: 0;
    width: 60px;
    height: 60px;
    flex: unset;
}
.cart-table .cart-img img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}
.cart-table .food-content {
    width: calc(100% - 60px);
    flex: unset;
    padding: 0 0 0 8px;
}
.cart-table .food-content h6 {
    height: unset;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;
}

.cart-table a.add-notes {
    font-size: 12px;
    color: #969696;
    text-decoration: none;
}
.cart-table a.add-notes i {
    font-size: 10px;
}
.cart-table .each-price{
    text-align: right;
    float: right;
    display: block;
    font-size: 13px;
    color: #414142;
}
.cart-table .food-content.contain .price span{
    font-size: 16px;
    color: #000;
}
.cart-table .food-content.contain .price sup{
    font-size: 16px;
    margin-right: 1px;
    color: #000;
}
.cart-table .product-quantity-cart .skin-5 .num-in {
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    height: 36px;
    padding: 6px 0;
}
.price-or{
    color: #696969 !important;
    font-size: 14px !important;
    top: 3px;
}
.cart-table .product-quantity-cart .skin-5 .num-in span.plus {
    margin-right: 8px;
}
.cart-table tr.border-details .remove {
    width: 45px;
    position: relative;
}
cart-itemlist .cart-table .table-row td.remove .area{
    position: absolute;
}
.cart-itemlist .table-cart-main{
    padding-top: 0 !important;
}
.cart-itemlist .cart-table{
    overflow: hidden;
}
.cart-itemlist .cart-table .slide-items{
    transition: 0.3s all ease-in-out;
    position: relative;
    left: 0;
}
.cart-itemlist .cart-table .slide-items .area{
    transition: 0.3s all ease-in-out;
    top: 0;
    bottom: 0;
    height: max-content;
    margin: auto;
}
.cart-itemlist .cart-table .slide-items td{
    position: relative;
    transition: 0.3s all ease-in-out;
    right: 0;
}
.cart-itemlist .cart-table .slide-items.confirm td.remove{
    transition: 0.3s all ease-in-out;
    right: -260px !important;
    opacity: 1;
}
.cart-itemlist .cart-table .slide-items.confirm .remove .area .btn{
    margin-left: 0;
}
.cart-itemlist .cart-table .slide-items.confirm td.check-mark{
    transition: 0.3s all ease-in-out;
    right: -260px !important;
    opacity: 1;
}
.cart-itemlist .cart-table .slide-items.confirm td.image{
    transition: 0.3s all ease-in-out;
    right: -260px !important;
    opacity: 1;
}
.cart-itemlist .cart-table .slide-items.confirm td.name{
    transition: 0.3s all ease-in-out;
    right: -260px !important;
    opacity: 1;
}
.cart-table td.name .each-price{
    display: none;
}
.cart-itemlist .cart-table .slide-items.confirm td.quantity{
    transition: 0.3s all ease-in-out;
    right: -260px !important;
    opacity: 1;
}
.cart-itemlist .cart-table .slide-items.confirm td.price-details{
    transition: 0.3s all ease-in-out;
    right: -260px !important;
    opacity: 1;
}

.cart-itemlist .cart-table .slide-items.confirm .remove .close-btn{
    opacity: 0.6;
}
.cart-itemlist .cart-table .slide-items.confirm .image{
    opacity: 0.6;
}
.cart-itemlist .cart-table .slide-items.confirm .name{
    opacity: 0.6;
}
.cart-itemlist .cart-table .slide-items.confirm .quantity{
    opacity: 0.6;
}
.cart-itemlist .cart-table .slide-items.confirm .price-details{
    opacity: 0.6;
}
.cart-itemlist .cart-table .slide-items .area{
    left: -280px;
}
.cart-itemlist .cart-table .slide-items.confirm .area{
    left: -260px !important;
    opacity: 1;
}
.cart-itemlist .cart-table tr.border-details .quantity {
    width: 100px;
    position: relative;
}
.cart-itemlist .cart-table tr.border-details {
    border-bottom: 1px solid #f3f3f3;
}
.review-cart .main-section {
    margin-bottom: 12px;
}
.cart-table tr.border-details:last-child {
    border: 0;
    padding-bottom: 0;
}
.cart-table tr.border-details:last-child td {
    padding-bottom: 0px;
}
.cart-table tr.border-details .quantity .product-quantity-cart {
    position: absolute;
    top: 19px;
    width: 100%;
}
.cart-table tr.border-details td {
    border: 0;
}
.cart-table tr.border-details td.quantity {
    width: 100px;
}
td.product-details.cart-width.cart-alignment.review-align span {
    font-size: 13px;
}
.cart-items h6 {
    font-weight: 700;
}
.cart-items h6 span {
    font-size: 14px;
    color: #535353;
}
#right-content .shop-section form .form-control.input-control {
    border-color: #696969;
    border-radius: 6px;
    font-size: 14px;
    /* padding: 23px 13px; */
}
#right-content .shop-section form input.form-control.input-control {
    height: 44px;
}
#right-content .main-cart button.btn.btn-proceed {
    width: 100%;
    margin: 10px 0px 10px;
}
#right-content .main-cart .note{
    color: #696969;
    font-size: 15px;
    margin: 20px 0 5px;
    font-weight: 500;
}
.scrollbar {
    height: 510px;
    overflow-y: scroll;
    margin-bottom: 0px;
    border-top: 1px solid #f3f3f3;
}

.force-overflow {
    min-height: 450px;
    overflow-x: hidden;
}

.cart-item-details.ct-info {
    padding-right: 5px;
}

#style-4::-webkit-scrollbar {
    width: 5px;
    border-radius: 40px;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #5ab7d4;
    border-radius: 20px;
}
.most-product{
    padding-right: 0;
}
.most-product .cart-row {
    padding-left: 0;
}
.most-product .food-column{
    margin-bottom: 0;
}
.most-product .product-details {
    border: 0;
    border-bottom: 1px solid #eaeaea;
    padding: 0;
}
.most-product .product-details a{
    padding: 7.5px 0 7.5px 15px;
    display: inline-block;
    float: left;
    /*display: contents;*/
}

.dropdown-action a {
    float: unset !important;
    padding: unset !important;
}

.most-product .product-details a.ad-fc{
    padding: 0;
}
.most-product .product-details a.ad-fc h6{
    padding: 0 15px 5px 10px;
}
.most-product .product-details a.ad-fc p{
    padding: 0 15px 8px 10px;
}
.most-product .product-details a a{
    padding: 0;
    display: block;
}
.most-product .product-details .price{
    text-align: left;
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 0;
}
.filter-badge{
    background: rgb(240 187 2 / 40%);
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 5px 0px 8px;
    color: black;
    text-shadow: unset;
    display: flex;
    align-items: center;
    height: 22px;
}
.filter-badge b{
    font-weight: 500 !important;
    font-size: 16px;
    margin-left: 2px;
}
.filter-main{
    float: none;
    display: flex;
    opacity: 1 !important;
    text-shadow: unset;
}
.filter-div{
    display: none;
    overflow: auto;
    width: 100%;
    padding: 0;
}
.filter-div .filter-content{
    width: max-content;
    padding: 0;
    display: -webkit-box;
}
.most-product .product-details.cart-table{
    padding: 10px 0 20px;
    border-radius: 0;
    border-bottom: 1px solid #ececec;
}
.most-product .product-details.cart-table a{
    width: 100%;
    border-radius: 0;
}
.most-product .product-details.cart-table .cart-img{
    padding-left: 0;
}
.most-product .product-details.cart-table .cart-row{
    padding: 0px 30px;
}
.most-product .product-details.cart-table .buttons{
    margin-top: 0;
    padding: 0;
}
.most-product .product-details.cart-table h6{
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 5px;
}
.most-product .product-details.cart-table p{
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 0px;
}
.most-product .pro-list span {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50px;
    background-color: #f6f6f6;
    text-align: center;
    margin-top: 0px;
}
.most-product .cart-row .cart-button .skin-5 .num-in input {
    right: -12px;
}
.most-product .cart-row .cart-button .skin-5 .num-in span.plus {
    margin-left: 62px;
}
#owl-slider .owl-stage {
    right: 60px;
}
.select-add {
    display: flex;
}

.table th {
    border: 0;
}
.cart-table .table td{
    padding: 1.2rem 0;
    border: 0;
    border-bottom: 1px solid #eaeaea;
    vertical-align: middle;
    position: relative;
}
.cart-table .table tr:last-child td{
    border-bottom: 0;
    padding-bottom: 0.75rem;
}
.cart-table p{
    margin-bottom: 0;
    font-size: 16px;
    text-align: right;
}
.cart-table p sup{
    margin-bottom: 0;
    font-size: 16px;
}
.cart-table .qty-in p{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600
}
.cart-table .remove{
    width: 40px;
}
.cart-table .check-mark {
    width: 30px;
    position: relative;
}
.cart-table .check-mark .remove{
    position: absolute;
}
.cart-table .check-mark .check{
    margin-bottom: 20px;
    padding: 0;
}
.cart-table .image{
    width: 80px;
}
.cart-table .name select{
    border: 0;
    outline: none !important;
    color: black;
    font-size: 14px;
    padding: 0;
    float: left;
    background: transparent;
    font-weight: 600;
    position: relative;
    left: -4px;
}
.cart-table .contain{
    width: 100%;
}
.cart-table .contain h6{
    font-weight: 400;
    color: #414142;
}
.cart-table .contain h6.text-sm{
    font-size: 12px;
}
.cart-table .image{
    padding-right: 15px !important;
}
.cart-table .image .contain img{
    height: 50px;
    max-width: 100%;
}

.cart-table .note{
    width: 180px;
    font-size: 14px;
}
.cart-table .note .easy-edit-wrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
}
.cart-table .note .easy-edit-wrapper:hover{
    color: black;
    font-weight: 600;
    font-style: normal;
}
.cart-table .quantity select.num-in{
    display: none;
}
.cart-table .price-details{
    width: 140px;
}
.cart-table .quantity{
    width: 96px;
}
.cart-table .quantity .skin-5 .num-in.no-change{
    border: 0;
}
.cart-table .price-details .contain{
    text-align: right;
}
.cart-table .price-details .contain .price{
    width: 100%
}
/*====================single product details page==========================*/
.main-section .food-details.main-title h5 {
    font-size: 18px;
}
.main-section .pickup-details {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 1rem;
}
.main-section .order-address {
    display: flex;
}
.main-section .pickup-details .order-info .order-address .oadd-details {
    margin-left: 8px;
}

.main-section .pickup-details .order-info .order-address .oadd-details p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}
.main-section .pickup-details .order-info .order-date p {
    margin-bottom: 0;
    color: #281713;
    font-size: 14px;
}
.main-section .pickup-details .order-column {
    margin: auto;
}
.main-section .pickup-details .order-column .order-status {
    text-align: center;
}
.main-section .pickup-details .order-column .order-status a.btn.btn-change {
    border: 1px solid #dc2a27;
    height: 34px;
    font-size: 14px;
    font-weight: 700;
    width: 176px;
}
.main-section .payment-method.payment.contact-info label {
    width: 290px;
}
.main-section .payment-method.payment label {
    border: 1px solid #ccc;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    width: 295px;
    padding: 1rem;
    display: inline-block;
    margin-right: 0.8rem;
    margin-bottom: 0;
}
.tip-main .cart-selection .selector-pick{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tip-main .input-group .input-group-prepend{
    background: white;
    left: 0;
    user-select: none;
    pointer-events: none;
    cursor: default;
    height: 100%;
}
.tip-main .input-group .input-group-prepend input{
    border-left-width: 0px !important;
}
.tip-main .promo-code.active .input-group .input-group-prepend input{
    border-left-width: 2px !important;
}
.tip-main .cart-selection .pickup-date.tip-method{
    margin: 0;
    width: calc(100% - 10px) !important;
}
.tip-main .cart-selection .pickup-date.tip-method:nth-child(2){
    margin: 0 10px;
}
.tip-main .cart-selection .pickup-date label{
    height: fit-content;
    padding: 0.3rem;
}
.tip-main .cart-selection .pickup-date label h5{
    margin: 0 !important;
    font-size: 12px;
}
.tip-main .cart-selection .pickup-date label h5 b{
    font-size: 12px;
}
.tip-main .promo-code .input-group .form-control{
    height: 36px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    font-size: 12px;
    border-left: 0 !important;
    padding-left: 43px;
}
.tip-main .promo-code .input-group .btn{
    height: 36px;
    font-size: 12px;
}
.tip-main .promo-code .input-group .form-control::-webkit-outer-spin-button,
.tip-main .promo-code .input-group .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.tip-main .promo-code.active .input-group .form-control{
    border: 2px solid #2a87d0 !important;
}
.tip-main .promo-code.active .input-group{

}
.tip-main .promo-code.active .input-group .form-control.w-36{
    border-color: #2a87d0 !important;
}
.font-weight-regular{
    font-weight: 500 !important;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.tip-main .promo-code .input-group .form-control[type=number] {
    -moz-appearance: textfield;
}
.tip-main .promo-code .input-group .form-control.w-36{
    /*width: 18px;*/
    text-align: center;
    user-select: none;
    pointer-events: none;
    cursor: default;
    padding: 0;
    background: white;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-left: 1px solid #c9c9c9 !important;
    border-right: 0 !important;;
    z-index: 2;
}
.dlv-instruction .control__indicator{
    /*top: 0;*/
    /*bottom: 1.5px;*/
    margin: auto;
}
.main-section .payment-method.payment.contact-info label h6.user-title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
}
.main-section .payment-method.payment.contact-info label p.country-contact {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 12px;
}
.main-section .payment-method.payment.contact-info label .add-edit {
    text-align: right;
}
.main-section .payment-method.payment.contact-info label .add-edit a {
    color: #009fce;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}
.main-section .cf-subtitle {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 10px;
    display: block;
    text-align: justify;
    color: #969696;
    font-weight: 400;
    margin-right: 0;
    line-height: 1.3;
}

.main-section .cf-subtitle a.more-info{
    font-size: 10px;
    margin: 0 !important;
}
.main-section .payment-method.payment .checkout-card{
    display: flex;
    align-items: center;
    gap: 20px
}
.main-section .profile .payment-method.payment{
   min-height: 100% !important;

}
.main-section .profile .payment-method.payment .checkout-card{
    display: inline-block;
}
.main-section .payment-method.payment .visa-card i {
    cursor: pointer;
}
.main-section .payment-method.payment .card-number {
    margin: 10px 0 5px 0;
    font-weight: 600;
}
.main-section .profile .payment-method.payment .card-number {
    margin: 10px 0;
    font-weight: 600;
}
.payment-method.payment .crd-holder-name{
    font-size: 18px;
}
.main-section .payment-method.payment .card-number span {
    color: #281713;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 600;
    margin-right: 10px;
    float: left;
    height: 16px;
    display: block;
}
.main-section .payment-method.payment p {
    font-size: 14px;
    color: #696969;
    margin-bottom: 5px;
    font-weight: 600;
}
.main-section .payment-method.payment .checkout-card .card-holder h6 {
    font-size: 16px;
    font-weight: 500;
    color: #281713;
    margin-bottom: 0;
}
.main-section .payment-method.payment .checkout-card .card-holder span {
    font-size: 14px;
    font-weight: 600;
    color: #281713;
    margin-bottom: 0;
}
.main-section .payment-method.payment .visa-card{
    position: absolute;
    right: 20px;
    bottom: 5px;
}
.main-section .review-product .shop-add {
    margin: auto 10px;
}
.main-section .review-product .shop-add p {
    font-size: 14px;
    width: 75%;
    margin-bottom: 0;
}
.main-section .review-product .review-content p {
    font-size: 14px;
    font-weight: 600;
    color: #281713;
    margin-bottom: 0;
}
.main-section .review-product .review-content p span {
    color: #dc2a27;
}
.main-section .review-product .review-price p {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 1.125rem;
}
.main-section .cart-item-content {
    margin: auto;
}
.main-section .cart-item-content p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}
.main-section .cart-item-content p span {
    color: #dc2a27;
    margin-left: 20px;
    font-weight: 700;
}
.main-section a.btn.btn-cart {
    width: 100%;
    color: white;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dc2a27;
    font-size: 0.875rem;
    border-radius: 25px;
}
.main-section .bottom-chebtn a.btn.btn-cart {
    width: 240px;
    padding: 7px;
}
.main-section .sub-total p {
    font-size: 14px;
    margin-bottom: 0;
    color: #212121;
}
.main-section .sub-total {
    display: flex;
}
.main-section .sub-total.amt-minus p {
    color: #00a560;
}
.main-section .sub-total.amt-plus p {
    color: #dc2a27;
}
.main-section .sub-total.amt-total p {
    font-weight: bold;
    font-size: 16px;
}
.main-section .proceed-button-check a.btn.btn-cart {
    padding: 7px;
}
.proceed-button-check {
    margin: 1rem 0;
}
.main-section .coupon-details {
    margin-top: 14px;
}
.main-section .coupon-details input {
    height: 40px;
}
.main-section .form-control.info-control {
    border-color: #c4c4c4;
    height: 44px;
}
.main-section .coupon-details .input-group-text {
    font-size: 13px;
    color: white;
    background-color: #dc2a27;
    border: 1px solid #dc2a27;
    border-radius: 0.25rem;
    cursor: pointer;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 700;
    border-left: 0;
}
.account-card .footer-content h2{
    color: #212121;
}
.account-card .footer-content .contact-details h6{
    color: #696969;
    font-weight: 500;
}
.account-card .footer-content .contact-details p{
    color: #696969;
    font-weight: 500;
}
#right-content{
    /*padding-left: 0px;*/
}
.main-section hr.amt-border {
    margin-top: 10px;
    margin-bottom: 10px;
}
.single-btn .cart-button span {
    font-size: 18px;
}
.single-btn .cart-button .skin-5 .num-in span.plus {
    margin-left: 78px;
}
.single-btn .skin-5 .num-in span.minus {
    margin-left: 10px;
}
.card-coupon .content{
    border: 1px dashed #d9d9d9;
    padding: 16px 16px 0;
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
    overflow: hidden;
}
.card-coupon.disabled {
    border-color: #d9d9d9;
}
.card-coupon .code-coupon svg{
    position: absolute;
    top: -20px;
    display: none;
    width: 16px;
    height: 16px;
}
.text-select{
    position: relative;
    top: 1px;
}
.text-secondary{
    color: #696969 !important;
}
.mr-7{
    margin-right: 7px;
}
.blr-icon{
    shape-rendering: geometricprecision;
}
.rounded-card-coupon{
    border-radius: 8px;
    padding: 12px;
}
.radius-8{
    border-radius: 8px !important;
}
.border-top-dash{
    border-top: 1px dashed rgba(0,0,0,.1);
}
.card-coupon .code-coupon{
    position: relative;
    border: 1px dashed #ffc601;
    border-right: 0;
    padding: 11px 16px 10px;
    text-align: center;
    border-bottom-left-radius: 8px;
    background: rgba(255, 198, 1, 0.2);
    cursor: pointer;
    height: 42px;
}
.card-coupon .code-coupon .text-uppercase{
    letter-spacing: 0.05rem;
    font-weight: 600;
}
.card-coupon .code-clip{
    background: #ffc601;
    padding: 13px 16px 13px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
}
.card-coupon.disabled .code-coupon{
    border-color: #d9d9d9;
    background: rgba(217, 217, 217, 0.3);
    cursor: default;
}
.card-coupon.disabled .code-clip{
    background: #d9d9d9;
    cursor: default;
}
/*====================/single product details page==========================*/
.single-product #myCarousel {
    padding-right: 0px;
    padding-left: 0px;
}
.single-product #myCarousel .carousel-item{
    height: 100%;
    align-items: center;
    cursor: default;
    display: flex;
}
.react-datepicker-popper {
    z-index: 1000 !important;
}
/*.react-datepicker__input-container input {*/
/*    height: ;*/
/*}*/
.single-product .product-description{
    padding: 15px 30px 20px;
}
.single-product .product-description p.description {
    font-size: 15px;
}
.single-product .product-description h5 {
    color: #212121;
    font-weight: 700;
    font-size: 1.1rem;
    float: left;
    margin-bottom: 5px;
    width: 100%;
}
/*====================personal information===========================*/

.row-margin {
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.account-left .main-section{
    padding: 25px 0 15px;
}
.account-left .main-section .account-details h6{
    padding: 0 15px;
}
.account-left .main-section .account-details ul li a{
    padding: 8px 15px;
}
.account-right .main-section{
    padding: 1.5rem;
}

.account-right .head{
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
}
.account-right .itemlist .top-bar{
    padding: 0 0 20px;
    margin-bottom: 0px;
    justify-content: space-between;
    display: inline-flex;
}
.account-right .coupon-section .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}
.account-right .coupon-section .content .h-sec{
    height: fit-content;
}
.account-right .coupon-section h4{
    font-weight: 600;
}
.account-right .coupon-section small{
    font-weight: 400;
}
.account-right .coupon-section button{
    width: 100%;
}
.account-right .coupon-section button.btn-icon{
    width: 40px;
    font-size: 18px;
}
.account-right .coupon-section .onsale-details {
    position: absolute;
    left: 0;
    top: -0px;
}
.account-right .coupon-section .onsale-details span {
    background: #56aac5;
    font-size: 13px;
    font-weight: 700;
    padding: 6px 14px;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.account-right .coupon-section .onsale-details.featured span {
    background: #dc2a27;
    color: white;
}
.account-right .coupon-section .onsale-details.exclusive span {
    background: #00a560;
    color: white;
}
.account-right .coupon-section .onsale-details.limited-time span {
    background: orange;
    color: white;
}
.account-right .coupon-section p{
    font-size: 14px;
    line-height: 1.4;
    color: #696969;
}
.add-badge{
    background: #00a560;
    padding: 7px 15px;
    color: white;
    font-weight: 600;
    border-radius: 5px 0 0 5px;
    font-size: 15px;
    margin: 0.5rem 0 0 1rem;
}
select.slc-button{
    text-align: left;
    padding: 0 12px !important;
    margin: 0;
}
.account-right .list-table select.slc-button{
    border: 1px solid #d1d1d1 !important;
    top: -2px;
    background: white;
    color: black;
    border-radius: 100px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><svg width="20" height="20" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z" fill="black"/></svg>') !important;
    background-repeat: no-repeat;
    background-size: 19px;
    background-position-x: 90%;
    background-position-y: center;
    position: relative;
}
.account-right .list-table .name p{
    font-size: 14px;
}
.account-right .list-table .name h6{
    font-size: 14px;
}
.account-right .listbody  .login-check label .control__indicator {
    top: 0;
    bottom: 0;
    margin: auto;
}
.account-right .top-bar input{
    width: 350px;
}
.account-right .top-bar select{
    width: max-content;
    padding: 0 10px;
}
.account-right .itemlist{
    padding: 1rem;
}
.account-right .itemlist .top-bar .sort{
    display: flex;
    align-items: center;
}
.account-right .itemlist .top-bar .sort span{
    margin-right: 10px;
    font-weight: 600;
}
.account-right .itemlist .listbody tr:first-child td{
    /*padding-top: 0;*/
}
.account-right .itemlist .listbody tr:first-child .remove{
    width: 0px;
}
.account-right .itemlist .listbody .select-div{
    display: flex;
    padding: 0 0 15px;
    margin-bottom: 0;
    border-bottom: 1px solid #e2e2e2;
}
.account-right .itemlist .listbody .select-div .check{
    width: 20px;
    height: 20px;
    margin-top: 8px;
}
.account-right .itemlist .listbody .btn{
    padding: 0 20px;
    margin-left: 20px;
}
.main-section .account-details {
    margin-bottom: 1rem;
}
.account-details:last-child {
    margin-bottom: 0;
}
.main-section .account-details h6 {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 4px;
}
.main-section .account-details ul li .nav-link {
    padding: 0.3rem 0rem;
    font-size: 14px;
    color: #696969;
}
.main-section .account-details ul li .nav-link.active {
    color: black;
    font-weight: 600;
    background: #f7f7f7;
}

.main-section .form-row.form-info .info-control {
    border-color: #c4c4c4;
    height: 44px;
    font-size: 13px;
}
.main-section .form-row.form-info label {
    color: #281713;
    font-size: 13px;
}
.label {
    color: #281713;
    font-size: 13px;
}
.update-btn .btn{
    width: max-content;
    padding: 0 60px;
    border-radius: 100px;
}
h6.account-title.info {
    margin-top: 8px;
}
/* The switch - the box around the slider */
.toggle-info .list-details:last-child{
    padding-bottom: 0;
}
.toggle-switch {
    display: flex;
    align-items: center;
}
.toggle-switch input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
}

.toggle-switch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 54px;
    height: 30px;
    margin-bottom: 0;
    background: lightgray;
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle-switch label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.toggle-switch input:checked + label {
    background: #fec601;
}

.toggle-switch input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}


.list-details {
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem 0;
}
.list-details:first-child {
    padding-top: 0;
}
.list-details:last-child {
    border-bottom: 0;
}
.form-group.mb-2{
    margin-bottom: 12px !important;
}
.form-group.mb-3{
    margin-bottom: 20px !important;
}
.form-group.npassword {
    position: relative;
}
.form-group.npassword i {
    position: absolute;
    top: 44px;
    right: 12px;
    color: #c4c4c4;
}
.address-details {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 2rem;
}
.address-name span{
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
}
.address-card{
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.address-card h5{
    font-size: 16px;
    font-weight: 600;
}
.address-card p{
    font-size: 14px;
}
.address-card .edit-info .btn:hover a{
    color: #d30000;
}
.card-slc{
    padding: 10px;
}
.card-slc .main-section{
    border-color: #e2e2e2;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}
.card-slc .main-section .badge{
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
    border-radius: 50px;
    padding: 5px 7px;
    position: absolute;
    opacity: 0;
    transition: 0.3s all ease-in-out;
}
.ebt-card .card-slc{
    margin-top: 0 !important;
}
.ebt-card .card-slc .main-section{
    border: 0 !important;
    padding: 0 !important;
}
.ebt-card .card-slc .main-section .badge{
    display: none !important;
}
.ebt-card .card-slc .main-section .badge{
    top: 10px;
    right: 10px;
}
.card-slc.active .main-section .badge{
    opacity: 1;
}
.card-slc.active .main-section{
    border-color: #28a745;
}

.card-slc .btn-border{

}
p.country-add {
    margin-bottom: 0.4rem;
    font-size: 11px;
    color: #281713;
}
p.country-contact {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 11px;
}
h6.user-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
}
.add-edit {
    text-align: right;
}
.add-edit a {
    color: #dc2a27;
    font-size: 12px;
    font-weight: 700;
}
p.crd-title {
    font-size: 10px;
    color: #281713;
    margin-bottom: 5px;
}

.card-number span {
    color: #281713;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 600;
    margin-right: 10px;
}
.card-number {
    margin-top: 14px;
    margin-bottom: 14px;
}
.account-right .title h2.text-sm{
    font-size: 19px;
}
.account-right h6{
    font-size: 15px;
    font-weight: 400 !important;
}
.visa-card i {
    cursor: pointer;
}
.payment-col1 {
    padding-right: 8px;
}
.payment-col {
    padding-left: 8px;
}

/*====================/personal information===========================*/

/*====================order successfully popup===========================*/
.order-successful {
    background: white;
    border-radius: 5px;
    text-align: center;
    width: 450px;
    padding: 2.5rem 5rem;
    margin-top: 6rem;
}
.orders-title {
    font-weight: bold;
    margin-bottom: 5px;
}
.orders-id {
    font-size: 14px;
    color: #281713;
    font-weight: 700;
    margin-bottom: 0;
}
.orders-subtitle {
    color: #696969;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1.8rem;
}
.tick-icon {
    height: 85px;
    width: 85px;
    margin: auto;
    border: 3px solid #00cc14;
    border-radius: 50%;
    line-height: 85px;
    margin-bottom: 1rem;
    cursor: pointer;
}
.tick-icon i {
    font-size: 32px;
    color: #00cc14;
}
.successHead .check-img{
    width: 50px;
    image-rendering: crisp-edges;
    image-rendering: -webkit-crisp-edges;
    /*background-color: #44c4a1;*/
    /*padding: 10px;*/
    /*border-radius: 100px;*/
}
.btn-sec-suc{
    padding: 1rem 0.5rem;
}
.btn-sec-suc .btn{
    margin: 0 7px;
    height: 40px;
    padding: 0 20px;
    min-width: max-content;
}
.suc-stick{
    position: sticky;
    top: 20px;
    padding: 0;
}

/*====================/order successfully popup===========================*/
.main-section .order-table .table td,
.table th {
    padding: 0.95rem 0;
    vertical-align: middle;
    border-top: 1px solid #e5e5e5;
}
.order-table td {
    font-size: 14px;
}
.order-table .table thead th {
    border-bottom: 1px solid #e5e5e5;
    border-top: 0;
    padding-top: 0;
    font-weight: 700;
    font-size: 15px;
}

.main-section .delivery-details {
    padding-left: 12px;
}
.main-section .delivery-details p {
    font-size: 14px;
    margin-bottom: 0;
}
.main-section a.btn.btn-view {
    font-size: 14px;
    background: #e5e5e5;
    padding: 0.3rem 1rem;
}
.main-section .page-details p {
    font-size: 12px;
    color: #696969;
    margin-bottom: 0;
}
.main-section .chevron-icon i {
    width: 28px;
    height: 28px;
    background: #f2f2f2;
    text-align: center;
    /*line-height: -4px;*/
    border-radius: 5px;
    font-size: 14px;
    padding-top: 7px;
    color: #c4c4c4;
    margin-right: 0.5rem;
    cursor: pointer;
}
.main-section .chevron-icon i.active {
    background-color: red;
    color: white;
}

/*=================order details page=======================*/
.order-breadcrumb .breadcrumb {
    padding: 8px 0;
    background-color: transparent;
}
.order-breadcrumb .breadcrumb a {
    background-color: transparent;
    font-weight: 700;
    font-size: 20px;
    color: #dc2a27;
}
.order-breadcrumb .breadcrumb a:hover {
    color: #dc2a27;
    text-decoration: none;
}
.order-breadcrumb .breadcrumb-item.active {
    color: #281713;
    font-weight: 700;
    font-size: 20px;
}
.order-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #281713;
}

.main-section h6.order-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
}
.main-section .order-address-details {
    margin-left: 8px;
}
.main-section .order-address-details p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}
.main-section .order-date p {
    margin-bottom: 0;
    color: #281713;
    font-size: 14px;
}
.main-section .order-status {
    text-align: center;
}
.main-section .order-status h6 {
    font-size: 14px;
    font-weight: 700;
    color: #281713;
}
.main-section .order-status h6.ostatus-deliver {
    color: #00a560;
    margin-bottom: 5px;
}
.grid-divider {
    position: relative;
    padding: 0;
}
.grid-divider > [class*='col-'] {
    position: static;
}
.grid-divider > [class*='col-']:nth-child(n + 2):before {
    content: '';
    border-left: 1px solid #c4c4c4;
    position: absolute;
    top: -16px;
    bottom: -17px;
    margin-left: -19px;
}
.main-section .order-notes p {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 1rem;
}
.main-section .order-notes p span {
    font-weight: 700;
}
.main-section .ostatus-col {
    margin: auto;
}

.main-section .order-card {
    margin-top: 1rem;
}
.order-card-main button.btn.btn-proceed{
    width: max-content;
}
/*=============checkbox==============*/
/* The check */
.check {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    transition: 0.15s all ease-in-out;
    width: 15px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
    background-color: #dc2a27;
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
    display: block;
}

a.btn.btn-reorder {
    background: #dc2a27;
    color: white;
    font-size: 13px;
    font-weight: 700;
    height: 30px;
    width: 114px;
    padding: 4px;
}
.custom-check {
    margin: auto 0;
}

.odfood-table .table td,
.table th {
    padding: 0;
    vertical-align: baseline;
    padding-bottom: 0.75rem;
}

th.col-three {
    width: 31%;
}
th.col-two {
    width: 60%;
}

.main-section .order-food .shop-add p {
    font-size: 14px;
    width: 75%;
    margin-bottom: 0;
}
.food-check .check {
    padding-left: 30px;
}
.food-check .checkmark {
    top: 14px;
}

.main-section .odfood-qty {
    font-size: 14px;
    font-weight: 700;
}
.table {
    margin-bottom: 0;
}

.odfood-table h6 {
    color: #281713;
    font-weight: 700;
}

/*============================css for merge==============================*/

/*===============checkout=======================*/
.food-details .edit-info {
    margin-top: 1.9rem;
}
.payment-method .edit-content {
    margin: auto 0;
}
.payment-method .edit-content a {
    font-size: 14px;
    color: #00506a;
    font-weight: 700;
    text-decoration: none;
}
.payment-method button.btn.btn-select {
    background: #007297;
    color: white;
    height: 32px;
    font-size: 14px;
    border-radius: 20px;
    width: 84px;
    font-weight: 700;
    padding: 1px;
}
.food-details.review-details {
    margin-bottom: 8px;
}
.cart-table tr.border-details td:first-child{
    width: 500px;
}
.cart-table tr.border-details td.width-name{
    width: 460px;
}
.cart-table tr.border-details td.icon-width{
    width: 24px;
}
td.product-details.cart-width.cart-alignment.review-align {
    vertical-align: baseline;
    padding-right: 0;
}
.cart-table .quantity.qty-cart .skin-5 .num-in{
    border: 0;
    padding-left: 10px;
}
.cart-table .quantity.qty-cart .skin-5 .num-in p{
    font-size: 14px;
    font-weight: 600;

}
td.product-details.cart-width.cart-alignment.review-align .pro-qty {
    position: relative;
    height: 34px;
}
.pro-qty {
    border: 1px solid #d1d1d1;
    text-align: center;
    height: 36px;
    border-radius: 20px;
    padding: 4px 0;
}
.pro-qty span {
    font-size: 14px;
    font-weight: 700;
}
.food-details .feature-details.review-details {
    margin-top: 1rem;
}

.edit-cart {
    float: right;
}
.store-loc .title{
    margin-bottom: 5px;
}
.store-loc .contact-details i{
    top: 0;
}
.store-loc h5{
    font-weight: 700;
    color: #212121;
    font-size: 18px;
}
.store-loc .contact-details h6{
    font-size: 14px;
}
.store-loc .contact-details p{
    font-size: 16px;
}
.edit-tag{
    display: inline-block;
    color: #0066c0;
    font-weight: 700;
    font-size: 13px;
    text-decoration: none;
    margin: 0px 0px 0px 10px;
    position: relative;
    top: 1px;
}
/*===============/checkout=======================*/

.cart-heading {
    padding: 10px 0px;
    /*border-bottom: 1px solid #e8e8e8;*/
    margin-bottom: 04px;
}
.cart-heading h4 {
    font-size: 20px;
    font-weight: 700;
}

.main-section.checkout-info .food-details.main-title {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
}
.main-section.checkout-info .food-details.main-title h5 {
    font-size: 18px;
}
.main-section.checkout-info .form-details {
    padding: 0px;
}
.main-section.checkout-info .form-group .fields{
    margin-bottom: 10px;
}
.main-section.checkout-info .form-group .form-control{
    margin-bottom: 5px !important;
}
.main-section.checkout-info .form-group .form-control.mb-0{
    margin-bottom: 0 !important;
}
.main-section.checkout-info .title{
    padding: 0;
}
.main-section .sub-total .item-text p {
    font-weight: 700;
}
.main-section .sub-total.amt-total .text-total p {
    font-weight: 700;
    font-size: 16px;
}

.main-section .sub-total .item-text.text-price p {
    font-size: 16px;
}

.main-section a.btn.btn-cart:hover {
    background: #b31f1d;
}

.review-right {
    margin: auto;
}

.main-section .review-img {
    width: 64px;
    height: 64px;
    margin-right: 16px;
}
.main-section .review-img img {
    width: 100%;
}

.main-section .order-info .order-date p {
    font-weight: 700;
}

.food-details .card-holder .crd-holder-name {
    font-weight: 700;
}

.odfood-table td.odfood-qty.qty-color {
    color: #dc2a27;
}

a.btn.btn-reorder:hover {
    background: #b31f1d;
}

.main-section.main-cart .form-control.input-control {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px;
}

.home-slider .food-details h5 {
    font-size: 16px;
}
.home-slider .food-details .feature-details {
    justify-content: inherit;
    margin: 3rem 0 0rem 1rem;
}
.home-slider .feature-details a {
    margin-left: 16px;
    vertical-align: super;
    color: #009fce;
    font-weight: 700;
    font-size: 14px;
}
.home-slider .feature-details a:hover {
    color: #0078a0;
}

.gradient {
    /* background: rgb(16,123,221); */
    background: linear-gradient(
            180deg,
            rgba(16, 123, 221, 1) 0%,
            rgba(1, 89, 169, 1) 55%
    );
    width: 15.56px;
    height: 15.56px;
    border-radius: 2px;
    transform: rotate(45deg);
    margin-left: 3px;
    margin-top: 3px;
    /* background: rgb(1,89,169); */
    /* background: linear-gradient(
180deg
, rgba(1,89,169,1) 0%, rgba(16,123,221,1) 55%); */
}
.gradient h6 {
    color: white;
    font-size: 10px;
    text-align: center;
    /* line-height: 20px; */
    padding-top: 1.5px;
    transform: rotate(315deg);
    padding-right: 1px;
}
/*============================/css for merge==============================*/

.cart-alignment .skin-5 .num-in span {
    font-size: 10px;
    position: absolute;
    top: 12px;
}
.cart-alignment .skin-5 .num-in span.plus {
    right: 5px;
}
.cart-alignment .skin-5 .num-in span.minus {
    left: 0;
}
.cart-alignment .skin-5 .num-in input {
    width: 86px;
}

.custom-dot {
    background: grey;
    width: 100%;
    height: 3px;
    border-width: 0;
}

.custom-dot--active {
    background: black;
}

.breadcrumb-details .breadcrumb {
    padding: 5px 0 10px;
    background-color: transparent;
    border-radius: 27px;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    margin: 10px auto 20px;
}
.breadcrumb-details .breadcrumb li.breadcrumb-item{
    display: flex;
    align-items: center;
}
.breadcrumb-details .breadcrumb h3 span{
    font-size: 80%;
}
.breadcrumb-details h4{
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 5px;
    text-align: center;
}
.breadcrumb-details li.breadcrumb-item a {
    font-size: 14px;
    color: #696969;
    text-decoration: underline;
    transition: 0.3s all ease-in-out;
}
.breadcrumb-details li.breadcrumb-item:last-child a{
    text-decoration: none;
}
.breadcrumb-details li.breadcrumb-item.active a{
    color: #212121;
}
.breadcrumb-details li.breadcrumb-item a:hover{
    color: #2e3a59;
}
.breadcrumb-details .breadcrumb-item + .breadcrumb-item::before {
    font-size: 16px;
    color: #6c757d;
    /* content: "/"; */
    content: '\f105';
    font-family: fontawesome;
}
.breadcrumb-details li.breadcrumb-item a i {
    font-size: 17px;
    vertical-align: text-top;
    color: #5ab7d4;
}
.form-details .fields .form-control {
    height: 40px;
    font-size: 0.875rem;
    /*background-color: #f7f7f7;*/
}
.breadcrumb-details .breadcrumb-item.active {
    color: #212121;
}
.mb-l {
    margin-bottom: 52px;
}
.mb-m {
    margin-bottom: 36px;
}
.mb-sm {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
}
.form-group{
    margin-bottom: 0.875rem
}
.form-details .fields {
    margin-bottom: 12px;
    padding-right: 0px;
}
.form-details .fields label {
    font-size: 13px;
    margin-bottom: 2px;
    color: #281713;
    font-weight: 400;
}
.form-group label {
    font-size: 13px;
    margin-bottom: 2px;
    display: block;
    color: #666;
    font-weight: 400;
}
.form-group .error-text{
    float: left;
    width: 100%;
    color: #ea0000;
}
.input-group{
    position: relative;
}
.input-group .input-group-prepend{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    user-select: none;
    pointer-events: none;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    width: 34px;
    margin: auto;
    height: fit-content;
    padding: 2px 0 !important;
    border-right: 1px solid #ced4da !important;
}
.input-group .form-control{
    padding-left: 45px;
}
/*====================login singup page css===========================*/
.login-column {
    max-width: 39.5%;
    flex: 0 0 39.5%;
}
section.main-height {
    /*min-height: calc(100vh - 155px);*/
    min-height: calc(100vh - 189px);
}
.form-view {
    display: flex;
}
.rotate-icon.top-1{
    position: relative;
    top: -1px;
}
/*.form-view{*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/
.signin-info {
    margin: 1rem 0 1rem ;
}
.signin-info h1{
    margin-bottom: 10px;
    font-size: 1.8rem;
}
.signin-info h5 {
    color: #d30000;
    font-weight: 700;
    margin-bottom: 7px;
}
.signin-info p {
    color: #696969;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}
.kpx_loginOr {
    position: relative;
    margin: 1rem 0;
}
.kpx_loginOr .kpx_hrOr {
    background-color: #dadada;
}
.kpx_loginOr .kpx_spanOr {
    display: block;
    position: absolute;
    left: 50%;
    top: 5px;
    margin-left: -1.5em;
    background-color: white;
    width: 3em;
    text-align: center;
    font-size: 14px;
}
.main-section form .forget-details {
    /* margin-bottom: 1.2rem; */
    /*margin: 1.2rem 0;*/
}
/*.main-section form .forget-details a {*/
/*    font-size: 14px;*/
/*    color: #00506a;*/
/*    margin-left: 0px;*/
/*    font-weight: 600;*/
/*}*/
.main-section form .checkmark {
    top: 1px;
    height: 20px;
    width: 20px;
    border: 0;
    background: #ededed;
}

.main-section form .custom-check.login-check {
    /* margin: 1rem 0 1.5rem; */
    /*margin-bottom: 1.6rem;*/
}
.login-check label{
    padding-left: 23px;
}
.login-check label .control__indicator{
    left: 0;
    right: unset;
}
.main-section form .login-check label {
    font-size: 12px;
    color: #212121;
}
.main-section form .login-check label .control__indicator{
    top: 1px;
}

.main-section form .login-check label a {
    color: #56aac5;
}
.main-section form .login-check label a:hover {
    color: #2a87d0;
}

.main-section form .custom-check.login-check.check-default label{
    padding: 0 30px;
    text-align: center;
    pointer-events: none;
}
.main-section form .custom-check.login-check.check-default label a{
    display: inline-block;
    z-index: 2;
    pointer-events: all;
}
.main-section form .custom-check.login-check.check-default .control__indicator{
    display: none !important;
}
.main-section form .create-account p {
    font-size: 14px;
    color: #212121;
    margin-top: 1.2rem;
}

.main-section form .login-check label {
    font-size: 12px;
    color: #281713;
}
.main-section form .custom-check.login-check.check-default{
    font-weight: 400;
    cursor: default;
}
/*.main-section form .create-account p {*/
/*    color: #281713;*/
/*    margin-top: 1.2rem;*/
/*}*/
.pb-15{
    padding-bottom: 0.13rem !important;
}
.create-account p a {
    font-size: 15px;
}
.main-section form .google-icon img {
    width: 16px;
}
.main-section form .google-button {
    background: #4688f1;
    width: 210px;
    height: 40px;
    margin: auto;
    border-radius: 25px;
    cursor: pointer;
}
.main-section form small {
    font-size: 12px;
    color: #535353;
}
.value-slc{
    text-align: center;
    color: white;
    font-weight: 500;
    position: absolute;
    z-index: 1;
    font-size: 14px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 32px);
    background: #d30000;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: auto;
}
.value-slc:after {
    content: '';
    position: absolute;
    background: #d30000;
    display: block;
    border-left: 2px solid white;
    border-top: 2px solid white;
    width: 8px;
    height: 8px;
    float: right;
    transform: rotateZ(-135deg);
    z-index: 5;
    right: 15px;
    top: 13px;
}
.main-section form .google-button .google-icon {
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 20px;
    line-height: 30px;
    margin: auto 4px;
}
.main-section form .google-sign h6.login-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1rem;
}
.main-section form .google-button .google-content {
    margin: auto auto auto 5px;
}
.main-section form .google-button .google-content h6 {
    color: white;
    font-size: 14px;
    font-weight: 700;
}
.main-section form .custom-check.login-check.check-default label{
    padding: 0 15px;
    text-align: center;
    pointer-events: none;
}
.main-section form .custom-check.login-check.check-default label a{
    display: inline-block;
    z-index: 2;
    pointer-events: all;
}
.main-section form .custom-check.login-check.check-default .control__indicator{
    display: none !important;
}
.main-section form .custom-check.login-check.check-default{
    font-weight: 400;
    cursor: default;
}
/*.main-section form .create-account p {*/
/*    color: #212121;*/
/*    margin-top: 1.2rem;*/
/*}*/
.pb-15{
    padding-bottom: 0.13rem !important;
}
.create-account p a {
    font-size: 15px;
}
.fooVeK{
    padding: 10px;
}

.cardErrorClr{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
    text-align: left;
    background-color: rgb(251, 229, 228);
}
.cardInfoClr{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: start;
    padding: 12px;
    margin-bottom: 5px;
    border-radius: 6px;
    border: 1px solid rgb(122 184 203 / 40%);
    width: 100%;
    text-align: left;
    background-color: rgba(86, 170, 197, 0.08);
}
.cardInfoClr.warning{
    border: 1px solid rgba(203, 161, 113, 0.4);
    width: 100%;
    text-align: left;
    background-color: rgba(201, 157, 74, 0.08);
}
.cardInfoClr svg{
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    margin: 0 8px 0 0 !important;
    display: block;
}
.cardErrorClr svg{
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    margin: 0 10px 0 0 !important;
    display: block;
}
.cardSuccessClr svg{
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    margin: 0 10px 0 0 !important;
    display: block;
}
.light-box-border{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 12px;
    margin-bottom: 5px;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    width: 100%;
    text-align: left;
    background-color: rgba(226, 226, 226, 0.11);
}
.title .login-check label{
    font-size: 14px;
    font-weight: 400;
}
.title .control--checkbox .control__indicator:after{
    left: 6.5px;
    top: 3.5px;
}
.light-box-border .control--checkbox .control__indicator:after{
    left: 6.5px;
    top: 3.5px;
}
.light-box-border .login-check label{
    font-size: 13px;
    color: #696969;
    line-height: 1.6;
    font-weight: 500;
}
.cardInfoClr .bbiirI{
    margin-right: 5px;
    width: 18px;
    height: 18px;
}
.cardInfoClr span{
    font-size: 13px;
    color: #696969;
    font-weight: 500;
    line-height: 1.2;
}
.sq-card-wrapper .sq-card-iframe-container{
    margin-bottom: 20px;
}
.pay-custom .sq-card-message{
    position: absolute;
    bottom: -15px;
    margin: 0;
    left: 15px;
}
iframe {
    border: 0;
    height: 46px;
    width: 100%;
}
iframe input{
    width: 91%;
}
.bbiirI{
    width: 16px;
    height: 16px;
}
.instruction-fields{
    position: relative;
}
.instruction-fields .light-box-border .custom-check .control__indicator{
    display: none;
}
.instruction-fields .light-box-border .custom-check label{
    padding: 12px;
}
.instruction-main .light-box-border .custom-check label{
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    top: 1px;
}
.instruction-main .light-box-border .custom-check label.top-0{
    top: 0px;
}
.instruction-main .light-box-border .custom-check label.top-0 svg{
    top: 0.5px;
}
.instruction-fields .light-box-border{
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0;
    font-weight: 600 ;
    background: #fcfcfc;
    left: 0;
}
.instruction-fields .light-box-border label{
    font-weight: 600 !important;
}
.instruction-fields .fields-div textarea{
    height: 47px;
}
.instruction-fields .fields-div{
    position: relative;
    z-index: -2;
    opacity: 0;
}
.instruction-fields .btn-div{
    display: none;
}
.show.instruction-fields .fields-div{
    position: relative;
    z-index: 1;
    opacity: 1;
}
.active.instruction-fields .btn-div {
    display: block;
}
/*====================/login singup page css===========================*/

/*====================/account latest css===========================*/
.main-section .user-personal-details {
    margin-bottom: 1.5rem;
}

.main-section .user-personal-details h6 {
    font-size: 13px;
    margin-bottom: 5px;
    color: #281713;
    font-weight: 400;
}
.main-section .user-personal-details p{
    font-weight: 600;
    color: #281713;
}
.edit-button{
    background-color: #56aac5;
    width: max-content;
    color: white;
    padding: 10px 20px;
    height: auto;
}
.btn.btn-border{
    background-color: #e8e8e8;
    width: max-content;
    padding: 10px 20px;
    height: auto;
}
.btn.btn-border a{
    color: #b9b9b9;
}
.btn.btn-border:hover{
    background: #56aac5;
    color: white;
}
.btn.btn-border:hover a{
    color: white;
}
.btn-lists{
    border: 1px solid #cecece !important;
    color: #56aac5 !important;
    width: auto;
    padding: 5px 10px;
    font-size: 12px;
}
.btn.btn-border.default-button{
    background-color: #56aac5;
    padding: 5px 20px;
    user-select: none;
    cursor: default;
}

.btn.btn-border.default-button a{
    color: white;
    font-weight: 600;
    user-select: none;
    cursor: default;
}
.btn-xs.edit-button{
    padding: 5px 15px 7px;
}
.edit-button a{
    color: white;
    padding: 0;
}
.edit-button.blank{
    background: transparent;
    margin-right: 30px;
    padding: 0;
}
.edit-button.blank a{
    color: #212121;
    padding: .5rem 0;
}
.edit-button.blank:hover a{
    color: black;
}
.payment-method .edit-button button.btn.btn-select {
    width: 126px;
}
.edit-button.default{
    box-shadow: inset 0 0 2px #000 !important;
    background: transparent;
    margin-left: 15px;

}
.edit-button.default a{
    color: black;
}
.main-section .address-info h6 {
    color: #212121;
    margin-bottom: 4px;
    font-weight: 600;
}
.main-section .address-info p.address2 {
    font-size: 14px;
    color: #696969;
    margin-bottom: 4px;
}
.address-btn button.btn.btn-address {
    background: #5ab7d4;
    color: white;
    font-size: 14px;
    font-weight: 700;
    width: max-content;
    border-radius: 20px;
    height: 40px;
    padding: 0 35px;
    transition: 0.5s all;
}
.address-btn button.btn.btn-address:hover {
    background: #258cac;
}
.payment-method.payment.pay-card label {
    border: 0;
    width: 100%;
    padding: 1rem;
}

.payment-method.payment.pay-card {
    background-image: url(../images/payment.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.main-section .pickup-order {
    padding: 1rem;
}
.main-section .pickup-order h6 {
    font-size: 16px;
    color: #212121;
    font-weight: 700;
}
.main-section .pickup-order p {
    font-size: 14px;
    color: #696969;
    margin: 5px 0;
}
.main-section .pickup-order h6.pickup-amt {
    font-size: 14px;
    color: black;
}
.main-section .pickup-order h6.pickup-amt span{
    margin-right: 15px;
}
.main-section .pickup-order h6.pickup-amt span b{
    font-weight: 400;
}
.main-section .pickup-card {
    margin: auto 0;
    text-align: right;
}
.main-section .pickup-order .pickup-way h6 {
    color: #000;
    font-size: 13px;
    font-weight: 600 !important;
}
.main-section .pickup-way.food-details {
    margin: 0.6rem 0 0;
}
.catering-main .product-details .food-content .btn{
    margin: 0;
}
.catering-main .display-details .food-column{
    width: 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.filter-design {
    border-radius: 25px;
    padding: 0.5rem;
}
.card-color{
    background-color: white !important;
    border-color: #008CBA;
}
.single-product .card-color{
    background-color: white !important;
    border-color: #d30000;
}
.filter-design .page-link {
    background: #f3f3f3;
    padding: 0;
    color: #696969;
    /* background-color: transparent; */
    border: 0;
    font-weight: 600;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    font-size: 14px;
}
.react-slider__btnNext{
    display: none;
}
.react-slider__btnPrev{
    display: none;
}
.filter-design .page-item.active .page-link {
    z-index: 3;
    color: black;
    background-color: #eec02a;
    border-color: #eec02a;
    border-radius: 20px;
}

.w-16{
    width: 22px;
    color: green;
}
.w-16 path{
    fill: green;
}
.text-message{
    font-size: 14px;
}
.text-message h6{
    font-weight: 600;
    margin-bottom: 3px;
}
.text-message p{
    margin-bottom: 0;
}
li.page-item.page-item-navigation {
    color: #696969;
    margin: auto 0;
}
.emptyCart{
    width: 100%;
    position: relative;
    height: 150px;
}
.blank-banner{
    width: 100%;
    text-align: center;
}
.blank-banner img{
    width: 100px;
    text-align: center;
}
.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999
}
.loader-tip{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background: rgba(255,255,255,0.5);
    align-items: center;
    z-index: 9;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.loader-tip .loader{
   position: relative;
    height: 60px;
    width: 60px;
}
.btn.disabled{
    user-select: none;
    pointer-events: none;
}



.btn-float{
    background: #fec601;
    border-radius: 100px;
    width: 44px;
    height: 44px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    display: flex;
    z-index: 8;
    justify-content: center;
    align-items: center;
}
.btn-filter{
    display: none;
}
.pagination{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0;
}
.pagination .paginationBttns {
    height: auto;
    justify-content: center;
    margin: 10px 0 0;
    width: 100%;
    background: #fff;
    display: flex;
    padding: 5px 10px;
    border-radius: 10px;
}
.pagination .paginationBttns a{
    display: inline-flex;
    width: auto;
    min-width: 40px;
    cursor: pointer;
    height: 40px;
    padding: 0px;
    margin: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: white;
    color: black;
    /*box-shadow: 0px 0px 10px rgb(0 0 0 / 9%);*/
    transition: 0.2s all ease-in-out;
    border: 1px solid #e2e2e2;
}
.pagination .paginationBttns a:hover{
    background: #56aac5;
    color: white;
    border-color: #56aac5;
}
.pagination .paginationBttns .paginationActive a{
    background: #56aac5;
    color: white;
    border-color: #56aac5;
}
.pagination .paginationBttns .paginationDisabled a{
    color: #696969;
    background: #f1f1f1;
    border: 0;
}
.details-card h6{
    font-weight: 400 !important;
}
.details-card h6{
    font-size: 14px;
}
.details-card small{
    font-size: 12px;
    margin-bottom: 2px !important;
}
.details-card p.text-dark{
    margin-bottom: 2px !important;
    font-size: 15px;
}
.details-card button.btn-proceed{
    font-size: 14px;
    height: 36px;
    margin-top: 0;
}
.details-card .text-warning {
    color: #dea902 !important;
}
/*====================/account latest css===========================*/

@media screen and (min-width: 1000px) and (max-width: 1199px) {
    .display-details .food-column{
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
    .account-card{
        padding-left: 0;
    }
    .full-grid .display-details .food-column{
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%;
    }
    .container{
        max-width: calc(100% - 30px);
    }
    .cart-selection .payment-method span{
        font-size: 10px;
    }
    .cart-selection .pickup-date label{
        height: 70px;
        max-height: 70px;
    }
    /*.value-slc{*/
    /*    width: 100% !important;*/
    /*}*/
    .cart-selection .pickup-time{
        width: calc(100% / 3 - 9px);
    }
}
.badge-pill{
    padding: .15em .4em 5px;
}
@media screen
and (min-device-width: 1600px)
and (max-device-width: 5000px){
    .modal-dialog {
        /*width: 600px;*/
        transform: scale(1.1)!important;
        /*top: 180px;*/
    }
}
/**/
.location .address{
    padding: 0 0 0 20px;
    border-left: 1px solid #e2e2e2;
}
.location .address .footer-content .contact-details h6{
    color: #696969;
    font-size: 15px;
    font-weight: 600;
}
.location .address .footer-content .contact-details p{
    color: #696969;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
}
.location .address .footer-content .contact-details i{
    top: 0;
}
.location .address .footer-content .contact-details .icons{
    width: 40px;
}
.location .address a{
    color: #008CBA;
    font-size: 14px;
    font-weight: 500
}
.map{
    height: 350px;
    overflow: hidden;
    margin-bottom: 0px;
}
.map img{
    width: 100%;
}
.card-ques {
}
.category-faq{
    border: 0;
    width: 100%;
    margin-left: 0;
    padding: 5px 15px 0;
}
.category-faq ul li{
    padding: 0;
    margin-left: 0;
    margin-bottom: 15px;
    border-radius: 15px;
    overflow: hidden;
}
/*.category-faq ul li:last-child{*/
/*    border-bottom: 0;*/
/*}*/
.category-faq ul li:last-child a{
    padding: 10px 0 5px;
}
.category-faq ul li a{
    padding: 15px 0;
    color: #414141;
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
}
.category-faq ul li a.more-info{
    display: inline-block;
    color: #56aac5;
    font-weight: 500;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    float: none;
}
.category-faq ul li a:hover{
    color: #000;
}
.accordion{
    border: 0;
}
.font-weight-medium{
    font-weight: 600 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.text-black{
    color: #212121 !important;
}
.accordion__button{
    background-color: white;
    cursor: pointer;
    padding: 15px;
    color: #696969;
    position: relative;
}
.accordion__button:hover{
    background-color: white;
    color: black;
}
.accordion__button:before{
    transition: 0.35s all ease-in-out;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.accordion__button[aria-expanded='true'], .accordion__button[aria-selected='true']{
    font-weight: 600;
    color: black;
}
.accordion__button::before {
    transform: rotate(45deg);
    transform-origin: center;
}
.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
    transform: rotate(225deg);
}
.accordion__panel{
    border-top: 1px solid #eee;
    padding: 15px;
}
.accordion__panel p{
    font-size: 14px;
    color: #696969;
    font-weight: 400;
}
.policy .text{
    border-bottom: 1px solid #e2e2e2;
    padding: 15px 10px;
}
.policy .text:last-child{
    border-bottom: 0;
    padding: 20px 10px 10px
}
.policy h4{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px
}
.policy h6{
    line-height: 1.4;
    color: #696969;
    font-weight: 400;
}
.policy h2{
    font-size: 1.25rem;
    margin-top: 10px;
    margin-bottom: 10px;
}
.policy ul{
    list-style: initial;
    margin-bottom: 20px;
    padding-left: 20px;
}
.policy ul li{
    margin: 10px 0;
    font-size: 14px;
}
.policy ul li span{
    font-size: 14px;
    font-weight: 500;
    color: #696969;
}
.policy ul li strong{
    font-weight: 600;
    color: black;
}
.policy a.text-dark{
    font-weight: 600;
}
.policy .contact span{
    margin: 3px 0;
}
.policy .contact a{
    margin: 3px 0;
}
.policy p{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
}
.about-banner{
    position: relative;
    top: -1rem
}
.about-banner .image-wrap {
    position: relative;
    width: 100%;
    height: 180px;
    overflow: hidden;
    background: #d30000;
}

.about-banner .banner-content {
    position: absolute;
    z-index: 99999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    line-height: 1.5;
    height: max-content;
}
.about-banner .banner-content h1{
    font-size: 42px;
    font-weight: 700;
    text-transform: capitalize;
    color: white;
}
.about-banner .banner-content h5{
    color: white;
}
.about-banner .img-content img {
    width: 100%;
    height: auto;
    display: none;
}

.about-banner .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.30);
    opacity: .8;
    z-index: 999;
    height: 100%;
}
.img-rounded{
    border-radius: 15px;
}
.about-banner .overlay:after{
    display: none;
}
.about-content h6{
    color: #696969;
    margin-bottom: 15px;
    line-height: 1.4;
}
.about-content h6:last-child{
    margin-bottom: 0;
}
.order-total ul{
    border: 1px solid #e2e2e2;
    padding: 15px;
}
.order-total ul li{
    margin: 5px 7px;
    font-size: 14px;
}
.order-total ul li span{
    float: right;
    text-align: right;
    width : 80px;
}
/*.order-total ul li.font-weight-bold span{*/
/*    margin-right: 7px;*/
/*}*/
.order-total ul li.font-weight-bold{
    font-size: 16px;
    font-weight: 600 !important;
}

.infinite-scroll-component{
    overflow-y: visible !important;
    overflow-x: hidden !important;
}
.site-layout{
    min-height: calc(100vh - 119px);
    position: relative;
    z-index: 2;
}
.mb-fix{
    margin-bottom: 65px;
    /*margin-bottom: 100px;*/
}
/*.checkout-view{*/
/*    padding-bottom: 80px;*/
/*}*/
.list-items-main{
    padding-bottom: 70px;
}

.i-form h2{
    font-size: 18px;
}
.i-form iframe #widget{
    padding-bottom: 0 !important;
}
/*.i-form iframe #widget ul.errors{*/
/*    position: absolute;*/
/*    top: 0;*/
/*}*/
.bg-primary-25{
    border: 0 !important;
    background-color: rgb(0 82 128 / 4%);
    /*background-color: white;*/
}
.bg-primary-25 .text-grey{
    color: #666;
}
.bg-primary-25 .control__indicator{
    top: 2.5px;
    background: white;
    border-radius: 40px;
    border: 2px solid #ced4da;
}
.bg-primary-25 .control input:checked ~ .control__indicator{
    border: 2px solid #5ab7d4;
    background: #5ab7d4;
}
.bg-primary-25 .control:hover .control__indicator{
    background: white;
    border: 2px solid #5ab7d4;
}
.bg-primary-25 .control input:checked ~ .control__indicator:hover{
    border: 2px solid #5ab7d4;
}
.t-70{
    /*color: #808080 !important;*/
    color: #8c8c8c !important;
}
.bg-primary-25 .control--checkbox .control__indicator:after{
    height: 8px;
    width: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    background: white;
    border-radius: 8px;
}
.bg-primary-25 .control__indicator:hover{
    top: 2.5px;
    background: #dadada;
}
.bg-primary-25 .login-check label {
    font-weight: 500;
}
.promotion .main-section.bg-primary-25 h6{
    font-size: 15px;
}

.lh-1{
    line-height: 1.2;
}
.lh-0{
    line-height: 0.8;
}
.i-form iframe{
    width: 100% !important;
    height: 460px;
}
#widget form{
    padding-bottom: 0 !important;
}
.i-form .form-div{
    width: 100% !important;
    padding-top: 20px;
    position: relative;
    border-radius: 10px;
    /*border: 1px dashed #5ab7d4;*/
}
form ul.errors{
    position: absolute;
    top: -314px;
    width: 100%;
}
.h2-big{
    line-height: 35px;
    font-size: 24px;
    text-align: left;
}
.h3-big{
    line-height: 35px;
    font-size: 20px;
    text-align: left;
}
.h6-big{
    font-size: 18px !important;
}
.h2-big:after{
    width: 100%;
}
.promotion .main-section h6{
    font-size: 1rem;
    line-height: 1.2;
}
.promotion .main-section .product-details .food-content h6{
    font-size: 14px;
    height: 33px;
}
.promotion .complete-order .cus-slider{
    padding-left: 0;
}
.promotion .complete-order .recent{
    /*padding-left: 10px;*/
    padding-bottom: 20px;
}
.promotion .complete-order .recent .food-column .product-details{
    border: 1px solid #eeeeee;
}
.promotion .complete-order .recent .food-column{
    height: 150px;
    margin-right: 10px;
}
.ex-badge .badge{
    padding: 4px 8px;
}
.coupon-field{
    position: relative;
}
.coupon-field .form-control{
    padding-right: 75px;
}
.coupon-field h6.apply{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto !important;
    height: 32.5px;
    border-radius: 5px;
    right: 20.5px;
    background: #56aac5;
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 0 10px;
    cursor: pointer;
}
.coupon-field h6.apply:hover{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}
/*.promotion .complete-order .recent .food-column:last-child{*/
/*    margin-right: 0;*/
/*}*/
/*--11/02/2022--*/
.bg-primary-25{
    border: 0 !important;
    background-color: rgb(0 82 128 / 4%);
    /*background-color: white;*/
}
.bg-primary-25 .text-grey{
    color: #666;
}
.bg-primary-25 .control__indicator{
    top: 2.5px;
    background: white;
    border-radius: 40px;
    border: 2px solid #ced4da;
}
.bg-primary-25 .control input:checked ~ .control__indicator{
    border: 2px solid #5ab7d4;
    background: #5ab7d4;
}
.bg-primary-25 .control:hover .control__indicator{
    background: white;
    border: 2px solid #5ab7d4;
}
.bg-primary-25 .control input:checked ~ .control__indicator:hover{
    border: 2px solid #5ab7d4;
}
.t-70{
    /*color: #808080 !important;*/
    color: #8c8c8c !important;
}
@media screen and (min-width: 320px)
and (max-device-width: 880px){
    .bg-primary-25 .control__indicator{
        top: 3.5px;
    }
    .bg-primary-25 .border-left{
        border: 0 !important;
    }
    .i-form:nth-child(2) .form-div{
        padding-top: 40px;
    }
    .i-form.border-right{
        border-right: 0 !important;
        border-bottom: 1px solid #e8e8e8;
    }
    .border-top-sm{
        border-top: 1px solid #e8e8e8;
    }
    .side-cart-width {
        width: 100% !important;
        max-width: 100% !important;
        flex: unset;
    }
    .width-left-side {
        width: 100% !important;
        max-width: 100% !important;
        flex: unset;
    }
    .cardInfoClr{
        padding: 12px !important;
    }
    .column.catering-main{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0 15px;

    }
    .rc-slider {
        padding: 10px 0 5px !important;
        width: calc(100% - 20px) !important;
        left: 10px;
    }
    .filter-div{
        display: block;
    }
    .article-list-view .article-card h6{
        height: auto;
    }
    .article-card h6{
        font-size: 12px;
        margin-top: 0;
        align-items: start;
    }
    /*.sec-article .cus-slider ul{*/
    /*    left: -15px;*/
    /*    width: calc(100% + 30px);*/
    /*    position: relative;*/
    /*}*/
    .menu-col.top-header ul li svg {
        width: 18px;
        position: relative;
        top: -1px;
    }
    .modal-info .modal-dialog{
        width: 100%;
        max-width: 100%;
    }
    .heading-checkout{
        color: #212121 !important;
        font-weight: 600 !important;
        font-size: 1.125rem !important;
        margin-bottom: 5px !important;
        text-transform: capitalize;
    }

    .full-grid .mb-fix{
        margin-bottom: 150px;
    }
    .div-breadcrumb{
        padding: 0;
    }
    .account-right .itemlist .top-bar{
        display: inline-block;
    }
    .title h2.text-sm {
        font-size: 16px;
    }
    .pickup-selection.form-group span{
        font-size:12px;
    }
    select.btn{
        font-size:12px;
    }
    .value-slc{
        width: calc(100% - 30px);
        font-size: 12px;
    }
    .product-details .food-content h6 {
        font-size: 13px;
        line-height: 1.5;
    }
    .single-product #myCarousel{
        padding: 0;
    }
    .single-product #myCarousel img{
        pointer-events: none;
        width: 100%;
    }
    #wrapper.toggled .container1{
        display: none !important;
    }
    button.btn.btn-m.cart-button.menu-toggle {
        font-size: 12px;
    }
    button.btn.btn-proceed {
        width: 195px;
        font-size:14px;
    }
    .drop-col {
        background: #d30000;
        left: 0;
        position: relative;
        width: 100%;
        transition: 0.1s all ease-in-out;
        color: white;
        display: block !important;
        margin-bottom: 13px;
    }
    .drop-col .btn{
        height: max-content;
    }
    /*.product-details{*/
    /*    border: 0;*/
    /*    padding: 0 5px;*/
    /*}*/
    .sale-banner.product-details{
        padding: 0 !important;
    }
    .product-details .buttons{
        /*padding: 0;*/
    }
    .product-details .food-content{
        /*padding: 0 0px;*/
    }
    .product-details .onsale-details span{
        font-size: 10px;
    }
    .react-slider__imgZoom{
        display: none !important;
    }
    .cart-pickup span{
        font-size: 13px;
        width: 100%;
        float: left;
    }
    .cart-pickup span span{
        width: 100%;
        display: initial;
    }
    .cart-pickup span b{
        font-size: 13px;
    }
    .cart-pickup span span{
        font-size: 13px;
    }
    /*.table-cart-main{*/
    /*    padding: 0;*/
    /*    border: 0;*/
    /*    margin-bottom: 0;*/
    /*    display: inherit;*/
    /*}*/
    .react-multi-carousel-item:nth-child(odd){
        padding-right: 7px;
    }
    .react-multi-carousel-item:nth-child(even){
        padding-left: 7px;
        padding-right: 7px;
    }
    .react-multi-carousel-item .product-details{
        padding: 0;
    }
    .cart-table .table-row{
        position: relative;
    }
    .cart-table .table-row td{
        padding-bottom: 32px !important;
        border-bottom: 1px solid #eaeaea !important;
    }
    .cart-table .image{
        width: 50px;
        padding-right: 10px !important;
    }
    .cart-table .image img{
        width: 100%;
        height: 100%;
    }
    .cart-table .contain h6{
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 5px;
    }
    .cart-table .price{
        font-size: 14px;
    }
    .cart-table p.price span{
        font-size: 14px;
    }
    .cart-table .name select{
        font-size: 12px;
        marging-right: 5px;
    }
    .cart-table td.name .each-price{
        font-size: 12px;
        margin-top: 2px;
        margin-bottom: 0px;
        float: left;
        display: inline-block;
    }
    .cart-table .price-details{
        width: auto;
    }
    .cart-table .price-details .each-price{
        display: none;
    }
    .footer .item-col{
        margin-top: 15px;
    }
    .footer .item-col:first-child{
        margin-top: 0;
    }
    .foot-cart p{
        border-bottom: 1px solid #e2e2e2;
        margin-bottom: 15px !important;
        padding-bottom: 10px !important;
    }
    .foot-cart .proceed-button h2{
        font-size: 0.85rem;
        text-align: left;
    }
    .foot-cart .proceed-button h2 b{
        width: 100%;
        font-size: 1rem;
        display: block;
    }
    .foot-cart .proceed-button .btn-proceed{
        width: auto;
        padding: 0 15px !important;
        font-size: 13px;
        height: 40px;
    }
    .subscribe-info{
        padding: 1.5rem 0;
    }
    .subscribe-details{
        text-align: center;
    }
    .subscribe-details h4{
        font-size: 1.2rem;
        margin-bottom: 2px;
    }
    .subscribe-details p{
        font-size: 12px;
    }
    .subscribe-details .action{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    .subscribe-details .form-group {
        width: 100%;
        padding: 0;
    }
    .subscribe-details .form-group .form-control{
        text-align: center;
    }
    .subscribe-details .btn {
        width: 160px;
        margin-top: 15px !important;
    }
    .main-section .payment-method.payment .checkout-card img{
        width: 38px;
        height: 38px;
    }
    /*.main-section .payment-method.payment .card-number span{*/
    /*    font-size: 18px;*/
    /*}*/
}
.fw-inherit{
    font-weight: inherit !important;
    color: inherit !important;
}
@media screen and (min-width: 640px) and  (max-width:767px) {
    .display-details .food-column{
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%
    }
}
@media screen and (min-width: 482px) and  (max-width:639px) {
    .display-details .food-column{
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%
    }
}
.z-9{
    z-index: 2;
}
@media screen and (min-width: 320px) and  (max-width:880px) {
    .account-right .top-bar input {
        width: 100%;
    }
    .success-title p{
        margin-bottom: 15px;
    }
    .success-title p b{
        display: block;
    }
    .success-title p.text-dark b{
        display: initial;
    }
    .single-related{
        position: relative;
    }
    .cart-picks{
        position: relative;
    }
    /*.single-related {*/
    /*    padding: 0 15px;*/
    /*}*/
    .title h2.text-sm {
        font-size: 18px;
    }
    .pickup-selection .dropdown img {
        filter: brightness(0) invert(1);
        top: 0;
    }
    .pickup-selection.form-group span{
        font-size:12px;
        top: 0;
        color: white;
        font-weight: 600 !important;
    }
    .pickup-selection .dropdown-toggle::after{
        color: white;
        font-size: 16px;
        margin-top: 0px;
    }
    .pickup-selection.form-group span.pickup{
        font-weight: 400 !important;
        font-size:12px;
        color: white;
    }
    .pickup-selection.form-group .btn:hover span{
        color: white !important;
    }
    .pickup-selection.form-group .btn:hover svg path {
        fill: white !important;
        stroke: white !important;
    }
    .pickup-selection.form-group .btn:after{
        top: 0.5px;
        position: relative;
    }
    .pickup-selection.form-group .btn:hover:after {
        color: white !important;
    }
    .pickup-selection.form-group span.pickup{
        margin: 0 3px;
    }
    .product-details .food-content h6 {
        font-size: 14px;
    }
    button.btn.btn-proceed {
        width: 217px;
    }
    .single-pro-right .btn-blank{
        margin-top: 7px;
        font-size: 14px;
    }

    .single-related .react-multi-carousel-item {
        padding-left: 0;
        padding-right: 15px;
    }
}
@media screen and (min-width: 320px) and  (max-width:370px) {
    .buttons .cart-dropdown .btn{
        font-size: 12px;
    }
    .buttons .added .minus {
        height: 38px;
        width: 28px;
        padding: 10px 9px;
        font-size: 14px;
    }
    .cart-dropdown .dropdown-toggle svg {
        right: 8px;
        top: 12px;
        width: 14px;
        height: 14px;
    }
    .product-details .food-content h6{
        padding: 0 0.75rem 5px;
    }
    .product-details .buttons{
        padding: 0 0.75rem;
    }
}
.head-content .btn-icon{
    display: none;
}
.badge{
    font-weight: 600 !important;
}
.pop-mob{
    display: none;
}
.pop-web{
    display: block;
}
@media screen and (min-width: 768px) and (max-width: 900px){
    .container{
        width: 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 320px) and (max-width: 880px){
    .about-banner .image-wrap{
        height: 220px;
    }
    .deliverable p {
        text-align: center !important;
        font-size: 10px !important;
    }
    .pl-3.border-right{
        border-bottom: 1px solid #e2e2e2 !important;
        border-right: 0 !important;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    b.dl-details{
        margin-bottom: 5px !important;
    }
    .about-banner .image-wrap img{
        height: 220px;
    }
    .cus-slider .nav-btn{
        display: none;
    }
    .food-column {
        width: 185px;
        max-width: 185px;
        margin: 0 15px 10px 0 !important;
        min-width: 185px;
    }
    .art-head{
        font-size: 1.5rem;
    }
    .food-column.article-card {
        width: 100%;
        max-width: 100%;
        margin: 0 0 10px 0 !important;
        min-width: 100%;
    }
    .cus-slider .article-card{
        margin-bottom: 5px;
        width: 180px;
        max-width: 180px;
        margin-right: 0;
        min-width: 180px;
    }
    .cart-picks.complete-order .most-product .product-details{
        padding: 15px 30px 10px !important;
    }
    .cart-picks.complete-order .most-product .product-details a{
        padding: 15px 15px 5px 30px !important;
    }
    .cart-picks.complete-order .most-product .product-details a a{
        padding: 0 !important;
    }
    .cart-picks.complete-order .most-product .product-details a .food-content{
        padding-left: 10px;
    }
    .cart-picks.complete-order .most-product .product-details .buttons{
        padding: 0 15px !important;
        margin-top: 0;
    }
    .cart-picks.complete-order .most-product .product-details .cart-img img{
        width: 100% !important;
    }
    .pop-mob{
        display: block;
    }
    .pop-web{
        display: none;
    }
    .gmnoprint{
        display: none !important;
    }
    .sidebar {
        display: block!important;
        position: fixed;
        left: -280px;
        top: 0;
        width: 280px;
        border-radius: 0;
        background: #fff;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 999999;
        transition: .2s all ease-in-out;
        padding: 1rem;
        padding-bottom: 60px;
    }
    .info-bar .right select {
        font-size: 12px;
        width: 140px;
    }
    .btn-filter{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border: 0;
        right: 10px;
        border-radius: 100px;
        font-size: 12px;
        width: 70px;
        background: #f1f1f1;
        height: 30px;
    }
    .btn-filter svg{
        width: 12px;
        margin-right: 5px;
    }
    .sidebar.show-filter{
        left: 0;
        padding-bottom: 70px;
    }
    .navbar-nav.menu-details.shop-list{
        padding-bottom: 70px;
    }
    .emp-form .title h2 {
        font-size: 18px;
    }
    .header-nav {
        background: transparent;
    }
    header.header1 {
        padding: 5px 0 0;
        position: sticky;
        height: auto;
        top: 0;
        overflow: hidden;
        transition: 0.1s all ease-in-out;
        border-bottom: 2px solid #d30000;
        margin-bottom: 0px;
    }
    .header-nav .navbar {
        position: absolute;
        padding: 0.5rem 0rem;
        top: -117px;
    }
    .modal-dialog .btn.proceed-button{
        height: auto;
    }
    .modal-dialog .btn.proceed-button br{
        display: block;
    }
    .address-card h5{
        font-size: 14px;
    }
    .card-slc{
        margin-bottom: 15px;
    }
    .column {
        padding-left: 15px;
        max-width: 100%;
        flex: 0 0 100%;
    }
    .display-details .food-column {
        width: 50%;
        max-width: 50%;
        min-width: 50%;
        flex: 0 0 50%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .product-details .onsale-details{
        top: 0;
    }
    .logo-col {
        width: 130px;
        margin: auto;
        position: relative;
        top: 0px;
    }

    .footer-content .hours-details {
        padding-left: 0;
    }
    p.price del {
        /*margin-left: 0;*/
        /*display: block;*/
        font-size: 12px;
    }
    .product-details .food-content h6 {
        height: 64px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        margin-bottom: 2px;
        -webkit-box-orient: vertical;
    }
    .product-details .food-content p.price{
        /*margin-bottom: 0;*/
        padding-bottom: 2px;
        font-size: 12px;
    }
    .product-details .food-content p.price span{
        font-size: 12px;
    }
    .catering-main .product-details .food-content h6 {
        height: 40px;
    }
    .menu-col.top-header ul li a b {
        display: none;
    }
    .head-content .btn-icon{
        text-align: center;
        position: absolute;
        display: block;
        top: -35px;
        right: 40px;
        border: 0;
        border-radius: 100px;
        font-size: 12px;
        width: 32px;
        height: 32px;
        padding: 0;
    }
    .badge-light{
        top: -10px;
        font-size: 10px;
        height: 16px;
        min-width: 16px;
    }
    .head-content .btn-icon svg{
        color: black;
        width: 14px;
        height: 14px;
        -webkit-appearance: none;
    }
    .search-bar-col{
        display: flex;
        position: fixed !important;
        padding: 15px;
        background: white;
        z-index: -99;
        opacity: 0;
        top: -120%;
        left: 0;
        width: 100vw;
        margin: 0;
        height: 100vh;
        transition: 0.4s all ease-in-out;
    }
    .head-content{
        padding-bottom: 0;
    }
    .head-content .drop-col{
        display: none !important;
    }
    .hide-bars{
        z-index: 2 !important;
    }
    .search-bar-col:after{
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        height: 100px;
        background: #d30000;
        left: 0;
    }
    .search-bar-col.show-search{
        z-index: 100;
        opacity: 1;
        top: 0;
    }
    .mid-head-details h4{
        position: fixed;
        left: 0;
        top: 0;
        padding: 15px 15px 0;
        width: 100%;
        z-index: 3;
    }
    .search-bar-col .search-bar i {
        width: 38px;
        height: 36px;
        line-height: 39px;
    }
    .search-bar-col .search-bar {
        position: relative;
        max-width: 100%;
        width: 100%;
        z-index: 2;
        padding-top: 32px;
        position: relative;
    }
    .search-bar-col .search-bar input{
        height: 38px;
        font-size: 14px;
    }
    .search-bar svg{
        width: 46px;
        height: 36px;
        padding: 9px 12px 9px 14px;
    }
    .search-bar .form-control {
        padding-right: 85px;
    }
    .search-bar .clear-icon{
        right: 60px;
        display: block !important;
    }
    .search-bar-col .btn.btn-danger {
        width: auto;
        display: block;
        background: transparent;
        color: white;
        text-decoration: underline;
        margin-left: 10px;
        top: 32px;
        height: 36px;
        z-index: 2;
        position: relative;
        padding: 0;
    }
    .search-wish .input-search{
        width: 100%;
    }
    .search-bar-col .search-list {
        width: 100vw;
        max-width: calc(100vw - 30px);
        max-height: calc(100vh - 100px);
        left: 15px;
        top: 110px;
        box-shadow: unset;
    }
    .search-bar-col .search-list li{
        padding: 10px 0;
    }
    .pickup-selection .dropdown .btn {
        font-size: 14px;
        padding: 0;
        height: 42px;
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*.search-bar .form-control{*/
    /*    height: 44px;*/
    /*    width: 100vw;*/
    /*    position: fixed;*/
    /*    background: white;*/
    /*    border: 1px solid;*/
    /*    left: 0;*/
    /*    z-index: -99;*/
    /*    opacity: 0;*/
    /*    border-radius: 0;*/
    /*    top: 0;*/
    /*}*/
    /*.search-list {*/
    /*    border-radius: 0px;*/
    /*    width: 100vw;*/
    /*    max-width: 100vw;*/
    /*    max-height: 300px;*/
    /*    left: -39px;*/
    /*    top: 44px;*/
    /*}*/
    .search-bar .form-control.open-search{
        z-index: 9;
        opacity: 1;
    }
    .mid-head-details {
        display: block;
    }
    .top-header ul li.menu-toggle {
        position: absolute;
        top: -30px;
        right: 5px;
    }
    .cart-selection .selector-pick {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: auto;
        padding-bottom: 15px;
        margin-bottom: 5px !important;
    }
    .cart-selection .pickup-date.payment-method {
        display: inline-block;
        margin-right: 10px;
        margin-top: 5px;
        width: calc(100% / 4);
    }
    .cart-selection .pickup-date label {
        height: 70px;
        max-height: 70px;
    }
    .cart-selection .pickup-date.payment-method {
        margin-right: 10px;
        margin-top: 5px;
    }
    .cart-selection .pickup-time {
        margin-right: 10px;
        margin-top: 5px;
        height: 36px;
        width: 130px;
    }
    .cart-selection .react-datepicker-popper{
        right: 0;
        left: unset !important;
    }
    .cart-selection .react-datepicker__triangle{
        right: 25px;
        left: unset !important;
    }
    .custom-date span {
        top: -2px;
        font-size: 10px;
    }
    .cart-selection .payment-method label{
        font-size: 10px;
        padding: 0.6rem;
    }
    .cart-selection .payment-method :checked + label b{
        font-weight: 500;
    }
    .cart-selection .payment-method :checked + label span{
        font-weight: 500;
    }
    .title {
        padding: 0 0px;
    }
    .sec-article .title{
        padding: 0 15px;
    }
    .cus-slider .food-column.article-card {
        margin-right: 15px !important;
        min-width: 250px;
        width: 250px;
    }
    .review-cart .table-cart-main{
        margin: 0;
        padding-bottom: 0;
    }
    .checkout-details .main-section .review-cart .table-cart-main{
        padding: 0 !important;
    }
    .review-cart .table-cart-main .cart-table .table-row td{
        padding-bottom: 10px !important;
    }
    .review-cart .table-cart-main .cart-table .table-row td.remove{
        width: 32px;
    }
    .review-cart .table-cart-main .cart-table .name select{
        display: none;
    }
    .review-cart .table-cart-main .cart-table .food-content.contain .price sup{
        font-size: 15px;
    }
    .review-cart .table-cart-main .cart-table .food-content.contain .price span{
        font-size: 15px;
    }
    .complete-order .product-details .food-content h6, .most-product .product-details .food-content h6{
        height: 37px;
        margin-bottom: 2px;
        font-size: 13px;
    }
    .scrollbar {
        height: auto;
        display: inline-flex;
        padding-bottom: 8px;
        width: 100%;
        border-top: 0;
        border-bottom: 0 !important;
    }
    .scrollbar .most-product{
        display: inline-flex;
        padding-right: 0;
    }
    .scrollbar hr{
        display: none;
    }
    .most-product .product-details{
        /*width: 250px;*/
        margin-right: 10px;
        border-bottom: 0;
        padding-bottom: 3px;
    }
    .most-product .product-details a{
        padding-bottom: 5px;
    }
    .most-product .food-column:last-child{
        margin-right: 0;
    }
    .product-articles .cus-slider.recent .food-column{
        margin-right: 10px;
        padding: 0;
        margin-bottom: 4px;
        width: 230px;
        min-width: 230px;
    }
    .complete-order .most-product .food-column {
        margin-right: 10px;
        padding: 0;
        margin-bottom: 4px;
        width: 230px;
        min-width: 230px;
    }
    .pagination .paginationBttns a{
        min-width: 34px;
        width: 34px;
        height: 34px;
        margin: 4px;
        font-size: 14px;
    }
    .complete-order .most-product .food-column:last-child{
        margin-right: 0 !important;
    }
    .complete-order .most-product .product-details{
        padding: 10px;
        border: 1px solid #eeeeee;
    }
    .complete-order .most-product .product-details:last-child {
        margin-right: 0;
        padding: 0 !important;
    }
    .complete-order .product-details .buttons{
        margin-top: 0;
    }
    .bg-section .complete-order .most-product .cus-slider{
        padding: 0;
    }
    .most-product .product-details .price{
        margin-top: 0;
    }
    #style-4::-webkit-scrollbar {
        width: 50px;
        height: 4px;
    }
    #style-4::-webkit-scrollbar-thumb {
        background: #56aac5 !important;
        height: 4px !important;
    }
    .most-product .product-details.cart-table .cart-img img{
        width: calc(100% - 10px);
    }
    .force-overflow {
        min-height:auto;
    }
    /*.mb-m {*/
    /*    margin-bottom: 26px;*/
    /*}*/
    .main-section.main-cart {
        margin-top: 0;
    }
    /*.main-section{*/
    /*    border-color: #e2e2e2;*/
    /*}*/
    .bg-primary-25 .h3-big {
        line-height: 26px;
    }
    .flex-direction {
        flex-direction: column-reverse;
    }
    .cart-selection .pickup-time {
        float: none;
        display: inline-block;
        width: max-content;
    }
    .storeModal .modal-dialog {
        width: 100%;
        max-width: 100%;
    }
    .modal-dialog {
        margin:1rem 0;
    }
    .modal-body {
        padding:0.5rem;
    }
    .modal-dialog .btn {
        padding: 8px 15px;
        height: auto;
    }
    .modal-dialog small.d-inline-block{
        text-align: center;
    }
    .cart-table .table tbody .table-row td{
        padding-top: 1rem !important;
    }
    .cart-table .table tr:last-child td{
        border-bottom: 0 !important;
    }
    .cart-table .table tbody .table-row td{
        padding-top: .4rem
    }
    .cart-table .table tr:last-child td{

    }
    .title h2 {
        font-size: 20px;
        font-weight: 700;
    }
    .store-loc .contact-details h6 {
        font-size: 14px;
    }
    .store-loc .contact-details p {
        font-size: 14px;
    }
    .cart-selection h4 {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .cart-table .image .contain img {
        max-width: fit-content;
        padding: 6px 0;
    }
    .cart-table {
        padding-bottom: 0;
    }
    .cart-table .table tbody .table-row td.price-details{
        position: relative;
        padding-top: 0.5rem !important;
        vertical-align: top;
    }
    .cart-itemlist .cart-table .slide-items .close-btn h6{
        margin-right: 10px;
    }
    .cart-table .table tbody .table-row td.quantity{
        display: none;
    }
    .cart-table .table tbody .table-row td.price-details{
        position: relative;
        width: 90px;
    }
    .cart-table .table tbody .table-row td.price-details .num-block {
        position: absolute;
        border-bottom: 0 !important;
        bottom: 11px;
        width: 80px;
        right: 0;
        height: auto;
        padding: 0 !important;
    }
    .cart-table .table td .skin-5 .num-in span{
        padding: 0 7px;
    }
    .cart-table .table td .skin-5 .num-in input{
        font-size: 13px;
        width: 30px;
    }
    .cart-table .food-content.contain .price span{
        font-size: 14px;
    }
    .cart-table .food-content.contain .price sup{
        font-size: 14px;
    }
    .content-padding.site-layout {
        padding-top: 0;
    }
    .space {
        padding: 0;
    }
    #wrapper.toggled {
        padding-right: 0;
    }
    .single-pro-right-main {
        padding-left: 15px!important;
    }
    .react-slider__picture {
        min-height: fit-content;
    }
    .single-product .block {
        padding: 0 15px;
    }
    .single-pro.product-details {
        margin-bottom: 0;
        padding-top: 5px;
    }

    .single-pro.product-details .food-maintitle h1 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1.4;
    }
    .icon-details-click{
        margin-top: 2px;
    }
    .single-pro.product-details .food-content h6{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .single-pro.product-details .food-content h6:last-child{
        font-size: 12px;
    }
    .catering-card{
        padding: 10px 0 6px;
    }
    .catering-card h6{
        font-size: 18px;
        color: #d30000;
        font-weight: 600;
        line-height: 0.9;
        margin-bottom: 20px;
    }
    .catering-card h6 span{
        font-size: 12px;
    }
    .catering-card p{
        font-size: 10px;
    }
    .catering-card small{
        font-size: 11px;
        margin: 5px 0 8px;
    }
    .checkout-details .main-section.checkout-info .form-details{
        padding: 0 15px;
    }
    .modal-dialog{
        padding: 0 15px;
        width: 100%;
    }
    .modal-dialog .modal-content {
        width: 100% !important;
        max-width: 100% !important;
    }
    .drop-modal {
        width: 100% !important;
        max-width: 100% !important;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: fit-content;
    }
    .complete-order .product-details .food-img {
        width: 60px;
        height: 60px;
    }
    .complete-order .food-content {
        width: calc(100% - 75px);
        padding-left: 0;
    }
    .complete-order .react-multi-carousel-item{
        padding: 0 10px;
    }
    .complete-order .react-multiple-carousel__arrow--left{
        left: 10px !important;
    }
    .complete-order .react-multiple-carousel__arrow--right{
        right: 10px !important;
    }
    .most-items{
        border: 1px solid lightgrey !important;
        padding: 10px 15px;
    }
    .most-items .complete-order{
        padding: 0 5px 0 0;
    }
    .most-items .react-multi-carousel-item .cart-table .cart-img img{
        position: relative;
    }
    .most-items .react-multi-carousel-item .buttons{
        margin-top: 0 !important;
    }
    .most-items .complete-order .react-multiple-carousel__arrow--left{
        left: -20px !important;
    }
    .most-items .complete-order .react-multiple-carousel__arrow--right{
        right: -15px !important;
    }
    .most-items .complete-order .react-multi-carousel-item{
        padding-left: 0;
        padding-right: 15px;
    }
    .most-bought .product-details .food-img {
        width: 60px;
        height: 60px;
    }
    .most-bought .food-content {
        width: calc(100% - 60px);
        padding-left: 10px;
    }
    .product-details .buttons {
        margin-top: 0;
    }
    .checkout-details .similar{
        display:block;
    }
    a.more-info {
        float: none;
    }
    .cart-pickup span b {
        font-size: 13px;
    }
    /*.checkout-details .main-section{*/
    /*    padding: 0 !important;*/
    /*    border: 0 !important;*/
    /*}*/
    .about-banner .banner-content h1 {
        font-size: 24px;
    }
    h5 {
        font-size: 18px;
    }
    .accordion__button {
        font-size: 14px;
    }
    .policy h4 {
        font-size: 16px;
    }
    .policy h6 {
        font-size: 12px;
    }
    .policy ul li {
        font-size: 14px;
        word-break: break-word;
    }
    .policy ul li span {
        font-size: 12px;
    }
    .policy .main-section {
        padding: 1rem 0rem 0.75rem;
    }
    .login-column {
        max-width: 100%;
        flex: 0 0 100%;
    }
    h1 {
        font-size: 22px;
    }
    .main-section form .login-check label {
        font-size: 12px;
    }
    .form-control {
        font-size: 12px;
    }
    .modal-body .card-heading{
        font-size: 18px;
    }
    .modal-body .card-text{
        font-size: 14px;
    }
    .info-bar {
        margin-bottom: 10px;
        margin-top: 0rem;
        padding: 10px;
    }
    .display-details{
        margin-top: 10px;
    }
    .modal-dialog .btn{
        font-size: 12px;
        width: 100%;
    }
    .modal-close{
        margin-top: 1px;
    }
    .drop-modal .store-select .card-title{
        font-size: 16px;
    }
    .drop-modal .store-select .distance{
        font-size: 12px;
        width: calc(100% - 60px);
    }
    .drop-modal .store-select button{
        width: 75px;
    }
    .rest-modal .modal-dialog{

    }
    .info-bar .left {
        width: auto;
        max-width: max-content;
        padding-right: 0;
        margin-left: -5px;
    }
    .info-bar .left span{
        display: none;
    }
    .info-bar .right {
        width: auto;
        max-width: max-content;
        padding: 0 95px 0 0;
        margin-left: 4px;
    }
    .info-bar .right span{
        display: none;
    }
    .info-bar .right .dropdown {
        width: 135px;
    }
    .info-bar .right .dropdown .btn{
        margin-left: 0;
    }
    .account-left .main-section {
        padding: 25px 16px 15px;
    }
    .account-left ul li{
        width: max-content;
        float: left;
    }
    .account-left ul li a{
        padding: 5px 0 !important;
        float: left;
        font-size: 14px !important;
    }
    .account-left .title h2.text-sm{
        font-size: 16px
    }
    .account-left .main-section{
        padding: 15px;
    }
    .account-left .main-section .account-details h6{
        padding-left: 0;
    }
    .account-right .main-section {
        padding: 1rem;
    }
    .checkout-details .card-slc{
        margin-bottom: 0px !important;
    }
    .checkout-details .card-slc .main-section{
        border: 1px solid #e2e2e2 !important;
        padding: 1rem !important;
    }
    .checkout-details .ebt-card .card-slc{
        margin-top: 0 !important;
    }
    .checkout-info .ebt-ifields{
        padding: 0 !important;
    }
    /*.ebt-ifields .col-md-6, .ebt-ifields .col-md-2{*/
    /*    margin-bottom: 6px;*/
    /*}*/
    .input-sm-cus.input-group{
        padding: 0;
    }
    .input-sm-cus.input-group .input-group-prepend{
        left: 0;
    }
    .checkout-details .ebt-card .card-slc .main-section .checkout-card .col-md-auto{
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .checkout-details .ebt-card .card-slc .main-section .checkout-card .col-md-5{
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .checkout-details .ebt-card .card-slc .main-section{
        border: 0 !important;
        padding: 0 !important;
    }
    .card-success .font-18{
        font-size: 16px !important;
    }
    .card-success h6{
        font-size: 14px !important;
        font-weight: 600;
    }
    .card-number span{
        letter-spacing: 1px;
        margin-right: 5px;
        display: inline-block;
    }
    .card-icon img{
        width: 38px;
        height: 38px;
    }
    .main-section .payment-method.payment .checkout-card .card-holder span{
        font-size: 12px;
    }
    .card-slc .main-section .badge {
        width: 20px;
        height: 20px;
        top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 20px;
        padding: 0;
    }
    .card-slc .main-section .badge svg{
        width: 8px;
        height: 8px;
    }
    .main-section.checkout-info .fields .react-tel-input .form-control{
        margin-bottom: 10px !important;
    }
    .main-section .account-details ul li .nav-link.active{
        background: transparent;
    }
    .account-right .main-section.head .text{
        display: inline-block;
        width: 60%;
    }
    .account-right .main-section.head .text h2{
        display: inline-block !important;
        width: 100%;
    }
    .account-right .main-section.head .text h2 span{
        width: 100%;
        display: block;
    }
    .account-right .main-section.head .text h2 a{
        width: max-content;
        margin: 10px 15px 0 0 !important;
        display: block;
        float: left;
    }
    .account-right .main-section.head .btn{
        font-size: 12px;
        padding: 5px 10px;
    }
    .account-right .card-slc{
        margin-bottom: 0;
        padding: 0 15px;
    }
    .account-right .card-slc .main-section .badge{
        top: 5px;
    }
    .account-right .address-btn{
        padding: 0 15px;
    }
    .account-right .blank-banner{
        margin-left: 0 !important;
    }
    .account-right .cart-table p{
        font-size: 12px;
    }
    .account-right .cart-table .table-row td{
        padding-bottom: 10px !important;
    }
    .account-right .cart-table{
        overflow: visible;
    }
    .table-responsive cart-table wish-table{
        overflow: visible;
    }
    .account-right .order-total h4{
        font-size: 16px;
        font-weight: 600 !important;
    }
    .account-right .wish-table .table-row td{
        padding-bottom: 20px !important;
    }
    .account-right .wish-table .table-row td.quantity{
        padding-bottom: 5px !important;
    }
    .account-right .itemlist .listbody .btn {
        margin-left: 27px;
        height: 32px;
    }
    .cart-table .check-mark {
        width: 25px;
        position: relative;
        transition: 0.3s all ease-in-out;
    }
    .account-right .main-section .form-group .field span{
        font-size: 16px;
    }
    .account-right .order-total ul li{
        font-size: 12px;
    }
    .account-right .order-total hr{
        margin: 15px 0;
    }
    .account-right .cart-table .price-details .contain {
        text-align: right;
        margin-bottom: 19px;
    }
    .account-right .itemlist .listbody .select-div{
        margin-bottom: 0px;
    }
    .account-right .list-table .name h6{
        height: 44px;
    }
    .account-right .cart-table .table tbody .table-row td.quantity{
        font-size: 12px;
        padding-top: 20px !important;
        text-align: right;
    }
    .account-right .cart-table .table tbody .table-row td.price-details .num-block {
        bottom: 16px;
    }
    .sp-mob-side{
        padding: 0 30px;
    }
    .spacing {
        padding: 0;
    }
    .breadcrumb-details .breadcrumb-item + .breadcrumb-item::before {
        font-size: 14px;
    }
    .breadcrumb-item+.breadcrumb-item::before {
        padding-right: 0.2rem;
    }
    .breadcrumb-details li.breadcrumb-item a {
        font-size: 13px;
    }
    .breadcrumb-details .breadcrumb-item.active {
        font-size: 13px;
    }

    p{
        font-size:14px;
    }
    .form-group {
        margin-bottom: 0.5rem;
    }
    .main-section .payment-method.payment .checkout-card{
        gap: 10px;
        flex-wrap: wrap;
    }
    .main-section .payment-method.payment .card-number span {
        font-size: 18px;
        letter-spacing: 1px;
    }
    .address-btn {
        margin-left: 0;
    }
    #wrapper.toggled .btn-order.btn-float{
        right: 10px !important;
    }
}



@media screen and (max-width: 991px){
    .mid-head-details a{
        display: contents;
    }
    .navbar-toggler {
        position: absolute;
        left: 5px;
        top: -50px;
        transition: all ease-in-out;
    }
    .header-nav{
        height: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .column {
        padding-left: 15px;
        max-width: 100%;
        flex: 0 0 100%;
    }
    .login-column {
        max-width: 65%;
        flex: 0 0 65%;
    }
    .display-details .food-column {
        width: 25%;
        flex: 0 0 25%;
        padding-left: 10px;
        margin-bottom: 10px;
    }
    /*.logo-col {*/
    /*    width: 140px;*/
    /*    margin-left: 44px;*/
    /*}*/

    .sidebar{
        display: none;
    }
    .search-bar{
        width: 90%;
        max-width: 90%;
    }
    header.header1 {
        padding: 10px 0;
    }
    .header-nav .navbar {
        padding: 0.5rem 0rem;
    }

    .mid-head-details{
        overflow: hidden;
    }
    .menu-col.top-header ul li a{
        display: flex;
    }
    .pickup-selection .dropdown img {
        filter: brightness(0) invert(1);
    }
    .pickup-selection.form-group span {
        color: white;
    }
    .pickup-selection .dropdown-toggle::after{
        color: white;
    }
    .pickup-selection.form-group span.pickup{
        color: white;
    }
    .cart-selection .selector-pick {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: auto;
    }
    .cart-selection .pickup-date.payment-method {
        display: inline-block;
        margin-right: 10px;
        width: auto;
    }
    .menu-col.top-header ul li a b {
        display: block;
    }
    .container1 {
        margin-right: 0;
        top: 124px;
        right: 0;
    }
}
.navbar-toggler {
    display: none;
}
.border-link{
    border-color: #56aac5 !important;
}
.border-selection{
    border-color: #28a745 !important;
}
@media screen and (min-width: 720px) and (max-width: 880px){
    .head-content .btn-icon{
        right: 80px;
    }
    .navbar-toggler {
        top: 15px !important;
    }
    .navbar-toggler svg {
        width: 30px !important;
    }
    .head-content .btn-icon{
        top: -33px;
    }
    .top-header ul li.menu-toggle {
        top: -26px;
    }
    .display-details .food-column{
        width: 33.33%;
        max-width: 33.33%;
        min-width: 33.33%;
        flex: 0 0 33.33%;
    }
    .single-pro-right{
        display: block !important;
    }
    .single-pro-right.d-md-block{
        display: none !important;
    }
}
@media screen and (min-width: 320px) and (max-width: 880px){
    .checkout-view{
        margin-bottom: 10px;
    }
    .order-card-main button.btn.btn-proceed{
        width: 100%;
    }
    .sidebar ul li:last-child{
        display: none;
    }
    .navbar-toggler {
        display: block;
        position: absolute;
        left: 5px;
        z-index: 9;
        transition: 0.3s all ease-in-out;
        top: 10px;
    }
    .navbar-toggler svg{
        color: black;
        width: 20px;
    }
    .item-list-mob .breadcrumb-details{
        display: none;
    }
    .item-list-mob{
        padding-top: 0 !important;
    }
    .item-list-mob .account-left{
        display: block !important;
        position: fixed;
        left: -280px;
        top: 0;
        width: 280px;
        border-radius: 0;
        background: white;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 999999;
        transition: 0.2s all ease-in-out;
        padding: 1rem;
    }
    .show-list{
        left: 0 !important;
    }
    .account-left .main-section {
        padding: 15px 5px;
        border: 0;
    }
    .item-list-mob .account-right .main-section{
        padding: 0 !important;
        border: 0;
    }

}
.item-list-mob .account-right .head h2{
    font-size: 1.25rem;
}
.similar{
    display:flex;
    justify-content: space-between;
}
malignmark button{
    display: none;
}
@media (max-width: 992px){
    .header-nav malignmark.show-drop{
        z-index: 999999 !important;
    }
    .navbar-collapse{
        display: block !important;
        position: fixed;
        left: -280px;
        top: 0;
        width: 280px;
        background: white;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 999999;
        transition: 0.2s all ease-in-out;
        padding: 1rem 1rem 100px;
    }
    .navbar-collapse.show-menu{
        left: 0;
    }
    .navbar-collapse ul li{
        align-items: center;
        display: flex;
    }
    .navbar-collapse ul li a{
        align-items: center;
        display: flex;
    }
    .navbar-collapse ul li img{
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -1px;
    }

    .header-nav ul.navbar-nav.menu-details.ml-auto li:first-child{
        color: black;
        font-weight: 600 !important;
        margin-bottom: 10px !important;
        width: 100%;
        display: inline-block;
    }
    .header-nav ul.navbar-nav.menu-details.ml-auto ul{
        display: inline-grid;
    }
    .header-nav ul.navbar-nav.menu-details.ml-auto ul li:first-child{
        margin: 4px 0 !important;
    }
    .header-nav ul.navbar-nav.menu-details.ml-auto li.title{
        color: black;
        font-weight: 600 !important;
        margin-bottom: 10px !important;
    }
    malignmark{
        background: black;
        opacity: 0;
        top: 0;
        left: 0 !important;
        right: 0 !important;
        width: 100%;
        display: none;
        height: 100vh;
        z-index: 1;
        transition: 0.15s all ease-in-out;
        position: fixed;
    }
    malignmark.show-drop{
        opacity: 0.7;
        display: block;
        z-index: 10 !important;
     }
    malignmark.show-drop button{
        opacity: 0;
        display: block;
        width: 100% !important;
        left: 0;
        top: 0;
        height: 100vh !important;
        z-index: 1;
        position: relative;
    }


    .user-menu ul.navbar-nav.menu-details li a{
        color: #46474a;
        font-weight: 500 !important;
        width: 100%;
        padding: 0;
        font-size: 15px !important;
    }
    .user-menu ul.navbar-nav.menu-details li a.active{
        font-weight: 700 !important;
        color: black !important;
    }
    .user-menu ul.navbar-nav.menu-details li span{
        color: #46474a;
        font-weight: 500 !important;
        font-size: 15px !important;
        float: left;
    }
    .user-menu ul.navbar-nav.menu-details li a b{
        color: #46474a;
        font-weight: 500 !important;
        font-size: 15px !important;
        margin-left: 0 !important;
    }
    .user-menu ul.navbar-nav.menu-details li{
        margin: 5px 0;
        display: block !important;
    }
    .user-menu ul.navbar-nav.menu-details li span{
        margin: 0 5px;
    }
    .user-menu ul.navbar-nav.menu-details:first-child li span{
        font-size: 15px !important;
    }
    .user-menu ul.navbar-nav.menu-details:first-child li a b{
        font-weight: 500 !important;
        font-size: 15px !important;
    }
    .user-menu ul li img{
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -1px;
    }
    .user-menu ul.navbar-nav.menu-details:first-child li a{
        width: auto;
        float: left;
    }
    .user-menu ul.navbar-nav.menu-details:first-child li a b{
        color: #008CBA !important;
        text-decoration: underline;
    }

    .header-nav ul.navbar-nav.menu-details li{
        margin-left: 0 !important;
        margin: 4px 0 !important;
    }
    .header-nav ul.navbar-nav.menu-details.shop-list li:last-child{
        display: none;
    }
    .header-nav ul.navbar-nav.menu-details h6{
        font-weight: 700 !important;
        font-size: 17px !important;
    }
    .breadcrumb-details .breadcrumb{
        margin: 0 0 10px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .header-nav ul.navbar-nav.menu-details li a{
        color: #46474a;
        font-weight: 500 !important;
        width: 100%;
        font-size: 15px !important;
    }
    .header-nav ul.navbar-nav.menu-details li a.active{
        font-weight: 700 !important;
        color: black !important;
    }
    .header-nav ul.navbar-nav.menu-details li a.font-weight-normal.active{
        font-weight: 500 !important;
    }
    .sidebar h2.shop-title{
        font-weight: 700!important;
        font-size: 17px!important;
    }
    .sidebar ul{
        padding-left: 0;
    }
    .sidebar ul li a{
        padding: 0;
    }
    .sidebar .filter h2{
        font-weight: 600;
    }
    .sidebar ul li a.active{
        background: transparent !important;
        font-weight: 700 !important;
        padding: 0;
        color: black;
    }
    .header-nav ul.navbar-nav.menu-details li span{
        color: #46474a;
        font-weight: 500 !important;
        font-size: 15px !important;
        float: left;
    }
    .header-nav ul.navbar-nav.menu-details li a b{
        color: #46474a;
        font-weight: 500 !important;
        font-size: 15px !important;
        margin-left: 0 !important;
    }
    .header-nav ul.navbar-nav.menu-details li span{
        margin: 0 5px;
    }
    .header-nav ul.navbar-nav.menu-details:first-child li span{
        font-size: 15px !important;
    }
    .header-nav ul.navbar-nav.menu-details:first-child li a b{
        font-weight: 500 !important;
        font-size: 15px !important;
    }
    /*.header-nav ul.navbar-nav.menu-details:first-child li a:first-child{*/
    /*    background: #d30000;*/
    /*    color: white;*/
    /*    padding: 5px 10px;*/
    /*    border-radius: 4px;*/
    /*}*/
    /*.header-nav ul.navbar-nav.menu-details:first-child li a:first-child{*/
    /*    color: white;*/
    /*}*/
    .header-nav ul.navbar-nav.menu-details:first-child li a{
        width: auto;
        float: left;
    }
    .header-nav ul.navbar-nav.menu-details:first-child li a b{
        color: #008CBA !important;
        text-decoration: underline;
    }
    /*.header-nav ul.navbar-nav.menu-details:first-child li a:first-child img{*/
    /*    width: 20px;*/
    /*    top: 0;*/
    /*    color: #008CBA;*/
    /*}*/
    /*.header-nav ul.navbar-nav.menu-details li.title{*/
    /*    color: #281713;*/
    /*    font-weight: 600 !important;*/
    /*    font-size: 18px !important;*/
    /*    margin-left: 0 !important;*/
    /*    padding: 0;*/
    /*}*/
    .header-nav .navbar-collapse .btn-close {
        display: block;
        position: absolute;
        right: 15px;
        border: 0;
        width: 36px;
        height: 36px;
        border-radius: 60px;
        transition: 0.2s all ease-in-out;
    }
    .dropdown .btn-tranparent {
        color: black;
        text-align: left;
        font-size: 18px;
    }
    .header-nav ul .nav-item .nav-link{
        padding: 0;
    }
}
.ebt-ifields .col-md-9{
    margin-top: 10px;
}
.add-card-container .ebt-ifields{
    padding: 0 !important;
    margin-bottom: 20px;
}
.ebt-ifields .col-md-3{
    margin-top: 10px;
}
.ebt-ifields input{
    font-weight: 500;
    font-size: 14px;
}
.ebt-ifields iframe{
    border: none !important;
    overflow: hidden;
    height: 46px;
}
.btn-ebt-pay{
    height: 32px;
    padding: 0 14px;
    font-size: 12px !important;
    border: 1px solid #5ab7d4 !important;
    background: white !important;
    color: #281713 !important;
}
.ebt-ifields iframe html{
    overflow: hidden !important;
}
.ebt-payment-selection-checkbox{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0 !important;
    display: block;
    cursor: pointer;
    top: 0;
}

.ebt-label {
    margin-top: 10px;
    margin-left: 10px;
}


.fs-12 {
    font-size: 12px !important;
}
.fs-7 {
    font-size: 16px !important;
}

.fs-10 {
    font-size: 15px !important;
}

.fs-12 {
    font-size: 12px !important;
}
.fs-13 {
    font-size: 13px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-9 {
    font-size: 18px !important;
}
.fs-24 {
    font-size: 22px !important;
}

/* Define a class for disabled options */
.disabled-option {
    color: #CCCCCC !important;  /* Medium gray text color */
    background-color: #f2f2f2 !important; /* Light gray background color */
    opacity: 0.7 !important; /* Reduce opacity for a muted look */
    text-decoration: line-through !important; /* Optionally, you can use strikethrough text */
}

.badge-default{
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    -webkit-font-smoothing: antialiased;
    width: fit-content;
    margin-bottom: 24px;
    background-color: rgb(238, 236, 236);
    border-radius: 40px;
    color: rgb(65, 65, 66);
    padding: 4px 12px;
}
.hidePassword {
    float: right;
    margin-right: 12px;
    margin-top: -31px;
    position: relative;
    cursor: pointer;
    z-index: 2;
    font-size: 13px;
    color: #9a9a9a
}
.hidePassword:hover svg{
    color: #006f94 !important;
    cursor: pointer;
}
.ebt-amount .input-group .input-group-prepend{
    left: 2px;
}
.hidePassword svg{
    color: #56aac5;
}
.form-control{
    color: #212121;
}
.ant-picker-input > input{
    color: #212121 !important;
}
.form-control::placeholder {
    color: rgba(33, 33, 33, 0.60) !important;
    font-weight: 400 !important;
}

.react-loading-skeleton {
    top: 0 !important;
}
.fooVeKSuccess.alert-message .text-message{
    font-size: 13px;
}
.fooVeK.fooVeKSuccess.alert-message {
    color: #28a745 !important;
}
.fooVeK.alert-message {
    width: calc(100% - 70px);
    margin: 0;
    left: 27px;
    bottom: -0.5px;
    align-items: start !important;
    line-height: 15px;
    position: absolute;
    height: 44px;
    padding: 2px 5px;
    background: #f5f8fa !important;
}
.w-14{
    width: 16px;
    height: 16px;
}
.text-success path{
    fill: #28a745;
}
/*.promo-export .line-break{*/
/*    display: none;*/
/*}*/
.promo-export .mb-fix{
    margin-bottom: 0;
}
.promo-export .bg-primary-25{
    margin-bottom: 0 !important;
}
.promo-export .mb-fix .px-0.px-md-3.pt-3{
    padding: 0 !important;
}
.promo-export .sec-main-promo{
    padding: 0;
}
@media (max-width: 768px) {
    .fooVeK.alert-message {
        left: 19px;
        bottom: 25px;
        padding: 2px 5px;
        width: calc(100% - 40px);
        height: 63px;
    }
}
.floating-field {
    position: relative;
    margin-bottom: 0;
}
.card-shadow{
    width: 420px;
    max-width: 420px;
    box-shadow: rgba(149, 157, 165, 0.15) 0px 0px 24px !important;
}
.floating-field input {
    font-size: 15px;
    padding: 22px 0 4px !important;
    width: 100% !important;
    border: 0 !important;
    border-bottom: 1px solid #D7D7D8 !important;
    border-radius: 0 !important;
    outline: none;
    font-weight: 400;
    line-height: 1.5;
    height: unset !important;
    transition: border-color 0.3s ease;
}
.floating-field input::placeholder{
    color: transparent !important;
}
.floating-field .ant-picker{
    padding: 0;
    border: 0 !important;
}
.floating-field.date-field label{
    transform: translateY(-24px);
    font-size: 12px;
    color: rgba(33, 33, 33, 0.60);
}
.floating-field.date-field .ant-picker-input > input{
    color: #212121;
    font-size: 15px;
    font-family: 'Poppins' !important;
}
.floating-field.number-field .react-tel-input .form-control::placeholder{
    color: rgba(33, 33, 33, 0.60) !important;
}
.floating-field.number-field .react-tel-input .form-control{
    color: #212121;
    font-size: 15px;
    line-height: 1.5;
    font-family: 'Poppins' !important;
}
.floating-field.number-field label{
    transform: translateY(-24px);
    font-size: 12px;
    color: rgba(33, 33, 33, 0.60);
}
.floating-field.number-field input::placeholder{
    color: rgba(33, 33, 33, 0.60) !important;
}
.floating-field .ant-picker-suffix{
    display: none;
}
.floating-field label {
    position: absolute;
    top: 22px;
    left: 0;
    font-size: 15px;
    color: rgba(33, 33, 33, 0.60);
    pointer-events: none;
    transition: transform 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}

.floating-field input:focus {
    border-color: rgba(33, 33, 33, 1) !important;
}

.floating-field input:focus + label,
.floating-field input:not(:placeholder-shown) + label {
    transform: translateY(-22px);
    font-size: 12px;
    color: rgba(33, 33, 33, 0.60);
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(33, 33, 33, 1) !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #fff;
}

.react-datepicker__year-select option:nth-last-child(-n+91)  {
    display: none !important;
}

.react-datepicker-wrapper {
    width: 100%;
}
.timing-main{
    width: 220px !important;
    display: block;
    padding: 5px 0 !important;
}
.timing-main li{
    font-size: 12px !important;
}
.date-item{
    user-select: none;
    pointer-events: none;
    width: 100%;
    padding: 6px 12px !important;
}
.MuiPopover-paper{
    box-shadow: 0 0 35px rgb(0 0 0 / 15%) !important;
}
.footer-content p.w-max{
    width: max-content;
}

.text-open , .text-blue {
    color: #5ab7d4;
}

.text-open.footer-bg {
    color: white;
}

.text-timing {
    color: #696969 !important;
}
.text-link{
    color: #5ab7d4;
}
.title-rc{
    position: relative;
    top: -7px;
}
.text-timing.footer-bg {
    color: white !important;
}

.text-open:hover  {
    color: #6498b4 !important;
}

.text-timing:hover {
    color: #5ab7d4 !important;
}

.text-open.footer-bg:hover {
    color: #5ab7d4;
}

.pointer {
    cursor: pointer !important;
}

.pointer-event-none {
    pointer-events: none !important;
}


.text-blue {
    color: #5ab7d4 !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.num-in.no-bg {
    background: transparent !important;
    border: none !important;
}

.slc-preference{
    position: relative;
    border: 1px solid #ebebeb;
    border-bottom: 0;
    background: #fafafa;
    border-radius: 12px 12px 0 0;
    padding: 0.5rem 1rem 0.4rem;
    color: #999999;
    box-shadow: 0 0 55px rgb(0 0 0 / 1%);
}
/*.top-radius-0{*/
/*    border-top-left-radius: 0;*/
/*    border-top-right-radius: 0;*/
/* }*/
.slc-preference svg{
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
}
.slc-preference.active svg{
    position: relative;
    top: -2.5px;
    width: 22px;
    height: 22px;
}
.slc-preference svg path{
    stroke: #FFF;
    fill: #212121;
    stroke-width: 6px;
}
.cart-tab-main{
    z-index: 3;
    position: relative;
    /*background: #f1f1f1;*/
    /*border-radius: 8px 8px 0 0;*/
    /*padding: 4px 16px 0;*/
}
.cart-tab-main .row .cart-tab-div:nth-child(3) .slc-preference{
    border-top-left-radius: 0;
    border-left-width: 0;
    left: -2px;
}
.border-2{
    border-width: 2px !important;
}
.cart-tab-main .row .cart-tab-div:nth-child(3) .slc-preference.active{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-left-width: 2px;
    left: -2px;
    position: relative;
    z-index: 3;
}
.cart-tab-main .row .cart-tab-div:nth-child(2) .slc-preference{
    border-top-right-radius: 0;
    border-right-width: 0;
}
.cart-tab-main .row .cart-tab-div:nth-child(2) .slc-preference.active{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-right-width: 2px;
    position: relative;
    z-index: 3;
    /*left: 2px;*/
}
.slc-preference:hover{
    border-color: #ebebeb;
    color: #56aac5;
    cursor: pointer;
}
.slc-preference:hover h3{
    color: #56aac5;
}
.slc-preference:hover svg path{
    stroke: #56aac5;
    fill: #56aac5;
    stroke-width: 4px;
 }
.slc-preference h3{
    font-weight: 400;
    font-size: 14px;
    color: #212121;
}
.slc-preference.active h3{
    font-size: 1.125rem
}
.slc-preference.active{
    padding: 0.7rem 1.4rem 0.5rem;
    border-color: #f1f1f1;
    border-width: 2px;
    background: white;
}

.slc-preference.active:after{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 100%;
    background: white;
    z-index: 0;
}
.slc-preference *{
    z-index: 1;
}
.slc-preference.active svg path{
    stroke: #56aac5;
    fill: #56aac5;
}
.slc-preference.active:hover svg path{
    stroke-width: 6px;
}
.slc-preference.active:hover{
    cursor: default;
}
.slc-preference.active h3{
    color: #56aac5;
}
@media (max-width: 768px) {
    .slc-preference.active:after {
        bottom: -2px;
    }
    .cart-pickup svg{
        width: 18px;
        height: 18px;
    }
    .slc-preference svg {
        top: -3px;
    }
    .cart-pickup h3{
        font-size: 1rem;
    }
    .card-shadow{
        width: 100%;
        max-width: 100%;
    }
    .modal-dialog-ebt {
        width: 100% !important; /* Adjust the width as needed for smaller screens */
    }
    .pickup-selection.form-group span {
        font-size: 16px;
        font-weight: 500;
        top: 2px;
        position: relative;
        color: #fff;
    }
    .pickup-selection .dropdown .btn span {
        max-width: calc(100% - 70px);
    }
    .pickup-selection .dropdown .btn svg{
        position: relative;
        top: 1px;
    }
    .pickup-selection.form-group span:hover {
        color: #fff;
    }
}
.loc-slc{
    border: 1px solid #e2e2e2;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
}
.loc-slc:last-child{
    margin-bottom: 0;
}
.loc-slc.active .distance .text-title{
    font-size: 0.98rem;
}
.loc-slc.active{
    border: 2px solid #5ab7d4;
}
.cart-dropdown .dropdown-menu {
    box-shadow: 0 0 18px rgb(0 0 0 / 20%) !important;
    border: 0px solid #e2e2e2;
    border-radius: 5px;
}
.cart-dropdown .dropdown-menu .border-top{
    padding: 8px 12px 11px !important;
    text-align: center;
}
.cart-dropdown .dropdown-menu .border-top .more-link:hover{
    color: #b31f1d !important;
}
.cart-dropdown .dropdown-menu .btn{
    height: 26px;
    margin: 0;
}
.cart-dropdown-left, .cart-dropdown-right {
    min-width: 160px;
}
.cart-dropdown button, .cart-dropdown button:hover, .cart-dropdown button:active, .cart-dropdown button:focus {
    background: #d30000 !important;
}

.cart-dropdown .dropdown-toggle:after {
    display: none !important;
}

.cart-dropdown .dropdown-toggle {
    position: relative;
}

.cart-item-dropdown .dropdown-toggle {
   z-index: 999 !important;
}

.cart-dropdown .dropdown-menu.drop-down-center.bottom {
    inset: 0 auto auto -105px !important;
}

.cart-dropdown  .dropdown-menu.drop-down-center.top {
    inset: auto auto 0 -105px !important;
}

.cart-dropdown .dropdown-toggle svg {
    position: absolute;
    right: 10px;
    top: 11px;
}

.cart-dropdown .dropdown-menu {
    padding: 0 !important;
    z-index: 1000 !important;
    margin-bottom: 5px;
}

.cart-dropdown .dropdown-item {
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    padding: 5px 8px 5px 20px !important;
}

.cart-dropdown .dropdown-item:active {
   background-color: transparent !important;
}
.cart-dropdown .dropdown-item.selected {
    background-color: #2aa1c0 !important;
}

.cart-dropdown .dropdown-item.disabled {
    background-color: #fff !important;
    opacity: 0.6;
    pointer-events: none !important;
}

.cart-dropdown .dropdown-item .label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}

.cart-dropdown .add-to-cart-option-item{
    display: block !important;
}
.cart-dropdown .dropdown-item .label p {
    text-align: left !important;
    font-size: 13px !important;
}

.cart-dropdown .dropdown-item .label p, .cart-dropdown .dropdown-item .label {
    text-align: left !important;
    font-size: 13px !important;
}
.cart-dropdown .dropdown-item .label p:first-letter{
    text-transform: uppercase;
}
.cart-dropdown .dropdown-item.selected .label p {
    color: white !important;
}

.cart-dropdown .dropdown-item.last-item {
    border: none !important;
}

.cart-dropdown .dropdown-item svg {
    position: absolute;
    right: 10px;
    top: 18px;
}
.cart-dropdown h6{
    font-size: 14px !important;
    height: unset !important;
    margin: 0 0 6px !important;
    padding: 7px 12px !important;
    background: #f1f1f1;
}
.cart-dropdown p{
    padding: 0px !important;
}
.cart-drop-main{
    z-index: 99;
}
.list-max-drop{
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden !important;
}
.mb-2px{
    margin-bottom: 2px !important;
}
.mt-2px{
    margin-top: 2px !important;
}



.coupon-unavailable{
    border: 1px solid #ddd;
    max-width: 500px;
    padding: 30px;
    margin-top: 15px;
    text-align: center;
    background-color: #fafafa !important;
    border-radius: 5px;
}

.coupon-card {
    border: 1px solid #ddd;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 14px;
    padding: 2px 2px 10px;
    text-align: center;
    background-color: #f9f9f9 !important;
    border-radius: 5px;
    position: relative !important;
}

.coupon-title {
    font-size: 16px;
    font-weight: 600;
}

.coupon-description {
    font-size: 12px;
}

.special-card {
    border: 1px dashed #ffc601;
    background: rgb(255 198 1 / 17%);
    padding: 8px 14px; /* Added padding for the sides */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.coupon-name {
    font-size: 13px !important;
    font-weight: 500;
    text-align: left;
}

.coupon-apply {
    background-color: #fec601;
    color: #281713;
    padding: 9px 20px;
    border: none;
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: none !important;
    outline: none !important;
}

.coupon-remove {
    background-color: #dc2a27;
    color: white;
    padding: 9px 20px;
    border: none;
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: none !important;
    outline: none !important;
}

.card-shadow{
    width: 420px;
    max-width: 420px;
    box-shadow: rgba(149, 157, 165, 0.15) 0px 0px 24px !important;
}
.coupon-applied {
    margin-left: 8px;
    letter-spacing: 0.28px;
    font-weight: 300;
}

.applied-coupon-featured {
    position: absolute;
    left: 0 !important;
    top: 0;
}
.coupon-img{
    width: 24px !important;
}

.applied-coupon-featured span {
    background: green;
    font-size: 13px;
    font-weight: 700;
    padding: 6px 25px;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.coupon-offer-title::before,
.coupon-offer-title::after {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgba(184, 184, 184, 0.376);
    margin: 0px 10px;
}

.coupon-offer-title{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.565);
}

.icon-small {
    color: #888;
    font-size: 12px; /* Adjust the size as needed */
    line-height: 1; /* Optionally adjust the line height */
}

.offer-card.card-slc{
    cursor: default;
}
.offer-card.card-slc .payment-card{
    cursor: default;
}
.offer-card.card-slc .disabled{
    pointer-events: none;
    opacity: 0.5 ;/* You can adjust the opacity to visually indicate that the card is disabled. */
    /* Optionally, you can add a gray background to visually distinguish the disabled card. */
    background-color: #f2f2f2;
}
.offer-card.card-slc.disabled .payment-card{
    border-color: #e0e0e0 !important;
    opacity: 0.5;
    pointer-events: none;
}
.offer-card.card-slc .applied{
    border-color: #00a560 !important;
}
.width-left-side{
    width: calc(100% - 320px);
    max-width: calc(100% - 320px);
    flex: unset;
}
.side-cart-width{
    width: 320px;
    max-width: 320px;
    flex: unset;
}
.tooltip-text.shadow-sm {
    box-shadow: 0 0 20px rgb(0 0 0 / 12%) !important;
    padding: 12px 15px !important;
    width: max-content !important;
    min-width: max-content !important;
    left: 0;
    margin: 4px 0 0;
}
.tooltip-text.shadow-sm p.font-weight-semibold{
    font-weight: 600;
}
.tooltip-text.shadow-sm p{
    padding: 0;
}
.tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: #000;
    color: white !important;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.2s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* styles/CouponBanner.module.css */
.coupon-banner {
    display: none;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    /*background-color: rgba(0, 129, 97, 0.1);*/
    /*background-color: rgba(66, 178, 0, 0.15);*/
    /*background-color: rgba(0, 124, 0, 0.15);*/
    /*background-color: rgba(32, 121, 0, 0.2);*/
    /*background-color: rgba(0, 130, 97, 0.20);*/
    background: #fee798;
    color: #281713;
    padding: 15px 10px 14px;
    text-align: center;
    font-size: 14px;
    z-index: 1000;
}

.coupon-banner span{
    font-weight: 400;
}
.coupon-banner span span{
    color: #281713;
   font-weight: 600;
    line-height: 1.4;
}

.coupon-banner span.d-inline-block span{
   font-weight: 400;
}
.coupon-banner a{
    color: #666;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    font-weight: 400;
    position: relative;
}
.coupon-banner a:hover{
    font-weight: 500;
    color: #281713;
    text-decoration: underline;
}
.coupon-banner .card-terms svg{
    position: absolute;
    top: -7px;
    width: 24px;
    right: 35px;
}
.coupon-banner .card-terms{
    display: none;
    position: absolute;
    width: 260px;
    min-width: 260px;
    max-width: 260px;
    background: white;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%) !important;
    border-radius: 8px;
    padding: 10px 15px;
    text-align: left;
    right: 0;
    top: 26px;
    transition: 0.35s all ease-in-out;

}
.coupon-banner span.position-relative{
    cursor: pointer;
}
.coupon-banner span.position-relative.open:{
    color: #281713;
}
.coupon-banner span.position-relative.open:hover{
    color: #2a87d0;
}
.coupon-banner span.position-relative:hover{
    color: #2a87d0;
}
/*.coupon-banner span.position-relative:hover .card-terms{*/
/*    display: block;*/
/*}*/
/*.coupon-banner a:hover .card-terms{*/
/*    display: block;*/
/*}*/
.cart-head-icon{
    position: relative;
    top: 1px;
}
.coupon-banner .card-terms p.bold{
    font-size: 13px;
    font-weight: 600;
}
.coupon-banner .card-terms p{
    font-size: 12px;
    font-weight: 400;
    color: #281713;
    margin-bottom: 0px;
}
.coupon-banner .card-terms p.bold{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0px;
}
.coupon-banner .card-terms p svg{
    position: relative;
    top: -0.5px;
    right: -5px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    margin-left: 20px;
}
.coupon-banner img{
    width: 20px;
    height: 20px;
    filter: contrast(0.6);
    image-rendering: auto;
    position: relative;
    top: -1.5px;
    margin: 0 5px 0 0;
}
.c-img{
    width: 24px;
}
/* Add styles for close button */
.close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-weight: bold;
}

body.with-coupon-banner {
    margin-top: 40px;
}
@media screen and (min-width: 320px) and  (max-width:880px) {
    .list-max-drop {
        max-height: 420px;
    }
}
@media (max-width: 600px) {
    .offer-container1 {
        border: 0 !important;
        top: 53px;
        height: calc(100vh - 53px) !important;
        right: 0;
        margin-right: 0;
        width: 100% !important;
    }
    .coupon-banner {
        padding: 10px 15px;
        font-size: 16px;
    }
    .coupon-banner img{
        width: 22px;
    }
    .coupon-banner .card-terms{
        right: unset;
        left: 0;
        top: 30px;
    }
    .coupon-banner .card-terms svg{
        left: 45px;
        right: unset;
    }
    .coupon-banner .card-terms p.bold{
        font-size: 13px;
    }
    .coupon-banner .card-terms p svg{
        margin-right: 0;
        top: -2px;
        left: unset;
    }
    .close-button{
        transform: translateY(40%);
    }
    .close-button svg{
        width: 18px;
        height: 18px;
    }
    .coupon-banner span.position-relative.open:{
        color: #281713;
    }
    .coupon-banner span.position-relative.open:hover{
        color: #281713;
    }
    /*header.header1.cb-open {*/
    /*    top: 74px;*/
    /*}*/
}
.hidePassword {
    float: right;
    margin-right: 10px;
    margin-top: -32px;
    position: relative;
    z-index: 2;
}
.hidePassword:hover {
    color: #00fcb6;
    cursor: pointer;
}
.btn-login{
    position: relative;
    height: 38px;
    border-radius: 60px;
    color: white;
}
.btn-login img{
    position: absolute;
    left: 4px;
    top: 3.5px;
    width: 30px;
    height: 30px;
    border-radius: 60px;
}
.btn-login.btn-google{
    background: #4285f4;
}
.btn-login.btn-google:hover{
    background: #306bce;
    color: white;
}
.btn-login.btn-facebook{
    background: #385c8e;
}
.btn-login.btn-facebook:hover{
    background: #2F4779;
    color: white;
}
.btn-login.btn-apple{
    background: #281713;
}
.btn-login.btn-apple:hover{
    background: #000;
    color: white;
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.spinner-apply {
    min-width: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.num-in.no-bg {
    background: transparent !important;
    border: none !important;
}

.num-in.disabled {
    opacity: 0.7 !important;
}
.w-min{
    min-width: max-content !important;
}

/*Cart, checkout page dropdown styles*/
.cart-item-dropdown .dropdown-menu {
    box-shadow: 0 0 20px rgb(0 0 0 / 15%) !important;
    border: 0px solid #e2e2e2;
    border-radius: 5px;
}
.cart-item-dropdown .dropdown-menu .border-top{
    padding: 12px !important;
    padding: 12px !important;
}
.cart-item-dropdown .dropdown-menu .btn{
    height: 26px;
    margin: 0;
}

.cart-item-dropdown .dropdown-toggle, .cart-item-dropdown .dropdown-toggle:hover, .cart-item-dropdown .dropdown-toggle:active, .cart-item-dropdown .dropdown-toggle:focus {
    background: transparent !important;
    color: #414142 !important;
    padding-right: 35px !important;
}

.cart-item-dropdown button, .cart-item-dropdown button:hover, .cart-item-dropdown button:active, .cart-item-dropdown button:focus {
    background: #d30000 !important;
}

.cart-item-dropdown .dropdown-toggle:after {
    display: none !important;
}

.cart-item-dropdown .dropdown-toggle {
    text-align: left;
    position: relative;
}

.cart-item-dropdown .btn-m {
    height: fit-content !important;
}

.cart-item-dropdown .dropdown-toggle svg {
    position: absolute;
    right: 10px;
    top: 2px;
    fill: #212121;
}

.cart-item-dropdown .dropdown-menu {
    padding: 0 !important;
    z-index: 1000 !important;
}

.cart-item-dropdown .dropdown-menu.mob-drop-down-center.bottom {
    inset: 0 auto auto -40px !important;
}

.cart-item-dropdown .dropdown-menu.mob-drop-down-center.top {
    inset: auto auto 0 -40px !important;
}

.cart-item-dropdown .dropdown-item {
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    padding: 6px 8px 6px 12px !important;
}

.cart-item-dropdown .dropdown-item {
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    padding: 6px 8px 6px 12px !important;
}

.cart-item-dropdown .dropdown-item:focus, .cart-item-dropdown .dropdown-item:active {
    background-color: unset !important;
}

.cart-item-dropdown .dropdown-item.selected {
    background-color: #2aa1c0 !important;
}

.cart-item-dropdown .dropdown-item.disabled {
    background-color: #fafafa !important;
    opacity: 0.9;
    pointer-events: none !important;
}

.cart-item-dropdown .dropdown-item .label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}

.cart-item-dropdown .add-to-cart-option-item{
    display: block !important;
}
.cart-item-dropdown .dropdown-item .label p {
    text-align: left !important;
    font-size: 13px !important;
}

.cart-item-dropdown .dropdown-item .label p, .cart-item-dropdown .dropdown-item .label {
    text-align: left !important;
    font-size: 13px !important;
}
.cart-item-dropdown .dropdown-item .label p:first-letter{
    text-transform: uppercase;
}
.cart-item-dropdown .dropdown-item.selected .label p {
    color: white !important;
}

.cart-dropdown .dropdown-item.last-item {
    border: none !important;
}

.cart-dropdown .dropdown-item svg {
    position: absolute;
    right: 10px;
    top: 18px;
}
.cart-dropdown h6{
    font-size: 12px !important;
    height: unset !important;
    margin: 0 0 6px !important;
    padding: 7px 12px !important;
    background: #f1f1f1;
    color: #434343;
}
.cart-dropdown h6.title{
    font-size: 13px !important;
    margin: 0 !important;
    padding: 12px 12px !important;
}

.cart-dropdown p{
    padding: 0px !important;
}
.cart-item-dropdown h6{
    font-size: 14px !important;
    height: unset !important;
    margin: 0 0 6px !important;
    padding: 7px 12px !important;
    background: #f1f1f1;
}
.cart-item-dropdown h6.title{
    margin: 0 !important;
    padding: 12px 12px !important;
}

.cart-item-dropdown p{
    padding: 0px !important;
}

.actions-disabled {
    pointer-events: none !important;
    opacity: 0.7 !important;
}

.drop-count .count{
    width: 16px;
    text-align: center;
    margin: 0 3px;
    display: block;
    position: relative;
    font-size: 12px;
    top: 1px;
    pointer-events: none;
}
.drop-count .num-in{
    display: flex;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 30px;
}
.drop-count .minus{
    width: 28px;
    padding: 6px !important;
    height: 28px;
    cursor: pointer;
    display: block;
}
.drop-count .minus i {
    position: relative;
    top: -3.5px;
    left: 4px;
    font-size: 12px;
}
.drop-count .plus{
    width: 28px;
    padding: 6px !important;
    height: 28px;
    cursor: pointer;
    display: block;
}
.drop-count .plus i {
    position: relative;
    top: -3px;
    left: 3px;
    font-size: 12px;
}

.dropdown-added-item{
    position: relative;
    transition: 0.2s all ease-in-out !important;
    left: 0;
}

.slide-items.confirm .dropdown-added-item {
    position: relative !important;
    left: 260px !important;
    opacity: 0.6;
}

.slide-items .btn-transparent {
    background-color: transparent !important;
}

.slide-items .btn-transparent:hover {
    background-color: #343a40 !important;
}

.slide-items .btn-normal {
    height: unset !important;
}

.slide-items .btn-normal.btn-sm {
    font-size: 11px;
    padding: 2px 5px 2px 5px !important;
}

.add-to-cart-dropdown-body .slide-items .area p {
    font-size: 12px !important;
}

.add-to-cart-dropdown-body .slide-items hr {
    margin: 0 !important;
}

.add-to-cart-dropdown-body .slide-items.confirm .area {
    left: 12px !important;
}

.backdrop-dropdown {
    position: fixed;
    top: 0;
    transition: 0.2s all ease-in-out;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    background: transparent;
    content: "";
    z-index: 998;
    display: none;
}

.backdrop-dropdown.show {
    display: block;
}

.backdrop-dropdown.isMobile {
    background: rgba(0, 0, 0, 0.2);
}

.no-scroll {
    overflow: hidden;
}

.dropdown-menu.mob-drop-down-bottom {
    inset: unset !important;
    bottom: -100% !important;
    transform: unset !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 1000 !important;
    transition: 0.2s all ease-in-out !important;
}

.dropdown-menu.mob-drop-down-bottom.animate {
    bottom: 0 !important;
    animation: slideInBottom 0.2s forwards !important;
}

.dropdown-menu.mob-drop-down-bottom.close {
    bottom: -100% !important;
    animation: slideOutBottom 0.2s forwards;
}

@keyframes slideInBottom {
    from {
        bottom: -100% !important;
    }
    to {
        bottom: 0 !important;
    }
}

@keyframes slideOutBottom {
    from {
        bottom: 0 !important;
    }
    to {
        bottom: -100% !important;
    }
}

.capitalize-word {
    display: inline-block;
}

.capitalize-word::first-letter {
    text-transform: uppercase !important;
}
